import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { MediaObject } from "../../types/MediaObject";
import { apiPost } from "../../services/Api/apiCall";
import { useSnackbar } from "notistack";

import ButtonLoading from "../Button/ButtonLoading";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

type CopyMediaObjectModalProps = {
  mediaObject: MediaObject;
  updateMediaObjects: Function;
  copyMediaObjectOpen: boolean;
  setCopyMediaObjectOpen: Function;
};

export default function CopyMediaObjectModal({
  mediaObject,
  updateMediaObjects,
  copyMediaObjectOpen,
  setCopyMediaObjectOpen,
}: CopyMediaObjectModalProps) {
  const [attachmentNumber, setAttachmentNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();

  const createMediaObjectCopy = async () => {
    setIsLoading(true);
    let snackbarMessage = "Dokument wurde erfolgreich als Anlage dupliziert";
    try {
      await apiPost("/lb/services/copy_media_object_as_attachment", {
        mediaObjectId: mediaObject.id,
        attachmentNumber: attachmentNumber,
        createdBy: currentUser["@id"],
      });
    } catch (e) {
      snackbarMessage = "Dokument konnte nicht als Anlage dupliziert werden";
    }

    enqueueSnackbar(snackbarMessage, {
      variant: "custom",
      isNonInteractive: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      persist: true,
    });

    updateMediaObjects();
    setIsLoading(false);
    setAttachmentNumber("");
    setCopyMediaObjectOpen(false);
  };

  return (
    <LegalbirdIoModal
      handleClose={() => {
        setCopyMediaObjectOpen(false);
      }}
      open={copyMediaObjectOpen}
      title={"Als Anlage duplizieren"}
      submitButton={
        <ButtonLoading
          isLoading={isLoading}
          disabled={!attachmentNumber}
          variant={"contained"}
          onClick={createMediaObjectCopy}
        >
          Dokument als Anlage duplizieren
        </ButtonLoading>
      }
    >
      <Typography>
        Mit dieser Funktion kann das ausgewählte Dokument als Anlage (beispielsweise für Anlagen von Klageschriften)
        erstellt werden. Hierzu wird das Dokument dupliziert und die Kopie je nach gewählter Nummer im untenstehenden
        Dropdown benannt.
      </Typography>
      <Typography>Bitte wählen Sie aus, welche Nummer die Anlage erhalten soll</Typography>
      <TextField
        type={"number"}
        value={attachmentNumber}
        onChange={({ target }) => setAttachmentNumber(target.value)}
        label={"Nummer der Anlage"}
      />
    </LegalbirdIoModal>
  );
}
