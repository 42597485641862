import React, { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import { InputBaseComponentProps } from "@mui/material/InputBase";

export const ThousandsNumberInputFloat = React.forwardRef<HTMLInputElement, InputBaseComponentProps>(
  function ThousandsNumberInput(props, ref) {
    const { onChange, name, numberType, ...other }: any = props;
    //added this mounted state because i was unable to find out why the onAccept was automatically called
    // with an empty value again after the initial render as soon as i rendered multiple of this field via a map function.
    // probably something with keys or refs, but nothing i tried solved the issue
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
      if (!mounted) {
        setMounted(true);
      }
    },[]);

    return (
      <IMaskInput
        {...other}
        mask={Number}
        scale={2}
        radix={","}
        unmask
        thousandsSeparator={"."}
        inputRef={ref}
        onAccept={(value) => mounted && onChange({ target: { name: name, value } })}
        mapToRadix={[]}
      />
    );
  }
);
