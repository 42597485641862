import { Divider, IconButton, ListItem, Stack, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIconOutlined from "@mui/icons-material/DeleteOutline";
import React from "react";
import { deleteResource, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { enqueueSnackbar } from "notistack";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import FormDraft from "../../../types/FormDraft";
import { UseFormReturn } from "react-hook-form";
import { convertFromRaw, EditorState } from "draft-js";
import { CasePdfGeneratorFormValues } from "../../../types/CasePdfGenerator";

type PdfGeneratorDraftListItemProps = {
  formDraft: FormDraft;
  formContext: UseFormReturn<CasePdfGeneratorFormValues>;
};
const PdfGeneratorDraftListItem = ({ formDraft, formContext }: PdfGeneratorDraftListItemProps) => {
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(deleteResource);

  const loadDraft = () => {
    const { content, ...restOfFormData } = formDraft.formData;
    const restoredEditorState = restoreEditorState(content._immutable.currentContent);

    formContext.reset({
      ...restOfFormData,
      content: restoredEditorState,
    });
  };

  const handleDelete = async () => {
    try {
      await deleteMutation.mutateAsync({ uri: "form_drafts", id: formDraft.id });
      await queryClient.invalidateQueries(queryKeys.collection("form_drafts"));
    } catch (error) {
      enqueueSnackbar("Es ist ein Fehler aufgetreten", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  function restoreEditorState(currentContent: DraftContent) {
    try {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });

      if (currentContent && currentContent.entityMap && currentContent.blockMap) {
        const rawContent = {
          entityMap: currentContent.entityMap,
          blocks: Object.values(currentContent.blockMap),
        };
        return EditorState.createWithContent(convertFromRaw(rawContent));
      }

      return EditorState.createEmpty();
    } catch (error) {
      return EditorState.createEmpty();
    }
  }

  return (
    <>
      <ListItem key={formDraft.id}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ width: "100%" }}>
          <Typography>{formDraft.title + " | " + formDraft.creator.person.fullname}</Typography>
          <Stack direction={"row"} spacing={1}>
            <Tooltip title="Entwurf bearbeiten">
              <IconButton onClick={loadDraft}>
                <EditIcon color={"primary"} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Entwurf löschen">
              <IconButton onClick={handleDelete}>
                <DeleteIconOutlined color={"primary"} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </ListItem>
      <Divider />
    </>
  );
};
export default PdfGeneratorDraftListItem;

type DraftContent = {
  entityMap: Record<string, any>;
  blockMap: Record<string, any>;
};
