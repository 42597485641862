import React, { useCallback } from "react";
import useStyles from "./templateEditorStyle";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import { editorStateToHTML, htmlToEditorState } from "../../services/Editor/editorFunctions";
import useForm from "../../hooks/useForm";
import SubmitButton from "../Button/SubmitButton";
import { Grid, MenuItem } from "@mui/material";
import { requiredFieldDefault, textFieldDefault } from "../../services/validationRules";
import ValidatorTextField from "../Validator/ValidatorTextField";
import ValidatorSelect from "../Validator/ValidatorSelect";
import { formValue } from "../../services/formServiceFunctions";
import { getFlatTranslationPathsWithLabel, translate } from "../../services/Translations/translatorService";
import AddPlaceholder from "./AddPlaceholder";
import LbEditor from "../LbEditor/LbEditor";
import Template from "../../types/Template";
import { UseForm } from "../../types/UseForm";
import { EditorState } from "draft-js";

type TemplateEditorProps = {
  template?: Template;
  onSubmitCallback?: () => Promise<void>;
  initialProduct?: string;
  type: string;
  withSubject?: boolean;
};

const TemplateEditor = ({ template, onSubmitCallback, initialProduct, type, withSubject }: TemplateEditorProps) => {
  const classes = useStyles({});

  const onSubmit = async ({ values }: Record<string, any>) => {
    let valuesToPersist = _.merge(
      {
        type: type,
      },
      values
    );

    // lowdash unescape is not able to handle null and expects undefined
    valuesToPersist.content = _.unescape(editorStateToHTML(values.content) ?? undefined);

    if (!template) {
      await ApiClient.post("templates", { body: JSON.stringify(valuesToPersist) });
      clearForm();
    } else {
      await ApiClient.put("templates/" + template.id, { body: JSON.stringify(valuesToPersist) });
    }
    if (_.isFunction(onSubmitCallback)) {
      await onSubmitCallback();
    }
  };

  let initialValues;

  if (template) {
    initialValues = {
      productClassName: template.productClassName,
      label: template.label,
      subject: template.subject,
      content: htmlToEditorState(template.content),
      recipientTypes: template.recipientTypes,
    };
  } else {
    initialValues = {
      productClassName: initialProduct,
      label: "",
      subject: "",
      content: EditorState.createEmpty(),
      recipientTypes: [],
    };
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    registerValidators,
    touchedValues,
    isLoading,
    clearForm,
  }: UseForm = useForm({
    initialValues,
    onSubmit,
  });

  const handleEditorStateChange = (editorState: EditorState) => {
    handleChange({
      target: {
        name: "content",
        value: editorState,
      },
    });
  };

  const getPlaceholders = useCallback(() => {
    const translationPathsWithLabel = getFlatTranslationPathsWithLabel(values.productClassName);
    return _.pickBy(translationPathsWithLabel, (value, key) => _.endsWith(key, "label"));
  }, [values.productClassName]);

  const placeholders = getPlaceholders();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent={"center"} alignItems={"center"} spacing={3}>
        <Grid item xs={6}>
          <ValidatorSelect
            disabled={!!template}
            label={"Rechtsprodukt"}
            name={"productClassName"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values["productClassName"]}
          >
            <MenuItem value={"divorce"}>Scheidung</MenuItem>
            <MenuItem value={"settlement"}>Abfindung</MenuItem>
            <MenuItem value={"traffic"}>Verkehr</MenuItem>
            <MenuItem value={"trafficAccident"}>Verkehrsunfall</MenuItem>
            <MenuItem value={"death"}>Tod und Erbe</MenuItem>
            <MenuItem value={"alimony"}>Unterhalt</MenuItem>
            <MenuItem value={"patientDecree"}>Patientenverfügung</MenuItem>
            <MenuItem value={"powerOfAttorney"}>Vorsorgevollmacht</MenuItem>
            <MenuItem value={"familyLawCase"}>Familienrecht</MenuItem>
            <MenuItem value={"contractLaw"}>Vertragsrecht</MenuItem>
            <MenuItem value={"dataLeakContract"}>Datenweitergabe</MenuItem>
            <MenuItem value={"rentalContract"}>{translate("rentalContract.label")}</MenuItem>
            <MenuItem value={"general"}>Produktübergreifend</MenuItem>
          </ValidatorSelect>
        </Grid>
        {type === "email" && (
          <Grid item xs={6}>
            <ValidatorSelect
              label={"Empfänger"}
              multiple
              validators={requiredFieldDefault}
              registerValidators={registerValidators}
              name={"recipientTypes"}
              value={formValue(values, "recipientTypes")}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors["recipientTypes"]}
              helperText={errors["recipientTypes"]}
            >
              <MenuItem value={"customer"}>Mandantschaft</MenuItem>
              <MenuItem
                disabled={
                  !["general", "rentalContract", "contractLaw", "dataLeakContract"].includes(values["productClassName"])
                }
                value={"contractualPartner"}
              >
                Gegenseite
              </MenuItem>
              <MenuItem
                disabled={
                  !["general", "rentalContract", "contractLaw", "dataLeakContract"].includes(values["productClassName"])
                }
                value={"debtCollectionAgency"}
              >
                Inkassobüro
              </MenuItem>
              <MenuItem
                disabled={!["general", "rentalContract"].includes(values["productClassName"])}
                value={"propertyManager"}
              >
                Hausverwaltung
              </MenuItem>
              <MenuItem
                disabled={
                  !["general", "rentalContract", "contractLaw", "dataLeakContract", "settlement", "divorce"].includes(
                    values["productClassName"]
                  )
                }
                value={"opponentLawFirm"}
              >
                Anwalt Gegenseite
              </MenuItem>
              <MenuItem
                disabled={!["general", "settlement"].includes(values["productClassName"])}
                value={"employerOrganization"}
              >
                E-Mail-Adresse Gegenseite
              </MenuItem>
              <MenuItem value={"processParticipants"}>Weitere Prozessbeteiligte</MenuItem>
              <MenuItem value={"insurance"}>Rechtsschutzversicherung</MenuItem>
              <MenuItem value={"advoAssistRepresentative"}>Terminsvertreter</MenuItem>
            </ValidatorSelect>
          </Grid>
        )}
        <Grid item xs={type === "email" ? 12 : 6}>
          <ValidatorTextField
            label={"Vorlage"}
            name={"label"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values["label"]}
            registerValidators={registerValidators}
            validators={[...textFieldDefault, ...requiredFieldDefault]}
            error={!!errors["label"]}
            helperText={errors["label"]}
            isHighlighted={undefined}
            initialDependentValidationFields={[]}
          />
        </Grid>
        {withSubject && (
          <Grid item xs={12}>
            <ValidatorTextField
              label={"Betreff"}
              name={"subject"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["subject"]}
              registerValidators={registerValidators}
              validators={[...textFieldDefault, ...requiredFieldDefault]}
              error={!!errors["subject"]}
              helperText={errors["subject"]}
              multiline
              isHighlighted={undefined}
              initialDependentValidationFields={[]}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <LbEditor
            toolbar={{
              options: ["inline", "list", "textAlign", "history", "link"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
              textAlign: {
                inDropdown: true,
              },
            }}
            stripPastedStyles
            toolbarCustomButtons={[
              <AddPlaceholder
                placeholders={placeholders}
                productClassName={values.productClassName}
              />,
            ]}
            editorState={formValue(values, "content")}
            onEditorStateChange={handleEditorStateChange}
            editorClassName={classes.editor}
            placeholder={"Entwurf"}
            localization={{ locale: "de" }}
          />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton
            error={_.keys(errors).length > 0}
            isLoading={isLoading}
            disabled={_.keys(touchedValues).length === 0}
            variant={"contained"}
            type={"submit"}
          >
            Vorlage speichern
          </SubmitButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default TemplateEditor;
