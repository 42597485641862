import * as stagesCase from "../../../Stages/StagesCase";
import personFields from "../modules/personFields";
import {
  beaSafeIdFieldDefault,
  dateFieldDefault,
  emailDefault,
  faxNumberDefault,
  ibanFieldDefault,
  invoiceNetValueNotOverpaid,
  percentageFieldDefault,
  postalCodeDefault,
  textFieldDefault,
} from "../../../validationRules";
import { trueFalseDefaultOptions } from "../formDefinitionFunctions";
import addressFields from "../modules/addressFields";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import { hasActiveCourtAppointment } from "../../ProductService";
import basicInsuranceFields from "../modules/basicInsuranceFields";
import _ from "lodash";
import { allDataPageCourtFields } from "../allDataPageCourtFields";
import { FormElementPropsWithoutProduct } from "../../../../components/Case/CaseForm/FormElement";
import { UseForm } from "../../../../types/UseForm";
import { BackofficeUser } from "../../../../types/BackofficeUser";
import DateChangeButtonsToolbar from "../../../../components/Validator/DateChangeButtonsToolbar";
import legalForms from "../../../legalForms";
import {
  getDriverOfClientVehicleOptions,
  getTypeOfAccidentOptions,
  getTypeOfDamageOptions,
} from "./trafficAccidentFormHelperService";
import { IbanInput } from "../../../../components/MaskedInputs/IbanInput";
import { TrafficAccident } from "../../../../types/TrafficAccident";

export const fullFormDefinition = (product: TrafficAccident, currentUser: BackofficeUser) => {
  const trafficAccidentBaseCreated = {
    isHidden: ({ product }: { product: TrafficAccident }) => product.stage > stagesCase.STAGE_CREATED,
    label: "Unfall",
    elements: [],
  };

  const trafficAccidentBase = {
    isHidden: ({ product }: { product: TrafficAccident }) => product.stage < stagesCase.STAGE_DATA_COMPLETED,
    label: "Unfall",
    elements: [
      ...personFields({ path: `${product.productClassName}.client`, showDiverseGenderOption: true }),
      ...addressFields({
        path: `${product.productClassName}.client.residenceAddress`,
      }),
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.clientBankData.iban`,
        validators: ibanFieldDefault,
        additionalProps: {
          InputProps: {
            inputComponent: IbanInput,
            startAdornment: <InputAdornment position="start">DE</InputAdornment>,
          },
        },
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.typeOfAccident`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage! >= stagesCase.STAGE_CHECKED,
        options: getTypeOfAccidentOptions,
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.typeOfDamage`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
        options: getTypeOfDamageOptions,
      },
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.accidentDate`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
        validators: dateFieldDefault,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.accidentTime`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
        additionalProps: {
          type: "time",
          InputLabelProps: {
            shrink: true,
          },
        },
      },
      ...addressFields({
        path: `${product.productClassName}.accidentAddress`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
      }),
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.policeInvolved`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
        options: () => trueFalseDefaultOptions(`${product.productClassName}.policeInvolved`),
      },
    ],
  };

  const courseOfEvents = {
    isHidden: ({ product }: { product: TrafficAccident }) => product.stage < stagesCase.STAGE_DATA_COMPLETED,
    label: "Unfallhergang",
    elements: [
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.courseOfEvents`,
        isDisabled: () => true,
        additionalProps: {
          multiline: true,
          rows: 12,
          fullWidth: true,
        },
      },
    ],
  };

  const damageDetails = {
    isHidden: ({ product }: { product: TrafficAccident }) => product.stage < stagesCase.STAGE_DATA_COMPLETED,
    label: "Details zum Schaden",
    elements: [
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.driverOfClientVehicle`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        options: getDriverOfClientVehicleOptions,
      },
      ...personFields({
        path: `${product.productClassName}.driverPerson`,
        showDiverseGenderOption: true,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          !(values.trafficAccident.driverOfClientVehicle === "other"),
      }),
      ...addressFields({
        path: `${product.productClassName}.driverPerson.residenceAddress`,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          !(values.trafficAccident.driverOfClientVehicle === "other"),
      }),
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.licensePlateClientVehicle`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        validators: textFieldDefault,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.vehicleMake`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.vehicleModel`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.vehicleId`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.legalExpenseInsurance`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
        options: () => trueFalseDefaultOptions(`${product.productClassName}.legalExpenseInsurance`),
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.descriptionInjuries`,
        isDisabled: () => true,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          !["vehicleAndPerson", "person"].includes(values.trafficAccident.typeOfDamage),
        additionalProps: {
          multiline: true,
          rows: 8,
          fullWidth: true,
        },
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.descriptionDamage`,
        isDisabled: () => true,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          !["vehicleAndPerson", "vehicle"].includes(values.trafficAccident.typeOfDamage),
        additionalProps: {
          multiline: true,
          rows: 8,
          fullWidth: true,
        },
      },
    ],
  };

  const opposingParty = {
    isHidden: ({ product }: { product: TrafficAccident }) => product.stage < stagesCase.STAGE_DATA_COMPLETED,
    label: "Gegenseite",
    elements: [
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.opposingPartyType`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        options: () => [{ value: "opposingPartyLegalEntity" }, { value: "opposingPartyPerson" }],
        additionalProps: {
          fullWidth: true,
        },
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opposingPartyLegalEntity.legalName`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: TrafficAccident }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyLegalEntity",
        validators: textFieldDefault,
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.opposingPartyLegalEntity.legalForm`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: TrafficAccident }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyLegalEntity",
        options: () => [
          { value: "__null__", label: "Keine Gesellschaftsform" },
          ..._.map(legalForms, (legalForm) => {
            return {
              value: legalForm.label,
              label: legalForm.label,
            };
          }),
        ],
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opposingPartyLegalEntity.address.streetAddress`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyLegalEntity",
        validators: textFieldDefault,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opposingPartyLegalEntity.address.postalCode`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyLegalEntity",
        validators: [...textFieldDefault, postalCodeDefault],
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opposingPartyLegalEntity.address.addressLocality`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyLegalEntity",
        validators: textFieldDefault,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opposingPartyLegalEntity.address.postOfficeBoxNumber`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyLegalEntity",
        validators: textFieldDefault,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opposingPartyLegalEntity.address.postOfficeBoxPostalCode`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyLegalEntity",
        validators: [...textFieldDefault, postalCodeDefault],
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opposingPartyLegalEntity.address.postOfficeBoxAddressLocality`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyLegalEntity",
        validators: textFieldDefault,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opposingPartyLegalEntity.email`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyLegalEntity",
        validators: textFieldDefault,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opposingPartyLegalEntity.telephone`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyLegalEntity",
        validators: textFieldDefault,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opposingPartyLegalEntity.fax`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyLegalEntity",
        validators: faxNumberDefault,
        additionalProps: {
          InputProps: {
            startAdornment: <InputAdornment position="start">+49</InputAdornment>,
          },
        },
      },
      {
        type: "ContractRepresentatives",
        path: `${product.productClassName}.opposingPartyLegalEntity.additionalRepresentatives`,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values.opposingPartyType === "opposingPartyLegalEntity",
        additionalProps: {
          fullWidth: true,
          gridSpacing: 4,
          gridSize: 4,
          buttonFullWidth: false,
        },
      },
      ...personFields({
        path: `${product.productClassName}.opposingPartyPerson`,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyPerson",
        isDisabled: ({ product }: { product: TrafficAccident; values: UseForm["values"] }) =>
          product.stage >= stagesCase.STAGE_COMPLETED,
      }),
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opposingPartyPerson.email`,
        validators: emailDefault,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyPerson",
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opposingPartyPerson.telephone`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyPerson",
      },
      ...addressFields({
        path: `${product.productClassName}.opposingPartyPerson.residenceAddress`,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values.trafficAccident.opposingPartyType !== "opposingPartyPerson",
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
      }),
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.licensePlateOpposingParty`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_COMPLETED,
        isHidden: () => false,
        validators: textFieldDefault,
      },
    ],
  };

  const opponentLawyer = {
    label: "Anwalt Gegenseite",
    isHidden: ({ product }: { product: TrafficAccident }) => product.stage < stagesCase.STAGE_DATA_COMPLETED,
    elements: [
      {
        type: "BeaLawyerImportButton",
        path: "pathPropNotUsedButRequired",
        additionalProps: {
          fullWidth: true,
        },
      },
      ...personFields({ path: `${product.productClassName}.opponentLawyer` }),
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opponentLawFirm.name`,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opponentLawFirm.email`,
        validators: emailDefault,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opponentLawFirm.telephone`,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opponentLawFirm.fax`,
        validators: faxNumberDefault,
        additionalProps: {
          InputProps: {
            startAdornment: <InputAdornment position="start">+49</InputAdornment>,
          },
        },
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opponentLawFirm.beaSafeId`,
        validators: beaSafeIdFieldDefault,
      },
      ...addressFields({ path: `${product.productClassName}.opponentLawFirm.address` }),
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.referenceOpponentLawyer`,
      },
    ],
  };

  const trafficAccidentInsurance = {
    label: "Versicherung",
    isHidden: ({ product }: { product: TrafficAccident }) => product.paymentType !== "insurance",
    elements: [
      ...basicInsuranceFields({
        path: `${product.productClassName}.insurance`,
        product: product,
      }),
      {
        type: "ValidatorNumberField",
        path: `${product.productClassName}.insurance.deductible`,
        fieldType: "money",
        numberType: "float",
      },
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.insurance.extrajudicialCoverageRequestedDate`,
        validators: dateFieldDefault,
      },
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.insurance.extrajudicialCoverageRequestAnsweredDate`,
        validators: dateFieldDefault,
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.insurance.extrajudicialCoverageGranted`,
        options: () => trueFalseDefaultOptions(`${product.productClassName}.extrajudicialCoverageGranted`),
      },
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.insurance.judicialCoverageRequestedDate`,
        validators: dateFieldDefault,
      },
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.insurance.judicialCoverageRequestAnsweredDate`,
        validators: dateFieldDefault,
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.insurance.judicialCoverageGranted`,
        options: () => trueFalseDefaultOptions(`${product.productClassName}.extrajudicialCoverageGranted`),
      },
    ],
  };

  const opposingPartyLiabilityInsurance = {
    label: "KFZ-Haftpflicht Gegenseite",
    elements: [
      {
        type: "ValidatorOrganizationAutocomplete",
        path: `${product.productClassName}.opposingCarLiabilityInsurance`,
        additionalProps: {
          organizationType: "opposingCarLiabilityInsurance",
        },
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opposingInsurancePolicyNumber`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opposingCaseReferenceNumber`,
        isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
      },
    ],
  };

  const processParticipantsPeople = {
    label: "Personen",
    isHidden: ({ product }: { product: TrafficAccident }) => product.productCategory !== "trafficLaw",
    elements: [
      {
        type: "ProcessParticipantsPeopleForm",
        path: `${product.productClassName}.relatedPeople`,
        additionalProps: {
          fullWidth: true,
        },
      },
    ],
  };

  const processParticipantsOrganizations = {
    label: "Unternehmen",
    isHidden: ({ product }: { product: TrafficAccident }) => product.productCategory !== "trafficLaw",
    elements: [
      {
        type: "ProcessParticipantsOrganizationsForm",
        path: `${product.productClassName}.relatedOrganizations`,
        additionalProps: {
          fullWidth: true,
        },
      },
    ],
  };

  const processParticipantsAuthority = {
    label: "Behörde",
    elements: [
      {
        type: "AuthorityPicker",
        path: "trafficAccident.responsibleAuthority",
        additionalProps: {
          fullWidth: true,
        },
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.authorityReference`,
        validators: textFieldDefault,
        isHidden: ({ product }: { product: TrafficAccident }) => product.responsibleAuthority === null,
      },
    ],
  };

  return {
    product: {
      sections: [trafficAccidentBaseCreated, trafficAccidentBase, courseOfEvents, damageDetails],
    },
    additional: {
      sections: [
        {
          label: "Allgemeines",
          elements: [
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.reference`,
              isDisabled: () => true,
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.powerOfAttorney`,
              isHidden: ({ product }: { product: TrafficAccident }) => product.stage < stagesCase.STAGE_DATA_COMPLETED,
              isDisabled: () => true,
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.legalExpenseInsurance`,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage > stagesCase.STAGE_ACTION_REQUIRED,
              options: () => trueFalseDefaultOptions("trafficAccident.legalExpenseInsurance"),
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.caseCategoryAssessment`,
              isHidden: ({ product }: { product: TrafficAccident }) => product.stage < stagesCase.STAGE_DATA_COMPLETED,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage > stagesCase.STAGE_WAITING_FOR_FEEDBACK,
              options: () => [{ value: "confirm" }, { value: "reject" }],
            },
          ],
        },
        {
          label: "Außergerichtliches Verfahren",
          isHidden: ({ product }: { product: TrafficAccident }) =>
            product.stage < stagesCase.STAGE_DATA_COMPLETED || product.previousInstanceCase,
          elements: [
            {
              type: "CaseRejectionCategorySelect",
              path: `${product.productClassName}.caseRejectionCategory`,
              options: () => [{ value: "none" }, { value: "individual" }],
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
              isHidden: ({ product, values }: { product: TrafficAccident; values: UseForm["values"] }) =>
                values[product.productClassName].caseCategoryAssessment !== "reject",
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.assessmentRejectionText`,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
              isHidden: ({ product, values }: { product: TrafficAccident; values: UseForm["values"] }) =>
                values[product.productClassName].caseCategoryAssessment !== "reject",
              additionalProps: {
                multiline: true,
                rows: 12,
                fullWidth: true,
              },
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.additionalIssueDescriptionText`,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
              isHidden: ({ product }: { product: TrafficAccident }) =>
                product.isLawsuitHandling ||
                (product.stage >= stagesCase.STAGE_CHECKED && !product.additionalIssueDescriptionText),
              additionalProps: {
                multiline: true,
                fullWidth: true,
              },
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.additionalClaimText`,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
              isHidden: ({ product }: { product: TrafficAccident }) =>
                product.isLawsuitHandling ||
                product.stage ||
                (0 >= stagesCase.STAGE_CHECKED && !product.additionalClaimText),
              additionalProps: {
                multiline: true,
                fullWidth: true,
              },
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.defectsDescriptionLawFirm`,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
              isHidden: ({ product }: { product: TrafficAccident }) =>
                !_.has(product, "defectsDescriptionLawFirm") || product.caseCategory !== "enforceClaim",
              additionalProps: {
                multiline: true,
                fullWidth: true,
              },
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.extrajudicialCommunicationSent`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage < stagesCase.STAGE_CHECKED ||
                product.stage >= stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT,
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.courseOfEventsLawFirm`,
              additionalProps: {
                multiline: true,
                fullWidth: true,
              },
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                !product.stage ||
                product.stage < stagesCase.STAGE_DATA_COMPLETED ||
                product.stage > stagesCase.STAGE_DATA_COMPLETED_LAWFIRM,
            },
            {
              type: "SelectAndExtendedDataComboField",
              path: `${product.productClassName}.claimsForDamages`,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                !product.stage ||
                product.stage < stagesCase.STAGE_DATA_COMPLETED ||
                product.stage > stagesCase.STAGE_DATA_COMPLETED_LAWFIRM,
              additionalProps: {
                fullWidth: true,
                config: {
                  values: [
                    "repairCosts",
                    "replacementCosts",
                    "lossOfUse",
                    "rentalCarCosts",
                    "depreciation",
                    "expertFees",
                    "expensesFlatFee",
                    "deregistrationCosts",
                    "registrationCosts",
                    "towingCosts",
                    "storageCosts",
                    "financingCosts",
                  ],
                  showOther: true,
                  extensionFieldConfig: {
                    type: "money",
                    labelPrefix: "Höhe Schadensersatz",
                    md: 4,
                  },
                },
              },
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.valueInDisputePreliminary`,
              fieldType: "money",
              numberType: "float",
              isDisabled: () => true,
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.extrajudicialCommunicationDeadline`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                !product.stage ||
                product.stage < stagesCase.STAGE_DATA_COMPLETED ||
                product.stage > stagesCase.STAGE_DATA_COMPLETED_LAWFIRM,
              additionalProps: {
                toolbar: DateChangeButtonsToolbar,
              },
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.typeOfDamage`,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                !product.stage ||
                product.stage < stagesCase.STAGE_DATA_COMPLETED ||
                product.stage > stagesCase.STAGE_DATA_COMPLETED_LAWFIRM,
              options: () => [
                { value: "vehicle" },
                { value: "person" },
                { value: "vehicleAndPerson" },
                { value: "other" },
              ],
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.typeOfAccident`,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                !product.stage ||
                product.stage < stagesCase.STAGE_DATA_COMPLETED ||
                product.stage > stagesCase.STAGE_DATA_COMPLETED_LAWFIRM,
              options: () => [
                { value: "rearEndCollision" },
                { value: "laneChangeCollision" },
                { value: "parking" },
                { value: "other" },
              ],
            },
            {
              type: "ExpandableTextField",
              path: `${product.productClassName}.courseOfEvents`,
              isDisabled: () => true,
              additionalProps: {
                multiline: true,
              },
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.extrajudicialCommunicationDeadline`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage < stagesCase.STAGE_DATA_COMPLETED || product.stage >= stagesCase.STAGE_LAWSUIT,
              additionalProps: {
                toolbar: DateChangeButtonsToolbar,
              },
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.claimedAmountLawFirm`,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_CHECKED,
              fieldType: "money",
              numberType: "float",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.extrajudicialReactionReceived`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.extrajudicialReaction === "noReaction" ||
                product.stage < stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT ||
                product.stage >= stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED,
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.extrajudicialReaction`,
              translationPath: `${product.productClassName}.extrajudicialReaction.${product.caseCategory}`,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage < stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT ||
                product.stage >= stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED,
              options: () => [
                { value: "noReaction" },
                { value: "fullyAgreed" },
                { value: "partiallyAgreed" },
                { value: "disagreed" },
              ],
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.extrajudicialRecommendation`,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage !== stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED &&
                product.stage !== stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT,
              isHidden: ({ product }: { product: TrafficAccident }) =>
                !_.includes(["defendClaim", "enforceClaim"], product.caseCategory),
              options: ({ product }: { product: TrafficAccident }) =>
                product.caseCategory === "defendClaim"
                  ? [
                      { value: "agreementDone" },
                      { value: "insufficientEvidence" },
                      { value: "insufficientChancesOfSuccess" },
                      { value: "costBenefitConsiderations" },
                      { value: "lawsuit" },
                      { value: "individualText" },
                    ]
                  : [
                      { value: "agreementDone" },
                      { value: "insufficientEvidence" },
                      { value: "insufficientChancesOfSuccess" },
                      { value: "costBenefitConsiderations" },
                      { value: "anonymousOpponent" },
                      { value: "opponentUnableToPay" },
                      { value: "lawsuit" },
                      { value: "individualText" },
                    ],
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.extrajudicialRecommendationText`,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                !(
                  product.stage === stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT ||
                  (product.stage === stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED &&
                    product.extrajudicialRecommendationText)
                ),
              isHidden: ({ product }: { product: TrafficAccident }) =>
                !_.includes(["defendClaim", "enforceClaim"], product.caseCategory) ||
                (product.stage > stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED &&
                  !product.extrajudicialRecommendationText),
              additionalProps: {
                multiline: true,
                fullWidth: true,
              },
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.finalClaimStatusClient`, // Forderungsstatus nach Fristende aus Mandantensicht
              translationPath: `${product.productClassName}.finalClaimStatusClient.${product.caseCategory}`, // Forderungsstatus nach Fristende aus Mandantensicht
              helperText:
                "Achtung: Feld ist über Servicewelt durch Mandantschaft zu befüllen! Wenn dieses Feld von uns befüllt wird, " +
                "kann die Mandantschaft kein Feedback mehr abgeben. Daher nur befüllen, wenn mit Mandantschaft explizit abgesprochen.",
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage > stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED,
              options: () => [
                { value: "fullyAgreed" },
                { value: "partiallyAgreed" },
                { value: "notAgreed" },
                { value: "noReaction" },
              ],
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.finalProcessFeedbackClient`, // Rückmeldung Mandant
              translationPath: `${product.productClassName}.finalProcessFeedbackClient.${product.caseCategory}`, // Rückmeldung Mandant
              helperText:
                "Achtung: Feld ist über Servicewelt durch Mandantschaft zu befüllen! Wenn dieses Feld von uns befüllt wird, " +
                "kann die Mandantschaft kein Feedback mehr abgeben. Daher nur befüllen, wenn mit Mandantschaft explizit abgesprochen.",
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage > stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED,
              isHidden: ({ product }: { product: TrafficAccident }) => product.finalClaimStatusClient === "fullyAgreed",
              options: () => [{ value: "agreement" }, { value: "lawsuit" }],
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.statementOfClaimSentDate`,
              validators: dateFieldDefault,
              isHidden: ({ product }: { product: TrafficAccident }) => product.caseCategory !== "enforceClaim",
            },
          ],
        },
        {
          label: "Gerichtliches Verfahren",
          isHidden: ({ product }: { product: TrafficAccident }) => product.stage < stagesCase.STAGE_DATA_COMPLETED,
          elements: [
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.paymentOrderDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage >= stagesCase.STAGE_LAWSUIT_COMPLETED,
              isHidden: ({ product }: { product: TrafficAccident }) => product.caseCategory !== "defendClaim",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.enforcementOrderDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage >= stagesCase.STAGE_LAWSUIT_COMPLETED,
              isHidden: ({ product }: { product: TrafficAccident }) => product.caseCategory !== "defendClaim",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.statementOfClaimFiledDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage >= stagesCase.STAGE_LAWSUIT_COMPLETED,
              isHidden: ({ product }: { product: TrafficAccident }) => product.caseCategory !== "defendClaim",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.statementOfDefenseFiledDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage !== stagesCase.STAGE_LAWSUIT,
              isHidden: ({ product }: { product: TrafficAccident }) =>
                product.caseCategory !== "enforceClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.replicaSentDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage !== stagesCase.STAGE_LAWSUIT,
              isHidden: ({ product }: { product: TrafficAccident }) =>
                product.caseCategory !== "enforceClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.courtPostalCode`,
              helperText:
                "Bitte hier die Postleitzahl eintragen, die für den Gerichtsstand ermittelt wurde und NICHT die Postleitzahl des Gerichtes.",
              isHidden: ({ product }: { product: TrafficAccident }) =>
                product.stage > stagesCase.STAGE_LAWSUIT ||
                product.caseCategory === "defendClaim" ||
                product.isHigherInstanceCase,
              alert: {
                onChange:
                  "Das Ändern der Postleitzahl führt dazu, dass das zuständige Gericht erneut ermittelt wird. Möchten Sie die Postleitzahl tatsächlich ändern?",
              },
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.courtReference`,
              isHidden: ({ product }: { product: TrafficAccident }) =>
                !_.includes(["defendClaim", "enforceClaim"], product.caseCategory),
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.statementOfDefenseSentDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage !== stagesCase.STAGE_LAWSUIT,
              isHidden: ({ product }: { product: TrafficAccident }) =>
                product.caseCategory !== "defendClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.replicaFiledDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage !== stagesCase.STAGE_LAWSUIT,
              isHidden: ({ product }: { product: TrafficAccident }) =>
                product.caseCategory !== "defendClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.lawsuitCompletedReason`,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage !== stagesCase.STAGE_LAWSUIT,
              options: () => [
                { value: "courtDecision" },
                { value: "settlement" },
                { value: "lawsuitRevocation" },
                { value: "resolution" },
                { value: "defaultJudgement" },
                { value: "judgementAppeal" },
              ],
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.customerLawsuitCompletedApprovalDate`,
              translationPath: ({ values }: { values: UseForm["values"] }) =>
                `${product.productClassName}.customerLawsuitCompletedApprovalDate.${
                  values[product.productClassName].lawsuitCompletedReason
                }`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage > stagesCase.STAGE_LAWSUIT,
              isHidden: ({ values }: { values: UseForm["values"] }) =>
                _.includes(
                  ["courtDecision", "defaultJudgement", "judgementAppeal"],
                  values[product.productClassName].lawsuitCompletedReason
                ) || !values[product.productClassName].lawsuitCompletedReason,
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.lawsuitCompletedDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage > stagesCase.STAGE_LAWSUIT,
              isHidden: ({ values }: { values: UseForm["values"] }) =>
                !values[product.productClassName].lawsuitCompletedReason,
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.judgementReceivedDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage >= stagesCase.STAGE_LAWSUIT_COMPLETED,
              isHidden: ({ product }: { product: TrafficAccident }) => product.stage < stagesCase.STAGE_LAWSUIT,
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.valueInDisputePreliminary`,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage >= stagesCase.STAGE_LAWSUIT,
              fieldType: "money",
              numberType: "float",
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.valueInDispute`,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage !== stagesCase.STAGE_TRIAL,
              fieldType: "money",
              numberType: "float",
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.hasStandardInvoicing`,
              options: () => trueFalseDefaultOptions(`${product.productClassName}.hasStandardInvoicing`),
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.subtractExtrajudicialProcessFee`,
              options: () => trueFalseDefaultOptions(`${product.productClassName}.subtractExtrajudicialProcessFee`),
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage !== stagesCase.STAGE_LAWSUIT &&
                product.stage !== stagesCase.STAGE_TRIAL &&
                product.stage !== stagesCase.STAGE_ACTION_REQUIRED &&
                product.stage !== stagesCase.STAGE_PREPARE_LAWSUIT,
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.netRvgAmount`,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage !== stagesCase.STAGE_LAWSUIT_COMPLETED || !product.lawsuitCompletedDate,
              isHidden: ({ product }: { product: TrafficAccident }) => product.hasStandardInvoicing,
              fieldType: "money",
              numberType: "float",
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.costBearingByOpponentFinalAbsolute`,
              validators: [...invoiceNetValueNotOverpaid(`${product.productClassName}.netRvgAmount`)],
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage !== stagesCase.STAGE_LAWSUIT_COMPLETED || !product.lawsuitCompletedDate,

              isHidden: ({ product }: { product: TrafficAccident }) =>
                product.lawsuitCompletedReason === "lawsuitRevocation" || product.costBearingByOpponentRelative === 0.0,
              fieldType: "money",
              numberType: "float",
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.courtCostsFinal`,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                product.stage !== stagesCase.STAGE_LAWSUIT_COMPLETED || !product.lawsuitCompletedDate,

              isHidden: ({ product }: { product: TrafficAccident }) =>
                product.lawsuitCompletedReason === "lawsuitRevocation" || product.costBearingByOpponentRelative === 0.0,
              numberType: "float",
              fieldType: "number",
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.costBearingByOpponentRelative`,
              validators: percentageFieldDefault,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                !_.includes(
                  [stagesCase.STAGE_LAWSUIT_COMPLETED, stagesCase.STAGE_TRIAL, stagesCase.STAGE_LAWSUIT],
                  product.stage
                ),
              fieldType: "percentage",
              numberType: "float",
              isHidden: ({ product }: { product: TrafficAccident }) =>
                product.lawsuitCompletedReason === "lawsuitRevocation",
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.principalClaim`,
              isHidden: ({ product, values }: { product: TrafficAccident; values: UseForm["values"] }) =>
                values[product.productClassName].caseCategory !== "enforceClaim" ||
                product.lawsuitCompletedReason === "lawsuitRevocation" ||
                product.costBearingByOpponentRelative === 0.0,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage !== stagesCase.STAGE_LAWSUIT,
              fieldType: "money",
              numberType: "float",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.principalClaimDefaultDate`,
              isHidden: ({ values }: { values: UseForm["values"] }) =>
                values[product.productClassName].caseCategory !== "enforceClaim" ||
                product.lawsuitCompletedReason === "lawsuitRevocation" ||
                product.costBearingByOpponentRelative === 0.0,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage !== stagesCase.STAGE_LAWSUIT,
              validators: dateFieldDefault,
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.accessoryClaim`,
              isHidden: ({ values }: { values: UseForm["values"] }) =>
                values[product.productClassName].caseCategory !== "enforceClaim" ||
                product.lawsuitCompletedReason === "lawsuitRevocation" ||
                product.costBearingByOpponentRelative === 0.0,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage !== stagesCase.STAGE_LAWSUIT,
              fieldType: "money",
              numberType: "float",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.accessoryClaimDefaultDate`,
              isHidden: ({ values }: { values: UseForm["values"] }) =>
                values[product.productClassName].caseCategory !== "enforceClaim" ||
                product.lawsuitCompletedReason === "lawsuitRevocation" ||
                product.costBearingByOpponentRelative === 0.0,
              isDisabled: ({ product }: { product: TrafficAccident }) => product.stage !== stagesCase.STAGE_LAWSUIT,
              validators: dateFieldDefault,
            },
          ],
        },
        {
          label: product.courtAppointments.length === 1 ? "Gerichtstermin" : "Gerichtstermine",
          isHidden: ({ product }: { product: TrafficAccident }) => product.courtAppointments.length === 0,
          elements: getCourtAppointmentFields(product),
        },
        getNextInstanceSection(product),
      ],
    },
    processParticipants: {
      sections: [
        opposingParty,
        processParticipantsAuthority,
        trafficAccidentInsurance,
        opposingPartyLiabilityInsurance,
        opponentLawyer,
      ],
    },
    additionalProcessParticipants: {
      sections: [processParticipantsPeople, processParticipantsOrganizations],
      withoutSubmit: true,
    },
    court: {
      sections: [
        {
          elements: [
            {
              type: "CourtPicker",
              path: `${product.productClassName}.responsibleCourt`,
              isDisabled: ({ product }: { product: TrafficAccident }) =>
                (product.caseCategory === "enforceClaim" && product.stage < stagesCase.STAGE_ACTION_REQUIRED) ||
                hasActiveCourtAppointment(product.courtAppointments),
              additionalProps: {
                fullWidth: true,
                courtTypes: ["Amtsgericht", "Arbeitsgericht", "Landgericht", "Mahngericht", "Oberlandesgericht"],
              },
            },
            ...allDataPageCourtFields(product.productClassName, "responsibleCourt"),
          ],
        },
      ],
    },
  };
};

export const getCourtAppointmentFields = (product: TrafficAccident) => {
  let courtAppointmentFields: FormElementPropsWithoutProduct[] = [];
  _.forEach(product.courtAppointments, (courtAppointment, index) => {
    courtAppointmentFields.push(
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.courtAppointments[${index}].courtDate.appointmentDate`,
        translationPath: "courtAppointment.courtDate.appointmentDate",
        isDisabled: () => true,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.courtAppointments[${index}].courtDate.appointmentTime`,
        translationPath: "courtAppointment.courtDate.appointmentTime",
        isDisabled: () => true,
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.courtAppointments[${index}].courtDateType`,
        translationPath: "courtAppointment.courtDateType",
        options: () => [
          { value: "hearing" },
          { value: "hearingOfEvidence" },
          { value: "settlement" },
          { value: "trialEmploymentLaw" },
          //{ value: "trialTenancyLaw" },
          { value: "trialTrafficLaw" },
          { value: "divorce" },
        ],
        isDisabled: () => true,
      }
    );
  });
  return courtAppointmentFields;
};

export const getNextInstanceSection = (product: TrafficAccident) => ({
  label: "Urteilsprüfung",
  isHidden: ({ product }: { product: TrafficAccident }) => product.stage < stagesCase.STAGE_EVALUATE_JUDGEMENT,
  elements: [
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.nextInstanceRecommendation`,
      isDisabled: ({ product }: { product: TrafficAccident }) => product.stage !== stagesCase.STAGE_EVALUATE_JUDGEMENT,
      options: () => [{ value: "appeal" }, { value: "noAppeal" }],
      alert: {
        onChange:
          "Mit der Auswahl einer Option wird sofort und automatisch eine Anfrage an die Mandantschaft gesendet. Daher bitte sicherstellen, dass die Empfehlung final ist.",
        onInitial:
          "Mit der Auswahl einer Option wird sofort und automatisch eine Anfrage an die Mandantschaft gesendet. Daher bitte sicherstellen, dass die Empfehlung final ist.",
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.nextInstanceDecisionClient`,
      helperText:
        "Achtung: Feld ist über Servicewelt durch Mandantschaft zu befüllen! Wenn dieses Feld von uns befüllt wird, " +
        "kann die Mandantschaft kein Feedback mehr abgeben. Daher nur befüllen, wenn mit Mandantschaft explizit abgesprochen.",
      alert: {
        onInitial:
          "Sie möchten den Wert im Feld “Entscheidung Mandantschaft nächste Instanz” erstmalig ändern. Dieses " +
          "Feld wird standardmäßig durch die Mandantschaft über eine Anfrage in der Servicewelt befüllt und sollte " +
          "nur im Ausnahmefall und nach ausdrücklicher Abstimmung mit der Mandantschaft manuell befüllt werden! " +
          "Mit der Auswahl einer Option und anschließendem Speichern wird der Fall in die Stage Rechtsstreit beendet " +
          "verschoben. Sofern Sie “Berufung einlegen” auswählen, wird ein neuer Fall angelegt und darin ggf. eine " +
          "Deckungsanfrage an die Versicherung gesendet. Daher bitte sicherstellen, dass die Entscheidung final ist.",
        onChange:
          "Sie möchten den Wert im Feld “Entscheidung Mandantschaft nächste Instanz” erstmalig ändern. Dieses " +
          "Feld wird standardmäßig durch die Mandantschaft über eine Anfrage in der Servicewelt befüllt und sollte " +
          "nur im Ausnahmefall und nach ausdrücklicher Abstimmung mit der Mandantschaft manuell befüllt werden! " +
          "Mit der Auswahl einer Option und anschließendem Speichern wird der Fall in die Stage Rechtsstreit beendet " +
          "verschoben. Sofern Sie “Berufung einlegen” auswählen, wird ein neuer Fall angelegt und darin ggf. eine " +
          "Deckungsanfrage an die Versicherung gesendet. Daher bitte sicherstellen, dass die Entscheidung final ist.",
      },
      isDisabled: ({ product }: { product: TrafficAccident }) =>
        !_.includes([stagesCase.STAGE_LAWSUIT_COMPLETED, stagesCase.STAGE_EVALUATE_JUDGEMENT], product.stage),
      options: () => [{ value: "appeal" }, { value: "checkJudgement" }, { value: "noAppeal" }],
    },
    {
      type: "ValidatorDateField",
      validators: dateFieldDefault,
      path: `${product.productClassName}.promulgationOfJudgementDate`,
    },
    {
      type: "ValidatorDateField",
      validators: dateFieldDefault,
      path: `${product.productClassName}.judgementEebSentDate`,
    },
  ],
});

export const getAdditionalClientsForm = (product: TrafficAccident) => ({
  label: "Weitere Mandantschaft",
  isHidden: () => false,
  elements: [
    {
      type: "AdditionalClientsForm",
      path: `${product.productClassName}.additionalClients`,
      additionalProps: {
        fullWidth: true,
      },
    },
  ],
});
