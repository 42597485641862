/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import React, { useEffect, useState } from "react";
import LegalbirdCall from "../../TwilioVoice/LegalbirdCall";
import { AbstractCase } from "../../../types/AbstractCase";
import { Activity } from "../../../types/Activity";
import ApiClient from "../../../services/ApiClient";

type CallModalProps = {
  open: boolean;
  handleClose: Function;
  product: AbstractCase;
  activity?: Activity;
};

function CallModal({ product, open, handleClose = () => {}, activity }: CallModalProps) {
  const [calendlyEvent, setCalendlyEvent] = useState();
  const [assignedUser, setAssignedUser] = useState();

  useEffect(() => {
    if (!activity) {
      setCalendlyEvent(undefined);
      return;
    }

    if (activity.calendlyEvent) {
      ApiClient.get(activity.calendlyEvent).then((response) => setCalendlyEvent(response));
    } else {
      setCalendlyEvent(undefined);
    }

    if (activity.assignedUser && typeof activity.assignedUser === "string") {
      ApiClient.get(activity.assignedUser).then((response) => setAssignedUser(response));
    } else {
      setCalendlyEvent(undefined);
    }
  }, [activity]);

  if (activity && !calendlyEvent && !assignedUser) {
    return null;
  }

  console.log(calendlyEvent);
  console.log(assignedUser);

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Anrufen"}
      disableBackdropClick={true}
      hideCloseButton={true}
      cancelButtonLabel={"Schliessen"}
    >
      <LegalbirdCall product={product} calendlyEvent={calendlyEvent} />
    </LegalbirdIoModal>
  );
}

export default CallModal;
