/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import _ from "lodash";
import { apiPut } from "../Api/apiCall";
import ApiClient from "../ApiClient";
import { getProductData } from "../Product/ProductService";
import {
  persistAddress,
  persistCourt,
  persistCustomer,
  persistPerson,
  persistAppointment,
  sanitizeLandlordData,
  getUpdateData,
} from "./Persister";

export const persistTrafficAccident = async (updateData, origTrafficAccidentCase) => {
  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origTrafficAccidentCase, "customer");
    await persistCustomer(origTrafficAccidentCase.customer.id, customerUpdateData);
  }

  if (_.isObject(updateData.insurance)) {
    const insuranceUpdateData = getUpdateData(updateData.insurance, origTrafficAccidentCase, "insurance");
    if (_.has(updateData.insurance, "insurance.id")) {
      insuranceUpdateData.insurance = `/insurances/${updateData.insurance.insurance.id}`;
    }
    await apiPut("customer_insurances", origTrafficAccidentCase.insurance.id, insuranceUpdateData);
  }

  if (_.has(updateData, "insurance.policyHolder") && _.isObject(updateData.insurance.policyHolder)) {
    const policyHolderUpdateData = getUpdateData(
      updateData.insurance.policyHolder,
      origTrafficAccidentCase,
      "insurance.policyHolder"
    );
    await persistPerson(origTrafficAccidentCase.insurance.policyHolder.id, policyHolderUpdateData);
  }

  if (_.isObject(updateData.clientBankData)) {
    const clientBankUpdateData = getUpdateData(updateData.clientBankData, origTrafficAccidentCase, "clientBankData");
    await apiPut("bank_datas", origTrafficAccidentCase.clientBankData.id, clientBankUpdateData);
  }

  if (_.isObject(updateData.responsibleCourt)) {
    const courtUpdateData = getUpdateData(updateData.responsibleCourt, origTrafficAccidentCase, "responsibleCourt");
    !_.isEmpty(courtUpdateData) && (await persistCourt(origTrafficAccidentCase.responsibleCourt.id, courtUpdateData));
  }

  if (_.has(updateData, "insurance.policyHolder") && _.isObject(updateData.insurance.policyHolder)) {
    const policyHolderUpdateData = getUpdateData(
      updateData.insurance.policyHolder,
      origTrafficAccidentCase,
      "insurance.policyHolder"
    );
    await persistPerson(origTrafficAccidentCase.insurance.policyHolder.id, policyHolderUpdateData);
  }

  if (_.has(updateData, "accidentAddress") && _.isObject(updateData.accidentAddress)) {
    const accidentAddressUpdateData = getUpdateData(
      updateData.accidentAddress,
      origTrafficAccidentCase,
      "accidentAddress"
    );
    await persistAddress(origTrafficAccidentCase.accidentAddress.id, accidentAddressUpdateData);
  }

  if (_.has(updateData, "driverPerson") && _.isObject(updateData.driverPerson)) {
    const driverPersonUpdateData = getUpdateData(updateData.driverPerson, origTrafficAccidentCase, "driverPerson");
    await persistPerson(origTrafficAccidentCase.driverPerson.id, driverPersonUpdateData);
  }

  if (_.has(updateData, "driverPerson.residenceAddress") && _.isObject(updateData.driverPerson.residenceAddress)) {
    const driverPersonAddressUpdateData = getUpdateData(
      updateData.driverPerson.residenceAddress,
      origTrafficAccidentCase,
      "driverPerson.residenceAddress"
    );
    await persistAddress(origTrafficAccidentCase.driverPerson.residenceAddress.id, driverPersonAddressUpdateData);
  }

  if (_.isObject(updateData.client)) {
    const petitionerUpdateData = getUpdateData(updateData.client, origTrafficAccidentCase, "petitioner");
    await persistPerson(origTrafficAccidentCase.client.id, petitionerUpdateData);
  }

  if (_.isObject(updateData.clientLegalRepresentative)) {
    const clientLegalRepresentativeUpdateData = getUpdateData(
      updateData.clientLegalRepresentative,
      origTrafficAccidentCase,
      "clientLegalRepresentative"
    );
    await persistPerson(origTrafficAccidentCase.clientLegalRepresentative.id, clientLegalRepresentativeUpdateData);
  }

  if (
    _.has(updateData, "clientLegalRepresentative.residenceAddress") &&
    _.isObject(updateData.clientLegalRepresentative.residenceAddress)
  ) {
    const clientLegalRepresentativeAddressUpdateData = getUpdateData(
      updateData.clientLegalRepresentative.residenceAddress,
      origTrafficAccidentCase,
      "clientLegalRepresentative.residenceAddress"
    );
    await persistAddress(
      origTrafficAccidentCase.clientLegalRepresentative.residenceAddress.id,
      clientLegalRepresentativeAddressUpdateData
    );
  }

  if (_.has(updateData, "client.residenceAddress") && _.isObject(updateData.client.residenceAddress)) {
    const clientAddressUpdateData = getUpdateData(
      updateData.client.residenceAddress,
      origTrafficAccidentCase,
      "client.residenceAddress"
    );
    await persistAddress(origTrafficAccidentCase.client.residenceAddress.id, clientAddressUpdateData);
  }

  if (_.isObject(updateData.opposingPartyPerson)) {
    const opposingPartyPersonUpdateData = getUpdateData(
      updateData.opposingPartyPerson,
      origTrafficAccidentCase,
      "opposingPartyPerson"
    );
    await persistPerson(origTrafficAccidentCase.opposingPartyPerson.id, opposingPartyPersonUpdateData);
  }

  if (
    _.has(updateData, "opposingPartyPerson.residenceAddress") &&
    _.isObject(updateData.opposingPartyPerson.residenceAddress)
  ) {
    const opposingPartyPersonAddressUpdateData = getUpdateData(
      updateData.opposingPartyPerson.residenceAddress,
      origTrafficAccidentCase,
      "opposingPartyPerson.residenceAddress"
    );
    await persistAddress(
      origTrafficAccidentCase.opposingPartyPerson.residenceAddress.id,
      opposingPartyPersonAddressUpdateData
    );
  }

  if (_.isObject(updateData.opposingPartyLegalEntity)) {
    let opposingPartyLegalEntityUpdateData = getUpdateData(
      updateData.opposingPartyLegalEntity,
      origTrafficAccidentCase,
      "opposingPartyLegalEntity"
    );

    opposingPartyLegalEntityUpdateData.additionalRepresentatives =
      updateData.opposingPartyLegalEntity.additionalRepresentatives;

    await ApiClient.put(`organizations/${origTrafficAccidentCase.opposingPartyLegalEntity.id}`, {
      body: JSON.stringify(sanitizeLandlordData(opposingPartyLegalEntityUpdateData)),
    });
  }

  if (
    _.has(updateData, "opposingPartyLegalEntity.address") &&
    _.isObject(updateData.opposingPartyLegalEntity.address)
  ) {
    const opposingPartyLegalEntityAddressUpdateData = getUpdateData(
      updateData.opposingPartyLegalEntity.address,
      origTrafficAccidentCase,
      "opposingPartyLegalEntity.address"
    );
    await persistAddress(
      origTrafficAccidentCase.opposingPartyLegalEntity.address.id,
      opposingPartyLegalEntityAddressUpdateData
    );
  }

  if (_.isObject(updateData.opponentLawyer)) {
    const opponentLawyerUpdateData = getUpdateData(
      updateData.opponentLawyer,
      origTrafficAccidentCase,
      "opponentLawyer"
    );
    await persistPerson(origTrafficAccidentCase.opponentLawyer.id, opponentLawyerUpdateData);
  }

  if (_.isObject(updateData.opponentLawFirm)) {
    const opponentLawFirmUpdateData = getUpdateData(
      updateData.opponentLawFirm,
      origTrafficAccidentCase,
      "opponentLawFirm"
    );
    await apiPut("organizations", origTrafficAccidentCase.opponentLawFirm.id, opponentLawFirmUpdateData);
  }

  if (_.has(updateData, "opponentLawFirm.address") && _.isObject(updateData.opponentLawFirm.address)) {
    const opponentLawFirmAddressUpdateData = getUpdateData(
      updateData.opponentLawFirm.address,
      origTrafficAccidentCase,
      "opponentLawFirm.address"
    );
    await persistAddress(origTrafficAccidentCase.opponentLawFirm.address.id, opponentLawFirmAddressUpdateData);
  }

  if (_.isObject(updateData.courtDate)) {
    const courtDateUpdateData = getUpdateData(updateData.courtDate, origTrafficAccidentCase, "courtDate");
    await persistAppointment(origTrafficAccidentCase.courtDate.id, courtDateUpdateData);
  }

  const apiUri = getProductData(origTrafficAccidentCase.productClassName, "apiUri");
  const contractCaseUpdateData = getUpdateData(updateData, origTrafficAccidentCase);

  if (_.isObject(updateData.claimsForDamages)) {
    contractCaseUpdateData.claimsForDamages = updateData.claimsForDamages;
  }

  await apiPut(apiUri, origTrafficAccidentCase.id, contractCaseUpdateData);
};
