import preleadTranslations from "./preleadTranslations";

const customerTranslations = {
  telephone: {
    label: "Telefonnummer",
  },
  alternativeTelephone: {
    label: "Alternative Telefonnummer",
  },
  email: {
    label: "E-Mail-Adresse",
  },
  verification: {
    label: "E-Mail bestätigt am",
  },
  created: {
    label: "Fall angelegt am",
  },
  contactTime: {
    label: "Erreichbarkeit",
    values: {
      flexible: "Ich bin flexibel",
      time: "Uhr",
    },
  },
  prelead: {
    ...preleadTranslations,
  },
  isTest: {
    label: "Testkunde",
  },
};

export default customerTranslations;
