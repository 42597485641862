import React from "react";
import productDashboardStyle from "./productsDashboardStyle";
import { Box, Divider, List, ListItemButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import divorceIcon from "../../assets/icon/product/icon_scheidung.svg";
import trafficIcon from "../../assets/icon/product/icon_verkehrsrecht.svg";
import trafficAccidentIcon from "../../assets/icon/product/icon_verkehrsunfall.svg";
import settlementIcon from "../../assets/icon/product/icon_arbeitsrecht.svg";
import documentsIcon from "../../assets/icon/product/icon_dokumente.svg";
import familyLawIcon from "../../assets/icon/product/icon_familienrecht.svg";
import contractLawIcon from "../../assets/icon/product/icon_vertragsrecht.svg";
import dataLeakContractIcon from "../../assets/icon/product/icon_datenweitergabe.svg";
import tenancyLawIcon from "../../assets/icon/product/icon_mietvertrag.svg";
import ContentBox from "../ContentBox/ContentBox";
import { translate } from "../../services/Translations/translatorService";

const ProductsDashboard = ({}) => {
  return (
    <Box sx={{ maxWidth: 360, margin: "auto" }}>
      <ContentBox padding="none">
        <List>
          <ListItemButton
            sx={productDashboardStyle.productItem}
            component={Link}
            to={"/services/board/scheidung/offen"}
          >
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <Box component={"img"} src={divorceIcon} sx={productDashboardStyle.icon} alt={"Scheidung"} />
              </Grid>
              <Grid item>{translate("divorce.label")}</Grid>
            </Grid>
          </ListItemButton>
          <Divider />
          <ListItemButton
            sx={productDashboardStyle.productItem}
            component={Link}
            to={"/services/board/abfindung/offen"}
          >
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <Box component={"img"} src={settlementIcon} sx={productDashboardStyle.icon} alt={"Abfindung"} />
              </Grid>
              <Grid item>{translate("settlement.label")}</Grid>
            </Grid>
          </ListItemButton>
          <Divider />
          <ListItemButton sx={productDashboardStyle.productItem} component={Link} to={"/services/board/verkehr/offen"}>
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <Box component={"img"} src={trafficIcon} sx={productDashboardStyle.icon} alt={"Verkehr"} />
              </Grid>
              <Grid item>{translate("traffic.label")}</Grid>
            </Grid>
          </ListItemButton>
          <Divider />
          <Divider />
          <ListItemButton sx={productDashboardStyle.productItem} component={Link} to={"/services/board/verkehrsunfall/offen"}>
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <Box component={"img"} src={trafficAccidentIcon} sx={productDashboardStyle.icon} alt={"Verkehrsunfall"} />
              </Grid>
              <Grid item>{translate("trafficAccident.label")}</Grid>
            </Grid>
          </ListItemButton>
          <Divider />
          <ListItemButton
            sx={productDashboardStyle.productItem}
            component={Link}
            to={"/services/board/dokumente/offen"}
          >
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <Box component={"img"} src={documentsIcon} sx={productDashboardStyle.icon} alt={"Dokumente"} />
              </Grid>
              <Grid item>Dokumente</Grid>
            </Grid>
          </ListItemButton>
          <Divider />
          <ListItemButton sx={productDashboardStyle.productItem} component={Link} to={"/services/board/familie/offen"}>
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <Box component={"img"} src={familyLawIcon} sx={productDashboardStyle.icon} alt={"Familienrecht"} />
              </Grid>
              <Grid item>{translate("familyLawCase.label")}</Grid>
            </Grid>
          </ListItemButton>
          <Divider />
          <ListItemButton
            sx={productDashboardStyle.productItem}
            component={Link}
            to={"/services/board/vertragsrecht/offen"}
          >
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <Box component={"img"} src={contractLawIcon} sx={productDashboardStyle.icon} alt={"Vertragsrecht"} />
              </Grid>
              <Grid item>Beratung / Vertretung Vertragsrecht</Grid>
            </Grid>
          </ListItemButton>
          <Divider />
          <ListItemButton
            sx={productDashboardStyle.productItem}
            component={Link}
            to={"/services/board/datenweitergabe/offen"}
          >
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <Box
                  component={"img"}
                  src={dataLeakContractIcon}
                  sx={productDashboardStyle.icon}
                  alt={"Datenweitergabe"}
                />
              </Grid>
              <Grid item>Datenweitergabe</Grid>
            </Grid>
          </ListItemButton>
          <Divider />
          <ListItemButton
            sx={productDashboardStyle.productItem}
            component={Link}
            to={"/services/board/mietrecht/offen"}
          >
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <Box component={"img"} src={tenancyLawIcon} sx={productDashboardStyle.icon} alt={"Mietrecht"} />
              </Grid>
              <Grid item>Beratung / Vertretung Mietrecht</Grid>
            </Grid>
          </ListItemButton>
        </List>
      </ContentBox>
    </Box>
  );
};

export default ProductsDashboard;
