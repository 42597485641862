import _ from "lodash";
import { LAW_FIRM_ADDRESS, LAW_FIRM_ADDRESS_LOCALITY, LAW_FIRM_NAME, LAW_FIRM_POSTAL_CODE } from "../globalVars";
import { BackofficeUser, Roles } from "../../types/BackofficeUser";
import { AbstractCase } from "../../types/AbstractCase";
import { userHasRole } from "../backofficeUserService";

const signatureData = {
  legalbirdLogoUrl: "https://www.legalbird.io/img/legalbird_logo_rechtsanwaelte.png",
  brandName: "Legalbird",
  companyName: LAW_FIRM_NAME,
  address: `${LAW_FIRM_ADDRESS} | ${LAW_FIRM_POSTAL_CODE} ${LAW_FIRM_ADDRESS_LOCALITY}`,
  telephone: "Tel. 0221 98651940",
  email: "service@legalbird.de",
  fax: "Fax 0221 98651949",
  executiveBoard: "Geschäftsführer: Frank Riedel, Anna Wagner",
  registeredAt: "Amtsgericht Köln HRB 97467 | USt Ident Nr.: DE324640771",
};

export const getEmailSignature = (backofficeUser: BackofficeUser, product: AbstractCase | null, onlyComplimentaryClose: boolean) => {

  let titles = "";
  if (backofficeUser.person.titles) {
    titles = _.reduce(
      backofficeUser.person.titles,
      (result, value) => {
        if (value.product === product?.productCategory || value.product === "all") {
          return result + value.fullTitle + "<br/>";
        }
        return result;
      },
      "<br/>"
    );
  }

  let representativeLawyer = '';

  if (product && userHasRole(backofficeUser, Roles.lawyer) && product.backofficeCase.lawyer && product.backofficeCase.lawyer.id !== backofficeUser.id) {
    representativeLawyer += `<br/>-in Vertretung von <strong>${product.backofficeCase.lawyer.person.fullname}${product.backofficeCase.lawyer.person.nameAddition ? ', ' + product.backofficeCase.lawyer.person.nameAddition : ''}</strong>-`;
  }

  let signature =
    `<p>Mit freundlichen Grüßen</p>` + `<p><strong>${backofficeUser.person.fullname}${backofficeUser.person.nameAddition ? ', ' + backofficeUser.person.nameAddition : ''}</strong>${titles}${representativeLawyer}</p>`;

  if (onlyComplimentaryClose) {
    return signature;
  }

  signature =
    signature +
    `<p>` +
    `<strong>${signatureData.brandName}</strong><br/>` +
    `${signatureData.companyName && signatureData.companyName + "<br/>"}` +
    `${signatureData.address}<br/>` +
    `${signatureData.email}<br/>` +
    `${signatureData.executiveBoard}<br/>` +
    `${signatureData.registeredAt}` +
    `</p>`;

  return signature;
};
