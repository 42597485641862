/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import * as stagesCase from "../../Stages/StagesCase";
import { TrafficAccident } from "../../../types/TrafficAccident";
import { contractRequiredFields } from "../Contract/contractRequiredFields";
import { AbstractCase } from "../../../types/AbstractCase";

export const trafficAccidentRequiredFields = {
  ...contractRequiredFields,
  [stagesCase.STAGE_DATA_COMPLETED_LAWFIRM]: (trafficAccidentCase: TrafficAccident) => {
    let requiredFields = [];

    if (trafficAccidentCase.caseCategoryAssessment === "reject") {
      requiredFields.push("assessmentRejectionText");
      return requiredFields;
    }

    requiredFields.push("caseCategoryAssessment");
    requiredFields.push("valueInDisputePreliminary");
    requiredFields.push("extrajudicialCommunicationDeadline");
    requiredFields.push("courseOfEventsLawFirm");

    return requiredFields;
  },
  [stagesCase.STAGE_CHECKED]: (trafficAccidentCase: AbstractCase) => {
    return trafficAccidentRequiredFields[stagesCase.STAGE_DATA_COMPLETED_LAWFIRM](trafficAccidentCase);
  },
  [stagesCase.STAGE_LAWSUIT]: (trafficAccidentCase: TrafficAccident) => {
    let requiredFields = [
      "insurance.judicialCoverageRequestAnsweredDate",
      "insurance.judicialCoverageGranted",
      "subtractExtrajudicialProcessFee",
    ];

    if (!trafficAccidentCase.isHigherInstanceCase) {
      requiredFields.push("courtPostalCode");
    }
    requiredFields.push("statementOfClaimSentDate");

    return requiredFields;
  },
  [stagesCase.STAGE_COMPLETED]: () => [],
};
