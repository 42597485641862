import React, { useState } from "react";
import { IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import EditActivity from "../EditActivity/EditActivity";
import PopUpMenu from "../../PopUpMenu/PopUpMenu";
import MenuItem from "@mui/material/MenuItem";
import ButtonLoading from "../../Button/ButtonLoading";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import CalendlyAssessmentModal from "../../CalendlyAssessments/CalendlyAssessmentModal";
import EditActivityNote from "./EditActivityNote";
import { AbstractCase } from "../../../types/AbstractCase";
import { Delete, LocalPhoneOutlined } from "@mui/icons-material";
import {
  canOpenCalendlyAssessmentModal,
  getAvailableActivityActions,
  isCalendlyActivity,
} from "../../../services/Activities/activityFunctions";
import ActivityDoneCheckBox from "./ActivityDoneCheckbox";
import { Activity } from "../../../types/Activity";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { activityActionsStyles } from "./activityActionsStyles";
import CallModal from "../../Case/CaseModals/CallModal";

interface ActivityActionsProps {
  activity: Activity;
  updateActivities: () => Promise<void> | void;
  product?: AbstractCase;
}

const ActivityActions = ({ activity, updateActivities, product }: ActivityActionsProps) => {
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [callOpen, setCallOpen] = useState(false);
  const [assessmentOpen, setAssessmentOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useCurrentUser();
  const updateMutation = useMutation(updateResource);

  const availableActions = getAvailableActivityActions(activity, currentUser, product);
  const handleDeleteActivity = async () => {
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: "activities",
      id: activity.id || 0,
      data: {
        deleted: true,
      },
    });
    setIsLoading(false);
    updateActivities();
    setDeleteOpen(false);
  };

  const toggleActivityDone = async () => {
    if (isCalendlyActivity(activity)) {
      if (!activity.done) {
        setAssessmentOpen(true);
      }
      return;
    }
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: "activities",
      id: activity.id || 0,
      data: {
        done: !activity.done,
      },
    });
    updateActivities();
  };

  console.log(activity);

  return (
    <>
      <Grid container wrap={"nowrap"} justifyContent={"flex-end"} alignItems={"center"}>
        <Grid item sx={activityActionsStyles.actionItem}>
          <ActivityDoneCheckBox
            activityDone={activity.done}
            isLoading={isLoading}
            toggleActivityDone={toggleActivityDone}
            isCheckboxDisabled={!availableActions.canComplete}
          />
        </Grid>

        {availableActions.canEditActivityNote && (
          <Grid item sx={activityActionsStyles.actionItem}>
            <EditActivityNote activity={activity} updateActivities={updateActivities} />
          </Grid>
        )}

        {availableActions.canDelete && availableActions.canEdit && (
          <Grid item sx={activityActionsStyles.actionItem}>
            <PopUpMenu tooltipTitle={"Mehr"}>
              <MenuItem onClick={() => setEditOpen(true)}>Bearbeiten</MenuItem>
              <MenuItem onClick={() => setDeleteOpen(true)}>Löschen</MenuItem>
            </PopUpMenu>
          </Grid>
        )}

        {availableActions.canCall && product && (
          <Grid item sx={activityActionsStyles.actionItem}>
            <IconButton onClick={() => setCallOpen(true)}>
              <LocalPhoneOutlined />
            </IconButton>
          </Grid>
        )}

        {availableActions.canDelete && !availableActions.canEdit && (
          <Grid item sx={activityActionsStyles.actionItem}>
            <IconButton onClick={() => setDeleteOpen(true)}>
              <Delete />
            </IconButton>
          </Grid>
        )}
      </Grid>

      {canOpenCalendlyAssessmentModal(activity, product) && (
        <CalendlyAssessmentModal
          key={"calendlyAssessmentModal." + activity.id}
          open={assessmentOpen}
          handleClose={() => setAssessmentOpen(false)}
          activity={activity}
          product={product}
          update={updateActivities}
        />
      )}

      {availableActions.canEdit && (
        <EditActivity
          key={activity.id}
          activity={activity}
          open={editOpen}
          closeDialog={() => setEditOpen(false)}
          updateActivities={updateActivities}
        />
      )}

      {availableActions.canCall && product && activity.calendlyEvent && (
        <CallModal
          open={callOpen}
          handleClose={() => setCallOpen(false)}
          product={product}
          activity={activity}
        ></CallModal>
      )}

      {availableActions.canDelete && (
        <LegalbirdIoModal
          handleClose={() => setDeleteOpen(false)}
          open={deleteOpen}
          title={"Achtung"}
          submitButton={
            <ButtonLoading variant={"contained"} onClick={handleDeleteActivity} isLoading={isLoading}>
              Ja
            </ButtonLoading>
          }
        >
          <Typography sx={activityActionsStyles.deleteDialogText}>
            Diese Aktivität wird unwiderruflich gelöscht. Sind Sie sicher, dass Sie die Aktivität löschen möchten?
          </Typography>
        </LegalbirdIoModal>
      )}
    </>
  );
};

export default ActivityActions;
