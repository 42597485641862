/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

export const typeOfDamageOptions = [
  { value: "vehicle" },
  { value: "person" },
  { value: "vehicleAndPerson" },
  { value: "other" },
];

export const typeOfAccidentOptions = [
  { value: "rearEndCollision" },
  { value: "laneChangeCollision" },
  { value: "parking" },
  { value: "other" },
];

export const driverOfClientVehicleOptions = [{ value: "client" }, { value: "other" }, { value: "nobody" }];

export const getTypeOfDamageOptions = () => typeOfDamageOptions;
export const getTypeOfAccidentOptions = () => typeOfAccidentOptions;
export const getDriverOfClientVehicleOptions = () => driverOfClientVehicleOptions;
