import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import calendlyAssessmentStyle from "./calendlyAssessmentStyle";
import ValidatorSelect from "../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import useForm, { UseFormProps } from "../../hooks/useForm";
import { requiredFieldDefault } from "../../services/validationRules";
import { convertBooleanToYesNoString, formValue } from "../../services/formServiceFunctions";
import ButtonLoading from "../Button/ButtonLoading";
import ValidatorTextField from "../Validator/ValidatorTextField";
import _ from "lodash";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ApiClient from "../../services/ApiClient";
import { useSnackbar } from "notistack";
import { userHasOneOfTheseRoles, userHasRole } from "../../services/backofficeUserService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchResource, queryKeys, updateResource } from "../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { BackofficeUser, Roles } from "../../types/BackofficeUser";
import moment from "moment";
import { createNoteAsFile } from "../../services/Files/fileService";
import ValidatorElectronicFileFolderPathSelect from "../Validator/ValidatorElectronicFileFolderPathSelect";
import { Activity } from "../../types/Activity";
import { AbstractCase } from "../../types/AbstractCase";
import { CalendlyEvent } from "../../types/Calendly/CalendlyEvent";
import { NotePdfGenerateData } from "../../types/NotePdfGenerateData";
import { hasUserConsent } from "../../services/calendlyService";

// @ts-ignore
const useStyles = makeStyles(calendlyAssessmentStyle);

type CalendlyAssessmentModalInnerProps = {
  activity: Activity;
  product: AbstractCase;
  update: Function;
  open: boolean;
  handleClose: Function;
  calendlyEvent: CalendlyEvent;
};

const CalendlyAssessmentModalInner = ({
  activity,
  product,
  update,
  open,
  handleClose,
  calendlyEvent,
}: CalendlyAssessmentModalInnerProps) => {
  const classes = useStyles();
  const updateMutation = useMutation(updateResource);
  const currentUser = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const isFollowUpAllowed = product.acquisitionPartner !== "check24";
  const isLawyerCounselingCall = activity.type === "lawyer_counseling_call";
  const isExternalCall = activity.type === "calendly_event";
  const offerMandateToExternalLawyer =
    !isLawyerCounselingCall &&
    isFollowUpAllowed &&
    userHasRole(currentUser, Roles.external) &&
    ["death", "alimony", "familyLawCase"].includes(product.productClassName);
  const isFlexServiceCustomer = !!(
    product.insurance.specialTermsTag && product.insurance.specialTermsTag === "advocardFlex"
  );
  const mediaObjectsFilter = {
    product: product.productClassName,
    productId: product.id,
  };

  const initialValues = {
    cancelled: false,
    processed: convertBooleanToYesNoString(calendlyEvent.assessment!.processed),
    notProcessedReason: formValue(activity, "calendlyEvent.assessment.notProcessedReason"),
    followUp: convertBooleanToYesNoString(calendlyEvent.assessment!.followUp),
    followUpAction: formValue(activity, "calendlyEvent.assessment.followUpAction"),
    customerSatisfaction: calendlyEvent.assessment!.customerSatisfaction,
    note: formValue(activity, "calendlyEvent.assessment.note"),
    saveNoteAsFile: false,
    dontSendSummaryToClient: false,
    electronicFileFolderPath: "none",
    acceptsMandateTransferal: convertBooleanToYesNoString(calendlyEvent.assessment!.acceptsMandateTransferal),
    cancelReason: formValue(
      activity,
      "calendlyEvent.assessment.cancelReason",
      "Leider müssen wir Ihnen den Beratungstermin krankheitsbedingt absagen. " +
        "Wir bitten die dadurch entstandenen Umstände zu entschuldigen! " +
        "Bitte buchen Sie sich über den Ihnen bekannten Link einen neuen Beratungstermin. " +
        "Mit freundlichen Grüßen, Ihr Team von Legalbird"
    ),
  };

  const queryClient = useQueryClient();

  const recordingEnabled = hasUserConsent(calendlyEvent, "recordingAndSummary");

  const onSubmit: UseFormProps["onSubmit"] = async ({ values }) => {
    const updateData = _.merge(values, {
      processed: values.processed === "yes",
      followUp: isLawyerCounselingCall ? null : values.followUp === "yes",
      followUpAction: isLawyerCounselingCall ? null : values.followUpAction,
      customerSatisfaction: isLawyerCounselingCall ? null : parseInt(values.customerSatisfaction),
      acceptsMandateTransferal:
        !offerMandateToExternalLawyer || values.followUp === "no" ? null : values.acceptsMandateTransferal === "yes",
      cancelReason: !values.cancelled ? null : values.cancelReason,
    });

    await updateMutation.mutateAsync({
      id: calendlyEvent.assessment!.id!,
      uri: "calendly_assessments",
      data: updateData,
    });
    await update();

    if (values.saveNoteAsFile) {
      let data: NotePdfGenerateData = {
        ...values,
        productId: product.id,
        documentIdentifier: "consultationSavedNote",
        createdBy:
          currentUser.person.gender.replace("female", "RAin").replace("male", "RA") + " " + currentUser.person.fullname,
      };

      let messageText = "Notiz wurde erfolgreich erstellt";
      try {
        await createNoteAsFile(data);
      } catch (e) {
        messageText = "Notiz konnte nicht erstellt werden";
      }

      await queryClient.invalidateQueries(queryKeys.collection("media_objects", mediaObjectsFilter));

      enqueueSnackbar(messageText, {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }

    clearForm();
    handleClose();

    if (values.cancelled) {
      enqueueSnackbar("Termin wird über Calendly abgesagt. Dies kann eine kurze Zeit in Anspruch nehmen.", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        autoHideDuration: 10000,
      });
    }
  };

  const { values, handleChange, handleSubmit, isLoading, registerValidators, errors, clearForm } = useForm({
    initialValues,
    onSubmit,
    identifier: activity,
  });

  const { data: phoneCall, isLoading: phoneCallLoading } = useQuery(
    queryKeys.item("lb/services/phone_call_summary", calendlyEvent.phoneCallId!),
    () => fetchResource("lb/services/phone_call_summary", calendlyEvent.phoneCallId!),
    {
      enabled: !!calendlyEvent.phoneCallId,
    }
  );

  useEffect(() => {
    if (phoneCall && phoneCall.summary) {
      handleChange({ target: { name: "callSummary", value: phoneCall.summary } });
    }
  }, [phoneCall, open]);

  const [retries, setRetries] = useState(0);
  const MAX_RETRIES = 15;

  useEffect(() => {
    if (!open) {
      clearForm();
      setRetries(0);
      queryClient.resetQueries(queryKeys.item("lb/services/phone_call_summary", calendlyEvent.phoneCallId!));
    }
  }, [open]);

  useEffect(() => {
    if (!open || retries > MAX_RETRIES || !recordingEnabled || (phoneCall && phoneCall.summary !== null)) {
      return;
    }

    const intervalId = setInterval(async () => {
      await queryClient.invalidateQueries(queryKeys.item("lb/services/phone_call_summary", calendlyEvent.phoneCallId!));
      setRetries((prevRetries) => prevRetries + 1);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [open, retries, calendlyEvent, phoneCall, recordingEnabled]);

  const hasSubmitRights = (currentUser: BackofficeUser, activity: Activity, values: Record<string, any>) => {
    return (
      currentUser["@id"] === activity.assignedUser ||
      userHasRole(currentUser, Roles.admin) ||
      (isLawyerCounselingCall && userHasRole(currentUser, Roles.lawyer)) ||
      (isExternalCall && userHasOneOfTheseRoles(currentUser, [Roles.lawyer, Roles.accountmanager])) ||
      (values.cancelled && userHasOneOfTheseRoles(currentUser, [Roles.lawyer, Roles.accountmanager]))
    );
  };

  const isSubmittable = () => {
    if (hasSubmitRights(currentUser, activity, values) === false) {
      return false;
    }

    if (values.cancelled === true) {
      return values.cancelReason.length >= 50;
    }

    if (values.saveNoteAsFile === true && values.electronicFileFolderPath === "none") {
      return false;
    }

    if (values.processed === "no") {
      return true;
    }

    if (recordingEnabled && _.isEmpty(values.callSummary)) {
      return false;
    }

    if (isLawyerCounselingCall || isFlexServiceCustomer) {
      return !_.isEmpty(values.processed);
    }

    if (offerMandateToExternalLawyer && values.followUp === "yes" && !values.acceptsMandateTransferal) {
      return false;
    }

    return (
      !_.isEmpty(values.processed) && values.customerSatisfaction && (!isFollowUpAllowed || !_.isEmpty(values.followUp))
    );
  };

  useEffect(() => {
    registerValidators("processed", !values.cancelled ? requiredFieldDefault : []);
    registerValidators(
      "followUp",
      !values.cancelled && !isLawyerCounselingCall && !isFlexServiceCustomer && isFollowUpAllowed
        ? requiredFieldDefault
        : []
    );
    registerValidators(
      "notProcessedReason",
      !values.cancelled && values.processed === "no" ? requiredFieldDefault : []
    );
    if (values.processed === "no") {
      handleChange({ target: { name: "followUp", value: "no" } });
    }
  }, [values.cancelled, values.processed]);

  const tooManyRetries = retries > MAX_RETRIES;

  if (recordingEnabled && !phoneCall && retries === 0) {
    return null;
  }

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Ergebnis des Beratungstermins"}
      submitButton={
        <ButtonLoading
          type={"submit"}
          variant={"contained"}
          disabled={!isSubmittable()}
          isLoading={isLoading}
          onClick={isSubmittable() ? handleSubmit : () => {}}
        >
          {values.cancelled ? "Termin absagen" : "Ergebnis speichern"}
        </ButtonLoading>
      }
    >
      <Grid container>
        {isFlexServiceCustomer && (
          <Alert severity="info">
            Spezialfall: Mandant kann in Servicewelt selbst entscheiden, ob weitere Mandatstätigkeit notwendg ist
          </Alert>
        )}
        {userHasOneOfTheseRoles(currentUser, [Roles.lawyer, Roles.accountmanager, Roles.admin]) &&
          moment(activity.due) > moment() && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="cancelled"
                    checked={values.cancelled}
                    onChange={handleChange}
                    value="cancelled"
                    color="primary"
                  />
                }
                label="Termin absagen"
              />
            </Grid>
          )}
        {!values.cancelled ? (
          <>
            <Grid item xs={12}>
              <ValidatorSelect
                label={"Hat das Gespräch wie geplant stattgefunden?"}
                name={"processed"}
                value={formValue(values, "processed")}
                onChange={handleChange}
                registerValidators={registerValidators}
                validators={!values.cancelled ? requiredFieldDefault : []}
                dependentValidationFields={["notProcessedReason"]}
                error={!!errors["processed"]}
                helperText={errors["processed"]}
                disabled={activity.done}
              >
                <MenuItem value={"yes"}>Ja</MenuItem>
                <MenuItem value={"no"}>Nein</MenuItem>
              </ValidatorSelect>
            </Grid>
            {values.processed === "no" && (
              <Grid item xs={12}>
                <ValidatorSelect
                  label={"Warum hat das Gespräch nicht stattgefunden? "}
                  name={"notProcessedReason"}
                  value={formValue(values, "notProcessedReason")}
                  onChange={handleChange}
                  disabled={values.processed !== "no" || activity.done}
                  error={!!errors["notProcessedReason"]}
                  helperText={errors["notProcessedReason"]}
                >
                  <MenuItem value={"clientNotAvailable"}>Mandant nicht erreicht</MenuItem>
                  <MenuItem value={"clientNumberIncorrect"}>Falsche Rufnummer</MenuItem>
                  <MenuItem value={"clientCancelled"}>Mandant hat Termin abgesagt</MenuItem>
                  <MenuItem value={"lawyerUnavailable"}>Ich konnte nicht teilnehmen</MenuItem>
                </ValidatorSelect>
              </Grid>
            )}
            {values.processed === "yes" && !isFlexServiceCustomer && (
              <>
                {!isLawyerCounselingCall && isFollowUpAllowed && (
                  <>
                    <Grid item xs={12}>
                      <ValidatorSelect
                        label={"Weitere Mandatstätigkeiten notwendig"}
                        name={"followUp"}
                        value={formValue(values, "followUp")}
                        onChange={(e) => {
                          handleChange(e);
                          registerValidators("followUpAction", e.target.value === "yes" ? requiredFieldDefault : []);
                          registerValidators("note", e.target.value === "yes" ? requiredFieldDefault : []);
                          registerValidators("callSummary", e.target.value === "yes" ? requiredFieldDefault : []);
                        }}
                        registerValidators={registerValidators}
                        validators={!values.cancelled && values.processed === "no" ? requiredFieldDefault : []}
                        dependentValidationFields={["followUpAction", "note"]}
                        error={!!errors["followUp"]}
                        helperText={errors["followUp"]}
                        disabled={activity.done}
                      >
                        <MenuItem value={"yes"}>Ja, es sind weitere Mandatstätigkeiten notwendig</MenuItem>
                        <MenuItem value={"no"}>Nein, alle Fragen wurden beantwortet</MenuItem>
                      </ValidatorSelect>
                    </Grid>
                    {values.followUp === "yes" && !activity.done && (
                      <>
                        <Grid item xs={12}>
                          <ValidatorSelect
                            label={"Welche weitere Mandatstätigkeit ist notwendig?"}
                            name={"followUpAction"}
                            value={formValue(values, "followUpAction")}
                            onChange={handleChange}
                            disabled={values.followUp !== "yes"}
                            error={!!errors["followUpAction"]}
                            helperText={errors["followUpAction"]}
                          >
                            <MenuItem value={"counsultation"}>Weitere kurze Beratung</MenuItem>
                            <MenuItem value={"additionalActions"}>Außergerichtliches Schreiben</MenuItem>
                            <MenuItem value={"documentCheck"}>Prüfung von Dokumenten</MenuItem>
                            <MenuItem value={"courtProcess"}>Anwaltliche Betreuuung und ggf. Prozess</MenuItem>
                          </ValidatorSelect>
                        </Grid>
                        {offerMandateToExternalLawyer && (
                          <Grid item xs={12}>
                            <ValidatorSelect
                              label={"Übernahme des Mandats"}
                              name={"acceptsMandateTransferal"}
                              value={formValue(values, "acceptsMandateTransferal")}
                              onChange={handleChange}
                              error={!!errors["acceptsMandateTransferal"]}
                              helperText={errors["acceptsMandateTransferal"]}
                            >
                              <MenuItem value={"yes"}>Ja, ich kann gerne übernehmen</MenuItem>
                              <MenuItem value={"no"}>Nein, ich kann nicht übernehmen</MenuItem>
                            </ValidatorSelect>
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                )}
                {!isLawyerCounselingCall && (
                  <Grid item xs={12} className={classes.topSpace}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Zufriedenheit des Mandanten mit der Beratung</FormLabel>
                      <div className={classes.ratingContainer}>
                        <Rating
                          name={"customerSatisfaction"}
                          value={parseInt(values.customerSatisfaction)}
                          onChange={(e, value) => {
                            handleChange({ target: { name: e.target.name, value: value } });
                          }}
                          disabled={activity.done}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12}>
              <ValidatorTextField
                label={"Hinweise zum Gespräch oder weiteren Mandatstätigkeiten"}
                multiline
                rows={8}
                name={"note"}
                value={formValue(values, "note")}
                onChange={handleChange}
                error={!!errors["note"]}
                helperText={errors["note"]}
                disabled={activity.done}
                registerValidators={() => {}}
                validators={[]}
                isHighlighted={false}
                initialDependentValidationFields={[]}
              />
            </Grid>
            {recordingEnabled && (
              <>
                <Grid item xs={12}>
                  <ValidatorTextField
                    label={"Zusammenfassung des Gesprächs"}
                    multiline
                    rows={8}
                    name={"callSummary"}
                    value={formValue(values, "callSummary")}
                    onChange={handleChange}
                    error={!!errors["callSummary"]}
                    helperText={errors["callSummary"]}
                    disabled={activity.done || phoneCallLoading || phoneCall.summary === null}
                    registerValidators={() => {}}
                    validators={[]}
                    isHighlighted={false}
                    initialDependentValidationFields={[]}
                  />
                  {tooManyRetries && (
                    <Typography color={"warning.main"}>
                      Die Zusammenfassung konnte nicht abgerufen werden. Wenn etwas schief gegangen ist, wird das System
                      in regelmässigen Abständen versuchen die Zusammenfassung erneut zu erstellen. Bitte später noch
                      einmal versuchen oder die IT informieren.
                    </Typography>
                  )}
                  {(!phoneCall || phoneCall.summary === null) && !tooManyRetries && retries > 0 && (
                    <Stack direction="row" spacing={1} alignItems="flex-start">
                      <CircularProgress size={20} color={"info"} />
                      <Typography color={"info.main"}>
                        Die Zusammenfassung des Gesprächs liegt noch nicht vor und wird gerade generiert. Der Techniker
                        ist informiert!
                      </Typography>
                    </Stack>
                  )}
                  {phoneCall && phoneCall.summary !== null && (
                    <Typography>
                      Feld wird durch AI gefüllt, sobald die Zusammenfassung generiert wurde. Sobald diese vorhanden
                      ist, bitte Korrektheit überprüfen und ggfs. anpassen.
                    </Typography>
                  )}
                </Grid>
              </>
            )}
            {!currentUser.isExternal && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={"dontSendSummaryToClient"}
                        value={formValue(values, "dontSendSummaryToClient")}
                        onChange={handleChange}
                      />
                    }
                    label={"Mandantschaft keine Zusammenfassung schicken"}
                  />
                </Grid>
              </>
            )}
            {!currentUser.isExternal && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={"saveNoteAsFile"}
                        value={formValue(values, "saveNoteAsFile")}
                        onChange={handleChange}
                      />
                    }
                    label={"Telefon-Notiz als Dokument speichern"}
                  />
                </Grid>
                {values.saveNoteAsFile && (
                  <Grid item xs={12}>
                    <ValidatorElectronicFileFolderPathSelect
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                    />
                  </Grid>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <ValidatorTextField
                label={"Absagegrund für den Mandanten"}
                multiline
                rows={8}
                name={"cancelReason"}
                value={formValue(values, "cancelReason")}
                onChange={handleChange}
                helperText={
                  <Box color={values.cancelReason.length < 50 ? "red" : "#999"}>
                    Anzahl Zeichen {values.cancelReason.length} von mindestens 50
                  </Box>
                }
                registerValidators={() => {}}
                validators={[]}
                isHighlighted={false}
                initialDependentValidationFields={[]}
              />
            </Grid>
          </>
        )}
      </Grid>
    </LegalbirdIoModal>
  );
};

type CalendlyAssessmentModalProps = {
  activity: Activity;
} & Omit<CalendlyAssessmentModalInnerProps, "calendlyEvent">;

const CalendlyAssessmentModal = (props: CalendlyAssessmentModalProps) => {
  const { activity, open } = props;
  const [calendlyEvent, setCalendlyEvent] = useState();

  useEffect(() => {
    if (open && activity.calendlyEvent) {
      ApiClient.get(activity.calendlyEvent).then((response) => setCalendlyEvent(response));
    }
  }, [open, activity.calendlyEvent]);

  if (!calendlyEvent) {
    return null;
  }

  return <CalendlyAssessmentModalInner {...props} calendlyEvent={calendlyEvent} />;
};

export default CalendlyAssessmentModal;
