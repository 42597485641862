import React from "react";
import { LeadStatusChangeByCustomerPreventedHistoryLog } from "../../../../types/HistoryLog";
import { translate } from "../../../../services/Translations/translatorService";
import { ArrowRightAlt } from "@mui/icons-material";
import GenericLogEntry from "../GenericLogEntry";
import _ from "lodash";
import { getStageList } from "../../../../services/Product/ProductService";
import { cloneHistoryLogAsGeneric } from "../../../../services/historyService";
import { AbstractCase } from "../../../../types/AbstractCase";

type CaseHistoryLeadStatusChangeProps = {
  historyItem: LeadStatusChangeByCustomerPreventedHistoryLog;
  productClassName: AbstractCase["productClassName"];
};

export default function CaseHistoryLeadStatusChangeByCustomerPrevented({
  historyItem,
  productClassName,
}: CaseHistoryLeadStatusChangeProps) {
  const translatedCurrentStage = _.find(
    getStageList(productClassName),
    (stage) => stage.leadStatus === historyItem.logData.leadStatus.current
  )?.label;
  const translatedPreventedStage = _.find(
    getStageList(productClassName),
    (stage) => stage.leadStatus === historyItem.logData.leadStatus.prevented
  )?.label;

  return (
    <GenericLogEntry
      subject={translate("historyItem.entityStatusChangeByCustomerPrevented.label")}
      Icon={ArrowRightAlt}
      info={
        <div>
          Das Schieben des Falls von der Stage {translatedCurrentStage || "Leer"} in die Stage{" "}
          {translatedPreventedStage || "Leer"}
          aufgrund einer Aktion der Mandantschaft wurde nicht durchgeführt, da min. eine Pausierung am Fall aktiv ist.
        </div>
      }
      historyItem={cloneHistoryLogAsGeneric(historyItem)}
    />
  );
}
