import { useCase } from "../../../provider/CaseProvider";
import PageContentContainer from "../../Container/PageContentContainer";
import PageHeadline from "../../PageHeadline/PageHeadline";
import React, { useState } from "react";
import LegalBirdPageLoading from "../../ContentLoader/LegalBirdPageLoading";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useQuery } from "@tanstack/react-query";
import { Box, Stack } from "@mui/material";
import MediaObjectFolderView from "../../MediaObjectSection/MediaObjectFolderView";
import ButtonLoading from "../../Button/ButtonLoading";
import caseReferenceFileStyles from "../../Case/CaseReferenceFile/caseReferenceFileStyles";
import ContentBox from "../../ContentBox/ContentBox";
import { apiPost } from "../../../services/Api/apiCall";
import { useSnackbar } from "notistack";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { getCaseLink, isAbstractLitigationProduct } from "../../../services/Product/ProductService";

const CaseImportDocumentsFromPreviousInstancePage = () => {
  const { product, isLoading } = useCase();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();
  const [selectedMediaObjects, setSelectedMediaObjects] = useState<number[]>([]);
  let mediaObjectsFilterPreviousInstance = {
    product: product?.productClassName,
    productId: product?.previousInstanceCase?.id,
  };

  const { data: mediaObjects } = useQuery(
    queryKeys.collection("media_objects", mediaObjectsFilterPreviousInstance),
    () => fetchCollection("media_objects", mediaObjectsFilterPreviousInstance),
    {
      enabled: !!product,
    }
  );

  if (!product || isLoading) {
    return <LegalBirdPageLoading />;
  }

  if (!isAbstractLitigationProduct(product) || !product.previousInstanceCase) {
    return null;
  }

  const handleHighInstanceImportDocuments = async () => {
    let snackbarMessage = "Dokument erfolgreich importiert";
    try {
      await apiPost("/lb/services/copy_media_object_to_higher_instance", {
        productId: product.id,
        mediaObjectIds: selectedMediaObjects,
        createdBy: currentUser["@id"],
      });
    } catch (e) {
      snackbarMessage = "Dokument konnte nicht importiert werden";
    }

    enqueueSnackbar(snackbarMessage, {
      variant: "custom",
      buttonType: "link",
      buttonLink: getCaseLink(product),
      buttonTarget: "_self",
      buttonText: "Zum Fall",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  return (
    <>
      <PageContentContainer>
        <PageHeadline main="Dokumente aus Vorinstanz importieren" />
        <ContentBox>
          <Stack alignItems={"center"} spacing={2}>
            <Box sx={{ width: "100%" }}>
              <MediaObjectFolderView
                mediaObjects={mediaObjects?.["hydra:member"]}
                selectableView
                selectedMediaObjects={selectedMediaObjects}
                setSelectedMediaObjects={setSelectedMediaObjects}
                isMediaObjectNonInteractive={true}
              />
            </Box>
            <ButtonLoading
              isLoading={isLoading}
              onClick={handleHighInstanceImportDocuments}
              variant={"outlined"}
              sx={caseReferenceFileStyles.button}
            >
              Kopieren
            </ButtonLoading>
          </Stack>
        </ContentBox>
      </PageContentContainer>
    </>
  );
};

export default CaseImportDocumentsFromPreviousInstancePage;
