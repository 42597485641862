import personTranslations from "./personTranslations";
import litigationCaseTranslations from "./litigationCaseTranslations";
import abstractCaseTranslations from "./abstractCaseTranslations";
import { prosecutionTranslations } from "./prosecutionTranslations";
import opposingPartyLegalEntityTranslations from "./opposingPartyLegalEntityTranslations";
import debtCollectionAgencyTranslations from "./debtCollectionAgencyTranslations";
import { objectivesLawFirmValues } from "./objectivesLawFirmTranslations";

const contractClaimTranslations = {
  ...litigationCaseTranslations,
  ...abstractCaseTranslations,
  additionalClaimText: {
    label: "Zusatztext Forderung",
  },
  additionalIssueDescriptionText: {
    label: "Zusatztext Schilderung Sachverhalt",
  },
  buyerPaidAmount: {
    label: "Gezahlter Betrag Käufer",
  },
  buyerPaidDate: {
    label: "Zahlungsdatum Käufer",
  },
  buyerPaidStatus: {
    label: "Bezahlstatus Käufer",
    values: {
      fully: "vollständig",
      partially: "teilweise",
      none: "noch gar nicht",
    },
  },
  clientLegalRepresentative: personTranslations,
  secondClientLegalRepresentative: {
    gender: {
      label: "Anrede des zweiten Vertreters",
      values: {
        male: "Herr",
        female: "Frau",
      },
    },
    givenName: {
      label: "Vorname des zweiten Vertreters",
    },
    familyName: {
      label: "Nachname des zweiten Vertreters",
    },
    residenceAddress: {
      streetAddress: {
        label: "Straße und Hausnummer des zweiten Vertreters",
      },
      postalCode: {
        label: "PLZ des zweiten Vertreters",
      },
      addressLocality: {
        label: "Ort des zweiten Vertreters",
      },
    },
  },
  responsibleProsecution: prosecutionTranslations,
  prosecutionReference: {
    label: "Aktenzeichen Staatsanwaltschaft",
  },
  contractNumber: {
    label: "Vertragsnummer",
  },
  contractDate: {
    label: "Vertragsabschluss am",
  },
  clientWasMinorAtContractDate: {
    label: "Person minderjährig?",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  clientLegalRepresentativeAmount: {
    label: "Anzahl gesetzlicher Vertreter",
    values: {
      1: "Einer",
      2: "Zwei",
    },
  },
  clientLegalRepresentativesAuthorizationType: {
    label: "Art der gesetzlichen Vertretung",
    values: {
      both: "Gesamtvertretung",
      individually: "Einzelvertretung",
    },
  },
  contractualPartnerPerson: personTranslations,
  contractualPartnerLegalEntity: {
    label: "Gegenseite",
    ...opposingPartyLegalEntityTranslations,
  },
  debtCollectionAgencyReference: {
    label: "Aktenzeichen",
  },
  debtCollectionAgency: {
    label: "Inkasso",
    ...debtCollectionAgencyTranslations,
  },
  goodsOrServiceProvidedStatus: {
    label: "Status Leistungserbringung/Lieferung",
    values: {
      fully: "vollständig",
      partially: "teilweise",
      none: "noch gar nicht",
    },
  },
  goodsOrServiceProvidedDate: {
    label: "Datum der Leistung/Lieferung",
  },
  claimedDateClient: {
    label: "Datum Forderungsschreiben Mandant",
  },
  claimedDeadlineClient: {
    label: "Forderungsfrist Mandant",
  },
  clientHasAlreadyClaimed: {
    label: "Mandantschaft hat bereits eingefordert",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  objectiveClient: {
    label: "Zielsetzung Mandantschaft",
    values: {
      fulfillment: "Erfüllung des Vertrags",
      withdrawal: "Rücktritt vom Vertrag",
      replacement: "Neulieferung",
      repair: "Nachbesserung oder Reparatur",
      reduction: "Volle oder teilweise Erstattung",
      compensation: "Schadensersatz",
      other: "Andere Zielsetzung",
    },
  },
  objectiveLawFirm: {
    label: "Zielsetzung Kanzlei",
    values: objectivesLawFirmValues,
  },
  objectiveLawFirmSecondary: {
    label: "Sekundäre Zielsetzung Kanzlei",
    values: objectivesLawFirmValues,
  },
  objectiveLawFirmTertiary: {
    label: "Tertiäre Zielsetzung Kanzlei",
    values: objectivesLawFirmValues,
  },
  paymentOrderDate: {
    label: "Datum des Mahnbescheids",
  },
  enforcementOrderDate: {
    label: "Datum des Vollstreckungsbescheids",
  },
  claimedAmountClient: {
    label: "Forderungsbetrag Mandantschaft",
  },
  overdueFees: {
    label: "Mahngebühren",
  },
  claimedAmountLawFirm: {
    label: "Forderungsbetrag Kanzlei",
  },
  subjectOfDispute: {
    label: "Forderung",
    values: {
      money: "Geld",
      goods: "Ware oder Leistung",
      other: "Sonstiges",
    },
    enforceClaim: {
      label: "Forderung Mandantschaft",
      values: {
        money: "Geld",
        goods: "Ware oder Leistung",
        other: "Sonstiges",
      },
    },
    defendClaim: {
      label: "Forderung Gegenseite",
      values: {
        money: "Geld",
        goods: "Ware oder Leistung",
        other: "Sonstiges",
      },
    },
  },
  trialWanted: {
    label: "Gerichtsverfahren gewünscht",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  advisoryAppointment: {
    label: "Beratungsgespräch",
  },
  hasMonitoringBilling: {
    label: "Abrechnung Monitoringgebühr",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
};

export default contractClaimTranslations;
