/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React, { useState } from "react";
import MicIcon from "@mui/icons-material/Mic";
import { IconButton } from "@mui/material";
import Softphone from "./Softphone";
import Grid from "@mui/material/Grid";
import { AbstractCase } from "../../types/AbstractCase";
import { CalendlyEvent } from "../../types/Calendly/CalendlyEvent";

type LegalbirdCallProps = {
  product: AbstractCase;
  calendlyEvent?: CalendlyEvent;
};

const LegalbirdCall = ({ product, calendlyEvent }: LegalbirdCallProps) => {
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [isActive, setIsActive] = useState(false);

  const handleAudioContextStart = () => {
    if (!audioContext) {
      const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();

      setAudioContext(newAudioContext);

      newAudioContext
        .resume()
        .then(() => {
          console.log("AudioContext started.");
          setIsActive(true);
        })
        .catch((error) => {
          console.error("Error starting AudioContext:", error);
        });
    } else {
      if (audioContext.state === "suspended") {
        audioContext
          .resume()
          .then(() => {
            console.log("AudioContext resumed.");
            setIsActive(true);
          })
          .catch((error: unknown) => {
            console.error("Error resuming AudioContext:", error);
          });
      }
    }
  };

  return (
    <>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <IconButton
            color={isActive ? "primary" : "default"}
            onClick={handleAudioContextStart}
            aria-label="Activate Microphone"
          >
            <MicIcon fontSize={"large"} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {isActive && <Softphone product={product} calendlyEvent={calendlyEvent} />}

          {!isActive && (
            <p className={"center"}>Bitte Mikrofon-Button drücken, um das Mikrofon für den Anruf zu aktivieren</p>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default LegalbirdCall;
