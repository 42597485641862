import _ from "lodash";
import ApiClient from "../ApiClient";

export async function call(
  method: string,
  iri: string,
  data: Record<string, any>,
  options: Record<string, any> = {}
): Promise<any> {
  options.body = JSON.stringify(data);
  return ApiClient.call(method, iri, options);
}

export async function apiGet(
  iri: string,
  id: number | null = null,
  data: Record<string, any> = {},
  options: Record<string, any> = {}
): Promise<any> {
  let fullIri = id ? iri + "/" + id : iri;
  if (!_.isEmpty(data)) {
    const urlParams = new URLSearchParams(data);
    fullIri = fullIri + "?" + urlParams.toString();
  }
  return ApiClient.call("get", fullIri, options);
}

export async function apiPost(
  iri: string,
  data: Record<string, any> = {},
  options: Record<string, any> = {}
): Promise<any> {
  options.body = JSON.stringify(data);
  return ApiClient.call("post", iri, options);
}

export async function apiPut(
  iri: string,
  id: number | null,
  data: Record<string, any>,
  options: Record<string, any> = {}
): Promise<any> {
  const fullIri = id ? iri + "/" + id : iri;
  options.body = JSON.stringify(data);
  return ApiClient.call("put", fullIri, options);
}

export async function apiDelete(iri: string, id: number, options: Record<string, any> = {}): Promise<any> {
  const fullIri = iri + "/" + id;
  return ApiClient.call("delete", fullIri, options);
}

export async function apiToken(credentials: { email: string; password: string; }): Promise<string> {
  return ApiClient.getToken(credentials);
}
