import React, { useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./contactStyle";
import { Button, TextField } from "@mui/material";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import { Call } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { getProductData, getResponsibleInstitutes } from "../../services/Product/ProductService";
import InputAdornment from "@mui/material/InputAdornment";
import { AbstractCase } from "../../types/AbstractCase";

type ExtendedContactProps = {
  product: AbstractCase;
};
const ExtendedContact = ({ product }: ExtendedContactProps) => {
  const classes = useStyles({});
  const [showMore, setShowMore] = useState(false);

  if (
    ![
      "divorce",
      "settlement",
      "traffic",
      "trafficAccident",
      "eventContract",
      "membershipContract",
      "otherContract",
      "providerContract",
      "dataLeakContract",
      "purchaseContract",
      "serviceContract",
      "subscriptionContract",
      "travelContract",
      "vehicleContract",
      "rentalContract",
    ].includes(product.productClassName) ||
    product.leadStatus < getProductData(product.productClassName, "minimumCaseStatus")
  ) {
    return null;
  }

  const { courtPath, authorityPath } = getResponsibleInstitutes(product);

  return (
    <>
      {showMore && (
        <>
          {product.productClassName === "traffic" && (
            <>
              <div className={classes.headline}>Zuständige Behörde</div>
              <TextField
                multiline
                label={"Zuständige Behörde"}
                value={
                  (_.get(product, authorityPath + ".name") || "") +
                  "\n" +
                  (_.get(product, authorityPath + ".department") || "") +
                  "\n" +
                  (_.get(product, authorityPath + ".subDepartment") || "")
                }
                disabled
              />
              <TextField
                label={"Faxnummer Behörde"}
                value={_.get(product, authorityPath + ".fax") || ""}
                disabled
                InputProps={{
                  startAdornment: <InputAdornment position="start">+49</InputAdornment>,
                }}
              />
              <Grid container alignItems={"flex-start"}>
                <Grid item xs={10}>
                  <TextField
                    label={"Telefonnummer Behörde"}
                    value={_.get(product, authorityPath + ".telephone") || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={2} className={classes.callGridItem}>
                  <IconButton
                    color={"secondary"}
                    className={classes.callIcon}
                    href={"tel:" + _.get(product, authorityPath + ".telephone")}
                    disabled={!_.get(product, authorityPath + ".telephone")}
                    size="large"
                  >
                    <Call />
                  </IconButton>
                </Grid>
              </Grid>
            </>
          )}
          <div className={classes.headline}>Zuständiges Gericht</div>
          <TextField label={"Zuständiges Gericht"} value={_.get(product, courtPath + ".name") || ""} disabled />
          <TextField
            label={"Faxnummer Gericht"}
            value={_.get(product, courtPath + ".fax") || ""}
            disabled
            InputProps={{
              startAdornment: <InputAdornment position="start">+49</InputAdornment>,
            }}
          />
          <Grid container alignItems={"flex-start"}>
            <Grid item xs={10}>
              <TextField
                label={"Telefonnummer Gericht"}
                value={_.get(product, courtPath + ".telephone") || ""}
                disabled
              />
            </Grid>
            <Grid item xs={2} className={classes.callGridItem}>
              <IconButton
                color={"secondary"}
                className={classes.callIcon}
                href={"tel:" + _.get(product, courtPath + ".telephone")}
                disabled={!_.get(product, courtPath + ".telephone")}
                size="large"
              >
                <Call />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}
      <Grid container justifyContent={"center"} className={classes.moreButton}>
        <Grid item>
          <Button
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            {showMore ? "Weniger" : "Mehr"} Anzeigen
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
ExtendedContact.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ExtendedContact;
