import * as stagesCase from "../../Stages/StagesCase";
import moment from "moment/moment";
import { validateFields } from "../statusChangeValidators";
import { trafficAccidentRequiredFields } from "./trafficAccidentRequiredFields";
import { TrafficAccident } from "../../../types/TrafficAccident";
import _ from "lodash";

export const trafficAccidentValidators = {
  [stagesCase.STAGE_CREATED]: (trafficAccidentCase: TrafficAccident) =>
    validateFields(trafficAccidentRequiredFields[stagesCase.STAGE_CREATED](), trafficAccidentCase),
  [stagesCase.STAGE_DATA_COMPLETED]: (trafficAccidentCase: TrafficAccident) =>
    validateFields(trafficAccidentRequiredFields[stagesCase.STAGE_DATA_COMPLETED](), trafficAccidentCase),
  [stagesCase.STAGE_WAITING_FOR_FEEDBACK]: () => [], // this stage is not supposed to have any validators, even though it has required fields
  [stagesCase.STAGE_DATA_COMPLETED_LAWFIRM]: (trafficAccidentCase: TrafficAccident) => [
    ...validateFields(
      trafficAccidentRequiredFields[stagesCase.STAGE_DATA_COMPLETED_LAWFIRM](trafficAccidentCase),
      trafficAccidentCase
    ),
    ...validateClaimsForDamages(trafficAccidentCase),
  ],
  [stagesCase.STAGE_CHECKED]: (trafficAccidentCase: TrafficAccident) => [
    ...validateFields(
      trafficAccidentRequiredFields[stagesCase.STAGE_CHECKED](trafficAccidentCase),
      trafficAccidentCase
    ),
    ...validateClaimsForDamages(trafficAccidentCase),
  ],
  [stagesCase.STAGE_IN_PROGRESS]: (trafficAccidentCase: TrafficAccident) =>
    validateFields(trafficAccidentRequiredFields[stagesCase.STAGE_IN_PROGRESS](), trafficAccidentCase),
  [stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT]: (trafficAccidentCase: TrafficAccident) =>
    validateFields(
      trafficAccidentRequiredFields[stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT](),
      trafficAccidentCase
    ),
  [stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED]: (trafficAccidentCase: TrafficAccident) =>
    validateFields(
      trafficAccidentRequiredFields[stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED](trafficAccidentCase),
      trafficAccidentCase
    ),
  [stagesCase.STAGE_ACTION_REQUIRED]: (trafficAccidentCase: TrafficAccident) =>
    validateFields(trafficAccidentRequiredFields[stagesCase.STAGE_ACTION_REQUIRED](), trafficAccidentCase),
  [stagesCase.STAGE_PREPARE_LAWSUIT]: (trafficAccidentCase: TrafficAccident) => {
    return validateFields(trafficAccidentRequiredFields[stagesCase.STAGE_PREPARE_LAWSUIT](), trafficAccidentCase);
  },
  [stagesCase.STAGE_LAWSUIT]: (trafficAccidentCase: TrafficAccident) => {
    let errorMessages = validateFields(
      trafficAccidentRequiredFields[stagesCase.STAGE_LAWSUIT](trafficAccidentCase),
      trafficAccidentCase
    );

    if (trafficAccidentCase.insurance.judicialCoverageGranted !== true) {
      errorMessages.push("Die gerichtliche Deckung muss gegeben sein");
    }

    return errorMessages;
  },
  [stagesCase.STAGE_TRIAL]: (trafficAccidentCase: TrafficAccident) => {
    return validateFields(trafficAccidentRequiredFields[stagesCase.STAGE_TRIAL](), trafficAccidentCase);
  },
  [stagesCase.STAGE_EVALUATE_JUDGEMENT]: () => true,
  [stagesCase.STAGE_LAWSUIT_COMPLETED]: (trafficAccidentCase: TrafficAccident) => {
    let errorMessages = validateFields(
      trafficAccidentRequiredFields[stagesCase.STAGE_LAWSUIT_COMPLETED](trafficAccidentCase),
      trafficAccidentCase
    );

    if (
      trafficAccidentCase.lawsuitCompletedReason === "defaultJudgement" &&
      moment().isBefore(moment(trafficAccidentCase.lawsuitCompletedDate))
    ) {
      errorMessages.push("Das Versäumnisurteil muss rechtskräftig sein (Rechtskräftige VU am)");
    }

    return errorMessages;
  },
  [stagesCase.STAGE_COMPLETED]: (trafficAccidentCase: TrafficAccident) => {
    return validateFields(trafficAccidentRequiredFields[stagesCase.STAGE_COMPLETED](), trafficAccidentCase);
  },
};

const validateClaimsForDamages = (trafficAccidentCase: TrafficAccident) => {

  if (trafficAccidentCase.caseCategoryAssessment === "reject") {
    return [];
  }

  let errorMessages = [];

  if (_.isEmpty(trafficAccidentCase.claimsForDamages)) {
    errorMessages.push("Das Feld \"Schadensersatzansprüche\" muss ausgefüllt sein!");
  }

  if (_.some(trafficAccidentCase.claimsForDamages, (claimExtension) => !claimExtension.extensionValue)) {
    errorMessages.push("Mindestens ein \"Höhe Schadensersatz\" Wert fehlt");
  }

  return errorMessages;
};
