/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import { FormTransformations } from "../../../types/ProductService/ProductService";

const trafficAccidentTransformations: FormTransformations = {
  dateFields: [
    { path: "accidentDate", type: "date" },
    { path: "lawsuitCompletedDate", type: "date" },
    { path: "courtDate.appointmentDate", type: "date" },
    { path: "customerLawsuitCompletedApprovalDate", type: "date" },
    { path: "extrajudicialCommunicationDeadline", type: "date" },
    { path: "extrajudicialCommunicationSent", type: "date" },
    { path: "extrajudicialReactionReceived", type: "date" },
    { path: "powerOfAttorney", type: "dateTime" },
    { path: "replicaFiledDate", type: "date" },
    { path: "replicaSentDate", type: "date" },
    { path: "insurance.extrajudicialCoverageRequestedDate", type: "date" },
    { path: "insurance.extrajudicialCoverageRequestAnsweredDate", type: "date" },
    { path: "insurance.judicialCoverageRequestedDate", type: "date" },
    { path: "insurance.judicialCoverageRequestAnsweredDate", type: "date" },
    { path: "judgementReceivedDate", type: "date" },
    { path: "promulgationOfJudgementDate", type: "date" },
    { path: "judgementEebSentDate", type: "date" },
    { path: "statementOfClaimSentDate", type: "date" },
    { path: "principalClaimDefaultDate", type: "date" },
    { path: "accessoryClaimDefaultDate", type: "date" },
  ],
  numberFields: ["leadStatus", "applicationStatus", "responsibleCourt.advoAssistId"],
  timeFields: ["courtDate.appointmentTime", "accidentTime"],
  floatFields: [
    "valueInDisputePreliminary",
    "valueInDispute",
    "netRvgAmount",
    "costBearingByOpponentFinalAbsolute",
    "courtCostsFinal",
    "claimedAmountLawFirm",
    "costReal",
    "costEstimate",
    "costBearingByOpponentRelative",
    "insurance.deductible",
    "principalClaim",
    "accessoryClaim",
  ],
  ibanFields: ["clientBankData.iban"],
  extendedSelectFields: ["claimsForDamages"],
  entityIriFields: ["opposingCarLiabilityInsurance"],
};

export default trafficAccidentTransformations;
