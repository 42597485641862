import React from "react";
import { Route, Routes } from "react-router-dom";
import CaseAllDataPage from "../components/_Pages/CaseAllDataPage/CaseAllDataPage";
import CaseHistoryPage from "../components/_Pages/CaseHistoryPage/CaseHistoryPage";
import CaseAccountingPage from "../components/_Pages/CaseAccountingPage/CaseAccountingPage";
import CaseFileGeneratePage from "../components/_Pages/CaseFileGeneratePage/CaseFileGeneratePage";
import CasePaidTaskPage from "../components/_Pages/CasePaidTaskPage/CasePaidTaskPage";
import CaseReferenceFilePage from "../components/_Pages/CaseReferenceFilePage/CaseReferenceFilePage";
import CasePage from "../components/_Pages/CasePage/CasePage";
import useProductParams from "../hooks/useProductParams";
import CaseProvider from "../provider/CaseProvider";
import CaseRelatedActions from "../components/Case/CaseRelatedActions/CaseRelatedActions";
import CaseImportDocumentsFromPreviousInstancePage from "../components/_Pages/CaseImportDocumentsFromPreviousInstancePage/CaseImportDocumentsFromPreviousInstancePage";

const CaseRoutes = () => {
  const { productId, productUrlPart } = useProductParams();
  if (!productUrlPart) {
    return null;
  }

  return (
    <CaseProvider caseUrlPart={productUrlPart} caseId={productId}>
      <CaseRelatedActions />

      <Routes>
        <Route path={"/"} element={<CasePage />} />
        <Route path={"/alle-daten"} element={<CaseAllDataPage />} />
        <Route path={"/konto"} element={<CaseAccountingPage />} />
        <Route path={"/verlauf"} element={<CaseHistoryPage />} />
        <Route path={"/datei-generieren"} element={<CaseFileGeneratePage />} />
        <Route path={"/dokumente-importieren/vorinstanz"} element={<CaseImportDocumentsFromPreviousInstancePage />} />
        <Route path={"/ausschreibung/:paidTaskId"} element={<CasePaidTaskPage />} />
        <Route path={"/handakte/:courtAppointmentId"} element={<CaseReferenceFilePage />} />
      </Routes>
    </CaseProvider>
  );
};

export default CaseRoutes;
