/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import { STAGE_DATA_COMPLETED_LAWFIRM } from "../../Stages/StagesCase";
import PreviewButton from "../../../components/Preview/PreviewButton";
import React from "react";
import { TrafficAccident } from "../../../types/TrafficAccident";

export const trafficAccidentCaseDetailsSpecialActions = (product: TrafficAccident) => {
  let specialActions = [];

  if (product.stage === STAGE_DATA_COMPLETED_LAWFIRM) {
    specialActions.push(
      <PreviewButton
        key={"preview-claimLetter"}
        product={product}
        documentType={"pdf"}
        documentIdentifier={"extrajudicialLetter"}
        buttonText={"Vorschau für Schreiben"}
        modalHeadline={"Vorschau außergerichtliches Schreiben"}
      />
    );
  }

  if (specialActions.length === 0) {
    return null;
  }

  return specialActions;
};
