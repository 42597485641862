/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

const callTranslations = {
  callStatus: {
    initializing: "Vorbereiten...",
    ready: "Bereit zum Telefonieren",
    ended: "Anruf beendet",
    inCallWithInboundCaller: "Im Gespräch mit eingehendem Anrufer",
  },
};

export default callTranslations;
