import { formValue } from "../../formServiceFunctions";
import { AbstractCase } from "../../../types/AbstractCase";
import { FormElementPropsWithoutProduct } from "../../../components/Case/CaseForm/FormElement";

const formDefinitionExternals: Record<AbstractCase["productClassName"], FormElementPropsWithoutProduct[]> = {
  familyLawCase: [
    {
      type: "ExpandableTextField",
      path: `familyLawCase.personalTextMessage`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
  ],
  death: [
    {
      type: "ValidatorSelect",
      path: "death.generalCategory",
      isDisabled: () => true,
      options: () => [{ value: "death" }, { value: "inheritance" }, { value: "both" }],
    },
    {
      type: "ValidatorSelect",
      path: "death.deathCategory",
      isDisabled: () => true,
      options: () => [{ value: "current" }, { value: "preparation" }],
    },
    {
      type: "ValidatorSelect",
      path: "death.inheritanceCategory",
      isDisabled: () => true,
      options: () => [{ value: "heir" }, { value: "bequeather" }],
    },
    {
      type: "ExpandableTextField",
      path: "death.personalTextMessage",
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
  ],
  alimony: [
    {
      type: "ExpandableTextField",
      path: "alimony.personalTextMessage",
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
  ],
  divorce: [
    {
      type: "ExpandableTextField",
      path: "divorce.personalTextMessage",
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
  ],
  traffic: [
    {
      type: "ValidatorSelect",
      path: "traffic.accusation",
      isDisabled: () => true,
      options: () => [
        { value: "__null__" },
        { value: "speeding" },
        { value: "red_light" },
        { value: "distance" },
        { value: "other" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: "traffic.accusationOther",
      isDisabled: () => true,
      isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "other",
      options: () => [
        { value: "__null__" },
        { value: "mobile_phone" },
        { value: "drugs" },
        { value: "parking" },
        { value: "other" },
      ],
    },
  ],
  trafficAccident: [],
  settlement: [
    {
      type: "ExpandableTextField",
      path: "settlement.personalTextMessage",
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
  ],
  shortTimeWork: [],
  rentalContract: [],
  dataLeakContract: [],
  serviceContract: [],
  travelContract: [],
  membershipContract: [],
  vehicleContract: [],
  eventContract: [],
  otherContract: [],
  employmentContract: [],
  providerContract: [],
  purchaseContract: [],
  subscriptionContract: [],
  patientDecree: [],
  powerOfAttorney: [],
};
export default formDefinitionExternals;
