/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import * as stage from "./StagesCase";
import { StageMetaData } from "../../types/ProductService/ProductService";
import { AbstractCase } from "../../types/AbstractCase";
import { stageMetaDataCase } from "./StagesCase";

export const stageMetaDataTrafficAccident: StageMetaData = {
  [stage.STAGE_CREATED]: stageMetaDataCase[stage.STAGE_CREATED],
  [stage.STAGE_DATA_COMPLETED]: stageMetaDataCase[stage.STAGE_DATA_COMPLETED],
  [stage.STAGE_WAITING_FOR_FEEDBACK]: stageMetaDataCase[stage.STAGE_WAITING_FOR_FEEDBACK],
  [stage.STAGE_DATA_COMPLETED_LAWFIRM]: {
    ...stageMetaDataCase[stage.STAGE_DATA_COMPLETED_LAWFIRM],
    isClickable: ({ product }: { product: AbstractCase }) => product.stage !== stage.STAGE_DATA_COMPLETED_LAWFIRM,
  },
  [stage.STAGE_CHECKED]: stageMetaDataCase[stage.STAGE_CHECKED],
  [stage.STAGE_IN_PROGRESS]: {
    ...stageMetaDataCase[stage.STAGE_IN_PROGRESS],
    isClickable: ({ product }: { product: AbstractCase }) =>
      product.stage !== stage.STAGE_IN_PROGRESS && product.stage >= stage.STAGE_CHECKED,
  },
  [stage.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT]: {
    ...stageMetaDataCase[stage.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT],
    label: "Schreiben an KFZ-Haftpflicht",
    isClickable: ({ product }: { product: AbstractCase }) =>
      product.stage !== stage.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT && product.stage >= stage.STAGE_CHECKED,
  },
  [stage.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED]: {
    ...stageMetaDataCase[stage.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED],
    label: "Reaktion der KFZ-Haftpflicht",
    isClickable: ({ product }: { product: AbstractCase }) =>
      product.stage !== stage.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED &&
      product.stage >= stage.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT,
  },
  [stage.STAGE_ACTION_REQUIRED]: {
    ...stageMetaDataCase[stage.STAGE_ACTION_REQUIRED],
    isClickable: ({ product }: { product: AbstractCase }) =>
      product.stage !== stage.STAGE_ACTION_REQUIRED && product.stage >= stage.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT,
  },
  [stage.STAGE_PREPARE_LAWSUIT]: {
    ...stageMetaDataCase[stage.STAGE_PREPARE_LAWSUIT],
    isActive: () => true,
    isClickable: ({ product }: { product: AbstractCase }) =>
      product.stage !== stage.STAGE_PREPARE_LAWSUIT && product.stage >= stage.STAGE_ACTION_REQUIRED,
  },
  [stage.STAGE_LAWSUIT]: {
    ...stageMetaDataCase[stage.STAGE_LAWSUIT],
    isActive: ({ product }: { product: AbstractCase }) => product.finalClaimStatusClient !== "fullyAgreed",
    isClickable: ({ product }: { product: AbstractCase }) =>
      product.stage !== stage.STAGE_LAWSUIT && product.stage >= stage.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED,
  },
  [stage.STAGE_TRIAL]: {
    ...stageMetaDataCase[stage.STAGE_TRIAL],
    isActive: ({ product }: { product: AbstractCase }) => product.finalClaimStatusClient !== "fullyAgreed",
    isClickable: ({ product }: { product: AbstractCase }) =>
      product.stage !== stage.STAGE_TRIAL && product.stage >= stage.STAGE_LAWSUIT,
  },
  [stage.STAGE_EVALUATE_JUDGEMENT]: stageMetaDataCase[stage.STAGE_EVALUATE_JUDGEMENT],
  [stage.STAGE_LAWSUIT_COMPLETED]: stageMetaDataCase[stage.STAGE_LAWSUIT_COMPLETED],
  [stage.STAGE_COMPLETED]: stageMetaDataCase[stage.STAGE_COMPLETED],
};
