import React, { useState } from "react";
import ValidatorTextField from "../Validator/ValidatorTextField";
import MenuItem from "@mui/material/MenuItem";
import useForm from "../../hooks/useForm";
import { formValue, prepareFormCustomerDates } from "../../services/formServiceFunctions";
import ValidatorSelect from "../Validator/ValidatorSelect";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import { difference, persistCustomer } from "../../services/Persist/Persister";
import {
  duplicateMailDefault,
  emailDefault,
  requiredFieldDefault,
  textFieldDefault,
} from "../../services/validationRules";
import SubmitButton from "../Button/SubmitButton";
import IconButton from "@mui/material/IconButton";
import { Call } from "@mui/icons-material";
import useStyles from "./contactStyle";
import { translate } from "../../services/Translations/translatorService";
import ValidatorForm from "../Validator/ValidatorForm";
import InputAdornment from "@mui/material/InputAdornment";
import { useCase } from "../../provider/CaseProvider";
import { UseForm } from "../../types/UseForm";
import FormElementAlert from "../Case/CaseForm/FormElementAlert";
import moment from "moment/moment";
import { TextField } from "@mui/material";

type AllDataContactProps = {
  formDisabled: boolean;
};

const AllDataContact = ({ formDisabled = false }: AllDataContactProps) => {
  const classes = useStyles({});
  const { product, refreshCase } = useCase();
  const customer = product!.customer;
  const [showAlert, setShowAlert] = useState(false);

  const timeSlots = [];
  for (let i = 8; i <= 18; i++) {
    timeSlots.push(
      <MenuItem key={i} value={"" + i}>
        {i} {translate("customer.contactTime.values.time")}
      </MenuItem>
    );
  }

  const initialValues = { customer: prepareFormCustomerDates(customer) };
  const onSubmit = async () => {
    if (!customer) {
      return;
    }
    await persistCustomer(customer.id, difference(values.customer, prepareFormCustomerDates(customer)));
    await refreshCase();
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    registerValidators,
    touchedValues,
    isLoading,
  }: UseForm = useForm({
    initialValues,
    onSubmit,
    // @ts-ignore
    identifier: product,
  });

  if (!product) {
    return null;
  }

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Grid container className={classes.grid}>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorTextField
            onChange={handleChange}
            onBlur={handleBlur}
            name={"customer.telephone"}
            value={formValue(values, "customer.telephone")}
            label={translate("customer.telephone.label")}
            disabled={formDisabled}
            registerValidators={registerValidators}
            validators={textFieldDefault}
            error={!!errors["customer.telephone"]}
            helperText={errors["customer.telephone"]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color={"secondary"}
                    className={classes.callIcon}
                    href={"tel:" + formValue(values, "customer.telephone")}
                    disabled={!formValue(values, "customer.telephone")}
                    size="large"
                  >
                    <Call />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            isHighlighted={false}
            initialDependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorTextField
            onChange={handleChange}
            onBlur={() => setShowAlert(true)}
            name={"customer.email"}
            value={formValue(values, "customer.email")}
            label={translate("customer.email.label")}
            disabled={formDisabled}
            registerValidators={registerValidators}
            validators={[...emailDefault, ...requiredFieldDefault, ...duplicateMailDefault(customer.email)]}
            error={!!errors["customer.email"]}
            helperText={errors["customer.email"]}
            isHighlighted={false}
            initialDependentValidationFields={[]}
          />
          <FormElementAlert
            alert={{
              onChange:
                "Das Ändern der E-Mail-Adresse führt dazu, dass der Kunde sich nicht mehr mit seiner alten Adresse in der Servicewelt anmelden kann, sondern nur noch mit der neuen Adresse.",
            }}
            initialValue={customer.email}
            currentValue={formValue(values, "customer.email")}
            path={"customer.email"}
            setIsShowAlert={setShowAlert}
            isShowAlert={showAlert}
            handleChange={handleChange}
            label={translate("customer.email.label")}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorTextField
            name="customer.verification"
            label={translate("customer.verification.label")}
            value={_.defaultTo(values.customer.verification, "")}
            disabled
            isHighlighted={false}
            initialDependentValidationFields={[]}
            registerValidators={registerValidators}
            validators={[]}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            label={translate("customer.created.label")}
            value={moment(product.created).format("DD.MM.YYYY HH:mm")}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorSelect
            label={translate("customer.contactTime.label")}
            name={"customer.contactTime"}
            value={formValue(values, "customer.contactTime")}
            disabled={formDisabled}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <MenuItem value={"flexible"}>{translate("customer.contactTime.values.flexible")}</MenuItem>
            {timeSlots}
          </ValidatorSelect>
        </Grid>
        <Grid item xs={12}>
          <SubmitButton
            error={_.keys(errors).length > 0}
            isLoading={isLoading}
            disabled={_.keys(touchedValues).length === 0}
            variant={"contained"}
            type={"submit"}
          >
            Änderungen Speichern
          </SubmitButton>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default AllDataContact;
