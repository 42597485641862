/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import { AbstractCase } from "../../../../types/AbstractCase";
import personFields from "../modules/personFields";
import * as stagesCase from "../../../Stages/StagesCase";
import { dateFieldDefault, invoiceNetValueNotOverpaid, percentageFieldDefault } from "../../../validationRules";
import _ from "lodash";
import { trueFalseDefaultOptions } from "../formDefinitionFunctions";
import { UseForm } from "../../../../types/UseForm";
import { stageFormDefinition as contractStageFormDefinition } from "../contract/stageFormDefinition";
import DateChangeButtonsToolbar from "../../../../components/Validator/DateChangeButtonsToolbar";
import { FormDefinition } from "../../../../types/ProductService/ProductService";
import { FormElementPropsWithoutProduct } from "../../../../components/Case/CaseForm/FormElement";
import { BackofficeUser } from "../../../../types/BackofficeUser";
import { TrafficAccident } from "../../../../types/TrafficAccident";

export const stageFormDefinition = (
  product: AbstractCase,
  currentUser?: BackofficeUser
): ReturnType<FormDefinition> => {
  const createdFields: FormElementPropsWithoutProduct[] = [
    ...personFields({ path: `${product.productClassName}.client`, showDiverseGenderOption: true }),
  ];

  const dataCompletedFields: FormElementPropsWithoutProduct[] = [
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfDamage`,
      options: () => [{ value: "vehicle" }, { value: "person" }, { value: "vehicleAndPerson" }, { value: "other" }],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfAccident`,
      options: () => [
        { value: "rearEndCollision" },
        { value: "laneChangeCollision" },
        { value: "parking" },
        { value: "other" },
      ],
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.courseOfEvents`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.legalExpenseInsurance`,
      options: () => trueFalseDefaultOptions("trafficAccident.legalExpenseInsurance"),
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: TrafficAccident }) => product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategoryAssessment`,
      isDisabled: ({ product }: { product: TrafficAccident }) =>
        (product.stage || 0) > stagesCase.STAGE_WAITING_FOR_FEEDBACK,
      isHidden: () => false,
      options: () => [{ value: "confirm" }, { value: "reject" }],
    },
    {
      type: "CaseRejectionCategorySelect",
      path: `${product.productClassName}.caseRejectionCategory`,
      options: () => [{ value: "none" }, { value: "individual" }],
      isDisabled: ({ product }: { product: TrafficAccident }) =>
        (product.stage || 0) > stagesCase.STAGE_WAITING_FOR_FEEDBACK,
      isHidden: ({ values }: { values: UseForm["values"] }) =>
        values.trafficAccident.caseCategoryAssessment !== "reject",
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.assessmentRejectionText`,
      isHidden: ({ values }: { values: UseForm["values"] }) =>
        values.trafficAccident.caseCategoryAssessment !== "reject",
      additionalProps: {
        multiline: true,
        rows: 12,
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.courseOfEventsLawFirm`,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "SelectAndExtendedDataComboField",
      path: `${product.productClassName}.claimsForDamages`,
      additionalProps: {
        fullWidth: true,
        config: {
          values: [
            "repairCosts",
            "replacementCosts",
            "lossOfUse",
            "rentalCarCosts",
            "depreciation",
            "expertFees",
            "expensesFlatFee",
            "deregistrationCosts",
            "registrationCosts",
            "towingCosts",
            "storageCosts",
            "financingCosts",
          ],
          showOther: true,
          extensionFieldConfig: {
            type: "money",
            labelPrefix: "Höhe Schadensersatz",
            md: 12,
          },
        },
      },
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.valueInDisputePreliminary`,
      fieldType: "money",
      numberType: "float",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationDeadline`,
      validators: dateFieldDefault,
      additionalProps: {
        toolbar: DateChangeButtonsToolbar,
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.courtPostalCode`,
      helperText:
        "Bitte hier die Postleitzahl eintragen, die für den Gerichtsstand ermittelt wurde und NICHT die Postleitzahl des Gerichtes.",
      isHidden: ({ product, values }: { product: TrafficAccident; values: UseForm["values"] }) =>
        values.trafficAccident.caseCategoryAssessment === "reject" ||
        !product.isLawsuitHandling ||
        product.caseCategory === "defendClaim" ||
        product.isHigherInstanceCase,
      alert: {
        onChange:
          "Das Ändern der Postleitzahl führt dazu, dass das zuständige Gericht erneut ermittelt wird. Möchten Sie die Postleitzahl tatsächlich ändern?",
      },
    },
  ];

  const dataLawFirmCompletedFields: FormElementPropsWithoutProduct[] = [...dataCompletedFields];

  const checkedFields: FormElementPropsWithoutProduct[] = [
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfDamage`,
      isDisabled: () => true,
      options: () => [{ value: "vehicle" }, { value: "person" }, { value: "vehicleAndPerson" }, { value: "other" }],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfAccident`,
      isDisabled: () => true,
      options: () => [
        { value: "rearEndCollision" },
        { value: "laneChangeCollision" },
        { value: "parking" },
        { value: "other" },
      ],
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.courseOfEvents`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.legalExpenseInsurance`,
      options: () => trueFalseDefaultOptions("trafficAccident.legalExpenseInsurance"),
    },
    // claim fields
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategoryAssessment`,
      isDisabled: ({ product }: { product: TrafficAccident }) =>
        (product.stage || 0) > stagesCase.STAGE_WAITING_FOR_FEEDBACK,
      isHidden: () => false,
      options: () => [{ value: "confirm" }, { value: "reject" }],
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.assessmentRejectionText`,
      isHidden: ({ values }: { values: UseForm["values"] }) =>
        values.trafficAccident.caseCategoryAssessment !== "reject",
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationSent`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) => product.isLawsuitHandling,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationDeadline`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: TrafficAccident }) => product.isLawsuitHandling,
      additionalProps: {
        toolbar: DateChangeButtonsToolbar,
      },
    },
  ];

  const completedFields: FormElementPropsWithoutProduct[] = [
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfDamage`,
      isDisabled: () => true,
      options: () => [{ value: "vehicle" }, { value: "person" }, { value: "vehicleAndPerson" }, { value: "other" }],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfAccident`,
      isDisabled: () => true,
      options: () => [
        { value: "rearEndCollision" },
        { value: "laneChangeCollision" },
        { value: "parking" },
        { value: "other" },
      ],
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.courseOfEvents`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    // claim fields
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategoryAssessment`,
      isDisabled: ({ product }: { product: AbstractCase }) =>
        (product.stage || 0) > stagesCase.STAGE_WAITING_FOR_FEEDBACK,
      isHidden: () => false,
      options: () => [{ value: "confirm" }, { value: "reject" }],
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.assessmentRejectionText`,
      isHidden: ({ values }: { values: UseForm["values"] }) =>
        values.trafficAccident.caseCategoryAssessment !== "reject",
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
        rows: 12,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.finalClaimStatusClient`,
      translationPath: `${product.productClassName}.finalClaimStatusClient.${product.caseCategory}`,
      isDisabled: () => true,
      isHidden: () => false,
      options: () => [
        { value: "fullyAgreed" },
        { value: "partiallyAgreed" },
        { value: "notAgreed" },
        { value: "noReaction" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.finalProcessFeedbackClient`,
      translationPath: `${product.productClassName}.finalProcessFeedbackClient.${product.caseCategory}`,
      isDisabled: () => true,
      isHidden: () => false,
      options: () => [{ value: "agreement" }, { value: "lawsuit" }],
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.customerLawsuitCompletedApprovalDate`,
      translationPath: ({ values }: { values: UseForm["values"] }) =>
        `${product.productClassName}.customerLawsuitCompletedApprovalDate.${values.trafficAccident.lawsuitCompletedReason}`,
      isDisabled: () => true,
      isHidden: ({ values }: { values: UseForm["values"] }) =>
        _.includes(
          ["courtDecision", "defaultJudgement", "judgementAppeal"],
          values.trafficAccident.lawsuitCompletedReason
        ) || !values.trafficAccident.lawsuitCompletedReason,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.lawsuitCompletedDate`,
      isDisabled: () => true,
      isHidden: () => false,
    },
  ];

  const inProgressFields: FormElementPropsWithoutProduct[] = [
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.personalTextMessage`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategoryAssessment`,
      isDisabled: ({ product }: { product: AbstractCase }) =>
        (product.stage || 0) > stagesCase.STAGE_WAITING_FOR_FEEDBACK,
      isHidden: () => false,
      options: () => [{ value: "confirm" }, { value: "reject" }],
    },
  ];

  const communicationSendFields: FormElementPropsWithoutProduct[] = [
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfDamage`,
      isDisabled: () => true,
      options: () => [{ value: "vehicle" }, { value: "person" }, { value: "vehicleAndPerson" }, { value: "other" }],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfAccident`,
      isDisabled: () => true,
      options: () => [
        { value: "rearEndCollision" },
        { value: "laneChangeCollision" },
        { value: "parking" },
        { value: "other" },
      ],
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.courseOfEvents`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.legalExpenseInsurance`,
      options: () => trueFalseDefaultOptions("trafficAccident.legalExpenseInsurance"),
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationSent`,
      isHidden: () => false,
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationDeadline`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory === "defendClaim",
      additionalProps: {
        toolbar: DateChangeButtonsToolbar,
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.timeBarredDate`,
      isDisabled: () => true,
      isHidden: () => false,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialReactionReceived`,
      validators: dateFieldDefault,
      isHidden: ({ values }: { values: UseForm["values"] }) =>
        values.trafficAccident.extrajudicialReaction === "noReaction",
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.extrajudicialReaction`,
      translationPath: `${product.productClassName}.extrajudicialReaction.${product.caseCategory}`,
      options: () => [
        { value: "noReaction" },
        { value: "fullyAgreed" },
        { value: "partiallyAgreed" },
        { value: "disagreed" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.extrajudicialRecommendation`,
      isHidden: ({ product }: { product: AbstractCase }) =>
        !_.includes(["defendClaim", "enforceClaim"], product.caseCategory),
      options: () => [
        { value: "agreementDone" },
        { value: "insufficientEvidence" },
        { value: "insufficientChancesOfSuccess" },
        { value: "costBenefitConsiderations" },
        { value: "lawsuit" },
        { value: "individualText" },
      ],
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.extrajudicialRecommendationText`,
      isHidden: ({ product }: { product: AbstractCase }) =>
        !_.includes(["defendClaim", "enforceClaim"], product.caseCategory),
      additionalProps: {
        multiline: true,
        fullWidth: true,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.finalClaimStatusClient`,
      translationPath: `${product.productClassName}.finalClaimStatusClient.${product.caseCategory}`,
      helperText:
        "Achtung: Feld ist über Servicewelt durch Mandantschaft zu befüllen! Wenn dieses Feld von uns befüllt wird, " +
        "kann die Mandantschaft kein Feedback mehr abgeben. Daher nur befüllen, wenn mit Mandantschaft explizit abgesprochen.",
      options: () => [
        { value: "fullyAgreed" },
        { value: "partiallyAgreed" },
        { value: "notAgreed" },
        { value: "noReaction" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.finalProcessFeedbackClient`, // Rückmeldung Mandant
      translationPath: `${product.productClassName}.finalProcessFeedbackClient.${product.caseCategory}`, // Rückmeldung Mandant
      helperText:
        "Achtung: Feld ist über Servicewelt durch Mandantschaft zu befüllen! Wenn dieses Feld von uns befüllt wird, " +
        "kann die Mandantschaft kein Feedback mehr abgeben. Daher nur befüllen, wenn mit Mandantschaft explizit abgesprochen.",
      isHidden: ({ values }: { values: UseForm["values"] }) =>
        values.trafficAccident.finalClaimStatusClient === "fullyAgreed",
      options: () => [{ value: "agreement" }, { value: "lawsuit" }],
    },
  ];

  const reactionReceivedFields: FormElementPropsWithoutProduct[] = [
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfDamage`,
      isDisabled: () => true,
      options: () => [{ value: "vehicle" }, { value: "person" }, { value: "vehicleAndPerson" }, { value: "other" }],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfAccident`,
      isDisabled: () => true,
      options: () => [
        { value: "rearEndCollision" },
        { value: "laneChangeCollision" },
        { value: "parking" },
        { value: "other" },
      ],
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.courseOfEvents`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.legalExpenseInsurance`,
      options: () => trueFalseDefaultOptions("trafficAccident.legalExpenseInsurance"),
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationSent`,
      isHidden: () => false,
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationDeadline`,
      validators: dateFieldDefault,
      isHidden: () => false,
      additionalProps: {
        toolbar: DateChangeButtonsToolbar,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.extrajudicialReaction`,
      translationPath: `${product.productClassName}.extrajudicialReaction.${product.caseCategory}`,
      isDisabled: () => true,
      options: () => [
        { value: "noReaction" },
        { value: "fullyAgreed" },
        { value: "partiallyAgreed" },
        { value: "disagreed" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.extrajudicialRecommendation`,
      isDisabled: () => true,
      isHidden: ({ product }: { product: AbstractCase }) =>
        !_.includes(["defendClaim", "enforceClaim"], product.caseCategory),
      options: () => [
        { value: "agreementDone" },
        { value: "insufficientEvidence" },
        { value: "insufficientChancesOfSuccess" },
        { value: "costBenefitConsiderations" },
        { value: "lawsuit" },
        { value: "individualText" },
      ],
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.extrajudicialRecommendationText`,
      isDisabled: () => true,
      isHidden: ({ product }: { product: AbstractCase }) =>
        !_.includes(["defendClaim", "enforceClaim"], product.caseCategory) || !product.extrajudicialRecommendationText,
      additionalProps: {
        multiline: true,
        fullWidth: true,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.finalClaimStatusClient`,
      translationPath: `${product.productClassName}.finalClaimStatusClient.${product.caseCategory}`,
      helperText:
        "Achtung: Feld ist über Servicewelt durch Mandantschaft zu befüllen! Wenn dieses Feld von uns befüllt wird, " +
        "kann die Mandantschaft kein Feedback mehr abgeben. Daher nur befüllen, wenn mit Mandantschaft explizit abgesprochen.",
      options: () => [
        { value: "fullyAgreed" },
        { value: "partiallyAgreed" },
        { value: "notAgreed" },
        { value: "noReaction" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.finalProcessFeedbackClient`, // Rückmeldung Mandant
      translationPath: `${product.productClassName}.finalProcessFeedbackClient.${product.caseCategory}`, // Rückmeldung Mandant
      helperText:
        "Achtung: Feld ist über Servicewelt durch Mandantschaft zu befüllen! Wenn dieses Feld von uns befüllt wird, " +
        "kann die Mandantschaft kein Feedback mehr abgeben. Daher nur befüllen, wenn mit Mandantschaft explizit abgesprochen.",
      isHidden: ({ values }: { values: UseForm["values"] }) =>
        values.trafficAccident.finalClaimStatusClient === "fullyAgreed",
      options: () => [{ value: "agreement" }, { value: "lawsuit" }],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.subtractExtrajudicialProcessFee`,
      options: () => trueFalseDefaultOptions(`${product.productClassName}.subtractExtrajudicialProcessFee`),
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.courtPostalCode`,
      helperText:
        "Bitte hier die Postleitzahl eintragen, die für den Gerichtsstand ermittelt wurde und NICHT die Postleitzahl des Gerichtes.",
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.caseCategory === "defendClaim" || product.isHigherInstanceCase,
      alert: {
        onChange:
          "Das Ändern der Postleitzahl führt dazu, dass das zuständige Gericht erneut ermittelt wird. Möchten Sie die Postleitzahl tatsächlich ändern?",
      },
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.valueInDisputePreliminary`,
      fieldType: "money",
      numberType: "float",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimSentDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "enforceClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "defendClaim",
    },
  ];

  const prepareLawsuitFields: FormElementPropsWithoutProduct[] = [
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfDamage`,
      isDisabled: () => true,
      options: () => [{ value: "vehicle" }, { value: "person" }, { value: "vehicleAndPerson" }, { value: "other" }],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfAccident`,
      isDisabled: () => true,
      options: () => [
        { value: "rearEndCollision" },
        { value: "laneChangeCollision" },
        { value: "parking" },
        { value: "other" },
      ],
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.courseOfEvents`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.courtPostalCode`,
      helperText:
        "Bitte hier die Postleitzahl eintragen, die für den Gerichtsstand ermittelt wurde und NICHT die Postleitzahl des Gerichtes.",
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.caseCategory === "defendClaim" || product.isHigherInstanceCase,
      alert: {
        onChange:
          "Das Ändern der Postleitzahl führt dazu, dass das zuständige Gericht erneut ermittelt wird. Möchten Sie die Postleitzahl tatsächlich ändern?",
      },
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.valueInDisputePreliminary`,
      fieldType: "money",
      numberType: "float",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimSentDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "enforceClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "defendClaim",
    },
  ];

  const evaluateJudgementFields: FormElementPropsWithoutProduct[] = [
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfDamage`,
      isDisabled: () => true,
      options: () => [{ value: "vehicle" }, { value: "person" }, { value: "vehicleAndPerson" }, { value: "other" }],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfAccident`,
      isDisabled: () => true,
      options: () => [
        { value: "rearEndCollision" },
        { value: "laneChangeCollision" },
        { value: "parking" },
        { value: "other" },
      ],
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.courseOfEvents`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    ...contractStageFormDefinition(product, currentUser)[stagesCase.STAGE_EVALUATE_JUDGEMENT]
  ];
  const lawsuitFields: FormElementPropsWithoutProduct[] = [
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfDamage`,
      isDisabled: () => true,
      options: () => [{ value: "vehicle" }, { value: "person" }, { value: "vehicleAndPerson" }, { value: "other" }],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfAccident`,
      isDisabled: () => true,
      options: () => [
        { value: "rearEndCollision" },
        { value: "laneChangeCollision" },
        { value: "parking" },
        { value: "other" },
      ],
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.courseOfEvents`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimSentDate`,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "enforceClaim",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.paymentOrderDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.statementOfClaimFiledDate || product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.enforcementOrderDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.statementOfClaimFiledDate || product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.courtReference`,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfDefenseFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.caseCategory !== "enforceClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.replicaSentDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.caseCategory !== "enforceClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfDefenseSentDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.replicaFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.caseCategory !== "defendClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.judgementReceivedDate`,
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.lawsuitCompletedReason`,
      options: () => [
        { value: "courtDecision" },
        { value: "settlement" },
        { value: "lawsuitRevocation" },
        { value: "resolution" },
        { value: "defaultJudgement" },
        { value: "judgementAppeal" },
      ],
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.customerLawsuitCompletedApprovalDate`,
      translationPath: ({ values }: { values: UseForm["values"] }) =>
        `${product.productClassName}.customerLawsuitCompletedApprovalDate.${values.trafficAccident.lawsuitCompletedReason}`,
      validators: dateFieldDefault,
      isHidden: ({ values }: { values: UseForm["values"] }) =>
        _.includes(
          ["courtDecision", "defaultJudgement", "judgementAppeal"],
          values.trafficAccident.lawsuitCompletedReason
        ) || !values.trafficAccident.lawsuitCompletedReason,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.lawsuitCompletedDate`,
      isHidden: ({ values }: { values: UseForm["values"] }) => !values.trafficAccident.lawsuitCompletedReason,
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.hasStandardInvoicing`,
      options: () => trueFalseDefaultOptions(`${product.productClassName}.hasStandardInvoicing`),
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.netRvgAmount`,
      isHidden: ({ values }: { values: UseForm["values"] }) => values.trafficAccident.hasStandardInvoicing,
      fieldType: "money",
      numberType: "float",
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.valueInDisputePreliminary`,
      fieldType: "money",
      numberType: "float",
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.valueInDispute`,
      fieldType: "money",
      numberType: "float",
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.costBearingByOpponentRelative`,
      validators: percentageFieldDefault,
      isDisabled: ({ product }: { product: AbstractCase }) =>
        !_.includes(
          [stagesCase.STAGE_LAWSUIT_COMPLETED, stagesCase.STAGE_TRIAL, stagesCase.STAGE_LAWSUIT],
          product.stage
        ),
      fieldType: "percentage",
      numberField: "float",
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.principalClaim`,
      isHidden: ({ values }: { values: UseForm["values"] }) =>
        values.trafficAccident.caseCategory !== "enforceClaim" ||
        values.trafficAccident.lawsuitCompletedReason === "lawsuitRevocation" ||
        values.trafficAccident.costBearingByOpponentRelative === "0",
      numberType: "float",
      fieldType: "money",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.principalClaimDefaultDate`,
      isHidden: ({ values }: { values: UseForm["values"] }) =>
        values.trafficAccident.caseCategory !== "enforceClaim" ||
        values.trafficAccident.lawsuitCompletedReason === "lawsuitRevocation" ||
        values.trafficAccident.costBearingByOpponentRelative === "0",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.accessoryClaim`,
      isHidden: ({ values }: { values: UseForm["values"] }) =>
        values.trafficAccident.caseCategory !== "enforceClaim" ||
        values.trafficAccident.lawsuitCompletedReason === "lawsuitRevocation" ||
        values.trafficAccident.costBearingByOpponentRelative === "0",
      fieldType: "money",
      numberType: "float",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.accessoryClaimDefaultDate`,
      isHidden: ({ values }: { values: UseForm["values"] }) =>
        values.trafficAccident.caseCategory !== "enforceClaim" ||
        values.trafficAccident.lawsuitCompletedReason === "lawsuitRevocation" ||
        values.trafficAccident.costBearingByOpponentRelative === "0",
      validators: dateFieldDefault,
    },
  ];

  const lawsuitCompletedFields: FormElementPropsWithoutProduct[] = [
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfDamage`,
      isDisabled: () => true,
      options: () => [{ value: "vehicle" }, { value: "person" }, { value: "vehicleAndPerson" }, { value: "other" }],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.typeOfAccident`,
      isDisabled: () => true,
      options: () => [
        { value: "rearEndCollision" },
        { value: "laneChangeCollision" },
        { value: "parking" },
        { value: "other" },
      ],
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.courseOfEvents`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.courtReference`,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.hasStandardInvoicing`,
      options: () => trueFalseDefaultOptions(`${product.productClassName}.hasStandardInvoicing`),
      isDisabled: () => true,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.subtractExtrajudicialProcessFee`,
      options: () => trueFalseDefaultOptions(`${product.productClassName}.subtractExtrajudicialProcessFee`),
      isDisabled: () => true,
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.netRvgAmount`,
      isDisabled: () => true,
      isHidden: ({ product }: { product: AbstractCase }) => product.hasStandardInvoicing,

      fieldType: "money",
      numberType: "float",
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.valueInDisputePreliminary`,
      fieldType: "money",
      numberType: "float",
      isDisabled: () => true,
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.valueInDispute`,
      fieldType: "money",
      numberType: "float",
      isDisabled: () => true,
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.costBearingByOpponentRelative`,
      validators: percentageFieldDefault,
      isDisabled: () => true,
      isHidden: ({ product }: { product: AbstractCase }) => product.lawsuitCompletedReason === "lawsuitRevocation",
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.costBearingByOpponentFinalAbsolute`,
      helperText:
        "Bitte hier die vom Gegner auf das gerichtliche Verfahren gezahlten Kosten inkl. MwSt. eintragen. " +
        "Außergerichtliche Gebühren sind nicht zu berücksichtigen.",
      validators: [...invoiceNetValueNotOverpaid(`${product.productClassName}.netRvgAmount`)],
      isDisabled: ({ product }: { product: AbstractCase }) => !product.lawsuitCompletedDate,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.lawsuitCompletedReason === "lawsuitRevocation" ||
        product.costBearingByOpponentRelative === 0.0 ||
        product.costBearingByOpponentRelative === 100.0,
      numberType: "float",
      fieldType: "money",
    },
    {
      type: "ValidatorNumberField",
      path: `${product.productClassName}.courtCostsFinal`,
      isDisabled: ({ product }: { product: AbstractCase }) => !product.lawsuitCompletedDate,
      isHidden: ({ product }: { product: AbstractCase }) =>
        product.lawsuitCompletedReason === "lawsuitRevocation" ||
        product.costBearingByOpponentRelative === 0.0 ||
        product.costBearingByOpponentRelative === 100.0,
      numberType: "float",
      fieldType: "money",
    },
  ];

  return {
    [stagesCase.STAGE_CREATED]: createdFields,
    [stagesCase.STAGE_DATA_COMPLETED]: dataCompletedFields,
    [stagesCase.STAGE_WAITING_FOR_FEEDBACK]: dataCompletedFields,
    [stagesCase.STAGE_DATA_COMPLETED_LAWFIRM]: dataLawFirmCompletedFields,
    [stagesCase.STAGE_CHECKED]: checkedFields,
    [stagesCase.STAGE_IN_PROGRESS]: inProgressFields,
    [stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT]: communicationSendFields,
    [stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED]: reactionReceivedFields,
    [stagesCase.STAGE_ACTION_REQUIRED]: reactionReceivedFields,
    [stagesCase.STAGE_PREPARE_LAWSUIT]: prepareLawsuitFields,
    [stagesCase.STAGE_LAWSUIT]: lawsuitFields,
    [stagesCase.STAGE_TRIAL]: lawsuitFields,
    // TODO: move STAGE_EVALUATE_JUDGEMENT stage definition to litigationStageDefinition?
    [stagesCase.STAGE_EVALUATE_JUDGEMENT]: evaluateJudgementFields,
    [stagesCase.STAGE_LAWSUIT_COMPLETED]: lawsuitCompletedFields,
    [stagesCase.STAGE_COMPLETED]: completedFields,
  };
};
