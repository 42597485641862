import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import useStyles from "./externalInvoiceStyle";
import ButtonLoading from "../Button/ButtonLoading";
import { useSnackbar } from "notistack";
import ApiClient from "../../services/ApiClient";

import { Switch } from "@mui/material";
import { DropzoneArea } from "mui-file-dropzone";
import ContentBox from "../ContentBox/ContentBox";

const ExternalInvoice = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [createPaymentRun, setCreatePaymentRun] = useState(true);

  const saveFile = async () => {
    if (!files[0]) {
      return;
    }

    setIsLoading(true);

    let formData = new FormData();
    formData.append("file", files[0]);
    formData.append("sendEmail", sendEmail.toString());
    formData.append("createPaymentRun", createPaymentRun.toString());
    let headers = new Headers();
    headers.set("Content-Type", "multipart/form-data");

    try {
      await ApiClient.post("service/external_invoice", {
        headers: headers,
        body: formData,
        sendEmail: sendEmail,
        createPaymentRun: createPaymentRun,
      });

      enqueueSnackbar("Rechnungen und Zahllauf wurden erfolgreich erstellt", {
        variant: "custom",
        buttonText: "zum Rechnungsordner",
        buttonLink: "https://drive.google.com/drive/folders/1TtVXM83YB4wAf6Kz_86iGvFP8UzJkTT8",
        linkIsExternal: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (e: any) {
      enqueueSnackbar(e.errors.csv, {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        variant: "custom",
        isNonInteractive: true,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <ContentBox>
        <Grid container>
          <Grid item xs={12}>
            <DropzoneArea
              onChange={(files) => {
                setFiles(files);
              }}
              acceptedFiles={["text/csv"]}
              filesLimit={1}
              dropzoneText={"CSV Datei auswählen"}
              showFileNames
              showAlerts={false}
              maxFileSize={300000000}
              useChipsForPreview
              fileObjects={undefined}
              onDelete={undefined}
            />
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <ButtonLoading onClick={saveFile} variant={"contained"} isLoading={isLoading}>
              Rechnungen erstellen
            </ButtonLoading>
          </Grid>
        </Grid>
      </ContentBox>
      <ContentBox headline="Weitere Einstellungen">
        <Grid container alignItems={"center"} justifyContent={"flex-start"} className={classes.switches}>
          <Grid item xs={11} md={5}>
            Rechnungen per Email versenden
          </Grid>
          <Grid item xs={1}>
            <Switch color={"primary"} checked={sendEmail} onChange={() => setSendEmail(!sendEmail)} />
          </Grid>
          <Grid item xs={11} md={5}>
            Zugehörigen Zahllauf erstellen
          </Grid>
          <Grid item xs={1}>
            <Switch
              color={"primary"}
              checked={createPaymentRun}
              onChange={() => setCreatePaymentRun(!createPaymentRun)}
            />
          </Grid>
        </Grid>
      </ContentBox>
    </>
  );
};

export default ExternalInvoice;
