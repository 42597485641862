import React, { useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import useForm from "../../../hooks/useForm";
import ValidatorForm from "../../Validator/ValidatorForm";
import { formValue, prepareFormCustomerDates } from "../../../services/formServiceFunctions";
import { difference, persistCustomer, persistPerson } from "../../../services/Persist/Persister";
import { getProductData } from "../../../services/Product/ProductService";
import {
  duplicateMailDefault,
  emailDefault,
  isPhoneNumberWithoutSpecialCharactersDefault,
  requiredFieldDefault,
  textFieldDefault,
} from "../../../services/validationRules";
import { translate } from "../../../services/Translations/translatorService";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Call } from "@mui/icons-material";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import FormElementAlert from "../../Case/CaseForm/FormElementAlert";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import _ from "lodash";
import SubmitButton from "../../Button/SubmitButton";
import ContentBox from "../../ContentBox/ContentBox";
import { AbstractCase } from "../../../types/AbstractCase";
import moment from "moment";

type ContactProps = {
  product: AbstractCase;
  refreshCase: Function;
};
export default function Contact({ product, refreshCase }: ContactProps) {
  const [isShowAlert, setIsShowAlert] = useState(false);
  const customer = product.customer!;
  const client = product.client!;

  const initialValues = {
    customer: prepareFormCustomerDates(customer),
    client: client,
  };

  const onSubmit = async () => {
    const updatedCustomer = await persistCustomer(
      customer.id,
      difference(values.customer, prepareFormCustomerDates(customer))
    );
    await persistPerson(client.id, difference(values.client, client));
    registerValidators("customer.email", [
      ...emailDefault,
      ...requiredFieldDefault,
      ...duplicateMailDefault(updatedCustomer.email),
    ]);
    await refreshCase();
  };

  const identifier = product;
  const { values, errors, handleChange, handleSubmit, handleBlur, registerValidators, touchedValues, isLoading } =
    useForm({
      initialValues,
      onSubmit,
      identifier,
    });

  const productStages = getProductData(product.productClassName, "stageConstants");
  const isCaseChecked = product.stage! >= productStages.STAGE_CHECKED;

  return (
    <ContentBox headline={"Kontakt"}>
      {isCaseChecked && (
        <Typography sx={{ color: "red", textAlign: "center", marginBottom: "2rem" }}>
          <strong>
            Fall zu weit fortgeschritten, um Daten zu ändern. Änderungswünsche des Kunden bitte als Gesprächsnotiz
            hinterlegen.
          </strong>
        </Typography>
      )}
      {_.includes(values.customer.email, "advocard.error+") && (
        <Typography sx={{ color: "#ff8800", textAlign: "center", marginBottom: "2rem" }}>
          <strong>
            Derzeit keine E-Mail-Adresse vorhanden. Nur Erstberatung möglich oder E-Mail-Adresse einholen.
          </strong>
        </Typography>
      )}
      <ValidatorForm onSubmit={handleSubmit}>
        <Grid container alignItems={"center"} spacing={3}>
          <Grid item xs={4}>
            <ValidatorTextField
              onChange={handleChange}
              onBlur={() => setIsShowAlert(true)}
              name={"customer.email"}
              value={formValue(values, "customer.email")}
              label={translate("customer.email.label")}
              registerValidators={registerValidators}
              validators={[...emailDefault, ...requiredFieldDefault, ...duplicateMailDefault(customer.email)]}
              error={!!errors["customer.email"]}
              helperText={errors["customer.email"]}
              disabled={isCaseChecked}
              isHighlighted={undefined}
              initialDependentValidationFields={undefined}
            />
            <FormElementAlert
              alert={{
                onChange:
                  "Das Ändern der E-Mail-Adresse führt dazu, dass der Kunde sich nicht mehr mit seiner alten Adresse in der Servicewelt anmelden kann, sondern nur noch mit der neuen Adresse.",
              }}
              initialValue={formValue(initialValues, "customer.email")}
              currentValue={formValue(values, "customer.email")}
              path={"customer.email"}
              setIsShowAlert={setIsShowAlert}
              isShowAlert={isShowAlert}
              handleChange={handleChange}
              label={translate("customer.email.label")}
            />
          </Grid>
          <Grid item xs={4}>
            <ValidatorTextField
              onChange={handleChange}
              onBlur={handleBlur}
              name={"customer.telephone"}
              value={formValue(values, "customer.telephone")}
              label={translate("customer.telephone.label")}
              registerValidators={registerValidators}
              validators={textFieldDefault}
              error={!!errors["customer.telephone"]}
              helperText={errors["customer.telephone"]}
              disabled={isCaseChecked}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color={"secondary"}
                      href={"tel:" + formValue(values, "customer.telephone")}
                      disabled={!formValue(values, "customer.telephone")}
                      size="large"
                    >
                      <Call />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              isHighlighted={undefined}
              initialDependentValidationFields={undefined}
            />
          </Grid>
          <Grid item xs={4}>
            <ValidatorTextField
              onChange={handleChange}
              onBlur={handleBlur}
              name={"customer.alternativeTelephone"}
              value={formValue(values, "customer.alternativeTelephone")}
              label={translate("customer.alternativeTelephone.label")}
              registerValidators={registerValidators}
              validators={[...textFieldDefault, ...isPhoneNumberWithoutSpecialCharactersDefault]}
              error={!!errors["customer.alternativeTelephone"]}
              helperText={errors["customer.alternativeTelephone"]}
              disabled={isCaseChecked}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color={"secondary"}
                      href={"tel:" + formValue(values, "customer.alternativeTelephone")}
                      disabled={!formValue(values, "customer.alternativeTelephone")}
                      size="large"
                    >
                      <Call />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              isHighlighted={undefined}
              initialDependentValidationFields={undefined}
            />
          </Grid>
          <Grid item xs={4}>
            <ValidatorSelect
              label={translate(`${product.productClassName}.client.gender.label`)}
              name={`client.gender`}
              value={formValue(values, `client.gender`)}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isCaseChecked}
            >
              <MenuItem value={`male`}>{translate(`${product.productClassName}.client.gender.values.male`)}</MenuItem>
              <MenuItem value={`female`}>
                {translate(`${product.productClassName}.client.gender.values.female`)}
              </MenuItem>
            </ValidatorSelect>
          </Grid>
          <Grid item xs={4}>
            <ValidatorTextField
              onChange={handleChange}
              onBlur={handleBlur}
              name={`client.givenName`}
              value={formValue(values, `client.givenName`)}
              label={translate(`${product.productClassName}.client.givenName.label`)}
              registerValidators={registerValidators}
              validators={[...textFieldDefault, ...requiredFieldDefault]}
              error={!!errors[`client.givenName`]}
              helperText={errors[`client.givenName`]}
              disabled={isCaseChecked}
              isHighlighted={undefined}
              initialDependentValidationFields={undefined}
            />
          </Grid>
          <Grid item xs={4}>
            <ValidatorTextField
              onChange={handleChange}
              onBlur={handleBlur}
              name={`client.familyName`}
              value={formValue(values, `client.familyName`)}
              label={translate(`${product.productClassName}.client.familyName.label`)}
              registerValidators={registerValidators}
              validators={[...textFieldDefault, ...requiredFieldDefault]}
              error={!!errors[`client.familyName`]}
              helperText={errors[`client.familyName`]}
              disabled={isCaseChecked}
              isHighlighted={undefined}
              initialDependentValidationFields={undefined}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={translate("customer.created.label")}
              value={moment(product.created).format("DD.MM.YYYY HH:mm")}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton
              error={_.keys(errors).length > 0}
              isLoading={isLoading}
              disabled={_.keys(touchedValues).length === 0}
              variant={"contained"}
              type={"submit"}
            >
              Speichern
            </SubmitButton>
          </Grid>
        </Grid>
      </ValidatorForm>
    </ContentBox>
  );
}
