import { AbstractCase } from "../../../types/AbstractCase";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useQuery } from "@tanstack/react-query";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { Divider, List, Typography } from "@mui/material";
import FormDraft from "../../../types/FormDraft";
import React from "react";
import PdfGeneratorDraftListItem from "./PdfGeneratorDraftListItem";
import { UseFormReturn } from "react-hook-form";
import { CasePdfGeneratorFormValues } from "../../../types/CasePdfGenerator";

type CaseFormDraftDisplayProps = {
  product: AbstractCase;
  formContext: UseFormReturn<CasePdfGeneratorFormValues>;
};

const PdfGeneratorDraftList = ({ product, formContext }: CaseFormDraftDisplayProps) => {
  const currentUser = useCurrentUser();
  const formDraftFilter = { backofficeCase: product.backofficeCase.id };

  const { data: formDraftResponse } = useQuery(queryKeys.collection("form_drafts", formDraftFilter), () =>
    fetchCollection("form_drafts", formDraftFilter)
  );

  if (!formDraftResponse || formDraftResponse["hydra:totalItems"] === 0 || currentUser.isExternal) {
    return null;
  }

  return (
    <>
      <Typography variant={"h5"} sx={{ padding: "16px" }}>
        <strong>Entwurf verwenden</strong>
      </Typography>
      <Divider />
      <List sx={{ marginBottom: "2rem" }}>
        {(formDraftResponse?.["hydra:member"] || []).map((formDraft: FormDraft) => (
            <PdfGeneratorDraftListItem formDraft={formDraft} formContext={formContext} key={formDraft.id}/>
        ))}
      </List>
    </>
  );
};

export default PdfGeneratorDraftList;
