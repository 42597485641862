import React, { useState } from "react";
import ButtonLoading from "../Button/ButtonLoading";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ApiClient from "../../services/ApiClient";
import { MediaObject } from "../../types/MediaObject";
import { Grid, Typography } from "@mui/material";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { useSnackbar } from "notistack";
import MediaObjectLabelAndActionForm from "../MediaObject/MediaObjectLabelAndActionForm";
import ActionIdentifierProvider from "../../provider/ActionIdentifierProvider";
import DocumentClassLabelAiProvider from "../../provider/DocumentClassLabelAiProvider";
import moment from "moment/moment";
import { FormContainer, SubmitHandler, useForm } from "react-hook-form-mui";


type ChangeMediaObjectFolderModalProps = {
  setChangeIdentifierOpen: (value: boolean) => void;
  changeIdentifierOpen: boolean;
  mediaObject: MediaObject;
  updateMediaObjects: Function;
};

export default function ChangeMediaObjectIdentifierModal({
  setChangeIdentifierOpen,
  changeIdentifierOpen,
  mediaObject,
  updateMediaObjects,
}: ChangeMediaObjectFolderModalProps) {
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    description: "Dokument vom " + moment().format("DD.MM.YYYY"),
  } as Record<string, any>;

  const currentUser = useCurrentUser();

  const formContext = useForm({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  const handleSaveNewIdentifier: SubmitHandler<typeof initialValues> = async (values) => {
    setIsSaving(true);
    try {
      const payload = {
        actionIdentifier: values.actionIdentifier!.value,
        documentClassLabelAi: values.documentClassLabelAi!.value,
        backofficeUserId: currentUser.id,
      } as Record<string, any>;

      if (showExtraFields) {
        payload["showInGallery"] = values.showInGallery === "true";
        payload["description"] = values.description;
        payload["electronicFileFolderPath"] = values.electronicFileFolderPath;
        payload["assignActivityToUserGroup"] = values.assignActivityToUserGroup;
        payload["sendEmailToCustomer"] = values.sendEmailToCustomer;
      }

      await ApiClient.put(`lb/services/media_object_change_label_and_action_identifier/${mediaObject.id}`, {
        body: JSON.stringify(payload),
      });

      await updateMediaObjects();

      enqueueSnackbar("Art des Dokuments wurde erfolgreich geändert", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (e) {
      enqueueSnackbar("Art des Dokuments konnte nicht geändert werden", {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        variant: "custom",
        isNonInteractive: true,
      });
    } finally {
      setIsSaving(false);
      setChangeIdentifierOpen(false);
    }
  };

  const showExtraFields = formContext.watch("actionIdentifier")?.value === "NO_ACTION";

  return (
    <LegalbirdIoModal
      handleClose={() => setChangeIdentifierOpen(false)}
      open={changeIdentifierOpen}
      title={"Art des Dokuments ändern"}
      submitButton={
        <FormContainer formContext={formContext} onSuccess={handleSaveNewIdentifier}>
          <ButtonLoading variant={"contained"} type={"submit"} isLoading={isSaving}>
            Auswahl speichern
          </ButtonLoading>
        </FormContainer>
      }
    >
      <FormContainer formContext={formContext} onSuccess={handleSaveNewIdentifier}>
        <Typography paddingBottom={2}>
          Bitte wählen Sie in den untenstehenden Dropdowns aus, wie das Dokument gespeichert werden soll.{" "}
          <strong>Achtung:</strong> Die Änderung wird alle Aktionen des Aktionskenners, wie beispielsweise Aktivitäten
          und Nachrichtenversand an den Mandanten, ausführen!
        </Typography>
        <Grid container spacing={2}>
          <DocumentClassLabelAiProvider>
            <ActionIdentifierProvider>
              <MediaObjectLabelAndActionForm
                productClassName={mediaObject.product}
                mediaObject={mediaObject}
                showExtraFields={showExtraFields}
              />
            </ActionIdentifierProvider>
          </DocumentClassLabelAiProvider>
        </Grid>
      </FormContainer>
    </LegalbirdIoModal>
  );
}
