import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import _ from "lodash";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import paymentRunStyle from "./paymentRunStyle";
import ButtonLoading from "../Button/ButtonLoading";
import { useSnackbar } from "notistack";
import ApiClient from "../../services/ApiClient";

import PageContentContainer from "../Container/PageContentContainer";
import PageHeadline from "../PageHeadline/PageHeadline";
import { DropzoneArea } from "mui-file-dropzone";
import ContentBox from "../ContentBox/ContentBox";
import { Box } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import { apiDelete, apiPost, apiPut } from "../../services/Api/apiCall";
import PaymentRun from "../../types/PaymentRun";

const PaymentRunList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAdvoAssist, setIsLoadingAdvoAssist] = useState(false);
  const [isLoadingPaidTasks, setIsLoadingPaidTasks] = useState(false);

  const queryClient = useQueryClient();
  const { data: paymentRunList } = useQuery(queryKeys.collection("payment_runs"), () =>
    fetchCollection("payment_runs")
  );

  const saveFile = async () => {
    if (!files[0]) {
      return;
    }

    setIsLoading(true);

    let formData = new FormData();
    formData.append("file", files[0]);
    let headers = new Headers();
    headers.set("Content-Type", "multipart/form-data");

    try {
      await ApiClient.post("service/csv_payment_run", {
        headers: headers,
        body: formData,
      });
      enqueueSnackbar("Zahllauf wurde erfolgreich erstellt", {
        variant: "custom",
        buttonText: "zum Zahllauf",
        buttonLink: "https://drive.google.com/drive/folders/1TtVXM83YB4wAf6Kz_86iGvFP8UzJkTT8",
        linkIsExternal: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (e: any) {
      enqueueSnackbar(e.errors.csv, {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }

    queryClient.invalidateQueries(queryKeys.collections("payment_runs")).then(() => setIsLoading(false));
  };
  const addPaymentRun = async () => {
    setIsLoadingAdvoAssist(true);
    try {
      await apiPost("payment_runs", { type: "AdvoAssist" });
      enqueueSnackbar("Zahllauf wurde erfolgreich erstellt", {
        variant: "custom",
        buttonText: "zum Zahllauf",
        buttonLink: "https://drive.google.com/drive/folders/1TtVXM83YB4wAf6Kz_86iGvFP8UzJkTT8",
        linkIsExternal: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (e) {
      enqueueSnackbar("Fehler beim Erstellen des Zahllaufs", {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
    queryClient.invalidateQueries(queryKeys.collections("payment_runs")).then(() => setIsLoadingAdvoAssist(false));
  };

  const createPaidTasksInvoices = async () => {
    setIsLoadingPaidTasks(true);
    try {
      await ApiClient.post("services/controlling/process_paid_task_controlling");
      enqueueSnackbar("Verarbeitung wurde erfolgreich gestartet", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (e: any) {
      enqueueSnackbar(e.errors.message, {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
    setIsLoadingPaidTasks(false);
  };

  const buttonActions = async (paymentRun: PaymentRun) => {
    if (typeof paymentRun.id !== "number") {
      return;
    }
    if (paymentRun.status === "success") {
      await apiPut("payment_runs", paymentRun.id, { paid: moment() });
    }
    if (paymentRun.status === "initialized" || !paymentRun.status) {
      await apiDelete("payment_runs", paymentRun.id);
    }
    await queryClient.invalidateQueries(queryKeys.collections("payment_runs"));
  };

  return (
    <PageContentContainer size={"small"}>
      <PageHeadline main="Zahlläufe" />
      <ContentBox headline="Aktuelle Zahlläufe">
        {!!paymentRunList && !isLoading ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Typ</TableCell>
                <TableCell>Erstellt</TableCell>
                <TableCell>Status</TableCell>
                <TableCell># Zahlungen</TableCell>
                <TableCell>Gesamtbetrag</TableCell>
                <TableCell>Ausgezahlt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(_.slice(paymentRunList["hydra:member"], 0, 5), (paymentRun: PaymentRun) => {
                return (
                  <TableRow key={paymentRun.id}>
                    <TableCell>{paymentRun.id}</TableCell>
                    <TableCell>{paymentRun.type}</TableCell>
                    <TableCell>{moment(paymentRun.created).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
                    <TableCell>{paymentRun.status}</TableCell>
                    <TableCell>{paymentRun.numberOfPayments}</TableCell>

                    <TableCell>
                      {!!paymentRun.sumOfPayments &&
                        (paymentRun.sumOfPayments / 100).toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        })}
                    </TableCell>

                    <TableCell>
                      {paymentRun.paid ? (
                        moment(paymentRun.paid).format("DD.MM.YYYY HH:mm:ss")
                      ) : (
                        <Button onClick={() => buttonActions(paymentRun)}>
                          {paymentRun.status === "success" ? "Als bezahlt markieren" : "Zahllauf löschen"}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Grid container justifyContent={"center"} alignContent={"center"} style={{ minHeight: 200 }}>
            <CircularProgress />
          </Grid>
        )}
      </ContentBox>

      <ContentBox headline="AdvoAssist">
        <Box sx={paymentRunStyle.buttonContainer}>
          <ButtonLoading onClick={addPaymentRun} variant={"contained"} isLoading={isLoadingAdvoAssist}>
            Neuen AdvoAssist Zahllauf starten
          </ButtonLoading>
        </Box>
      </ContentBox>

      <ContentBox headline="Abgenommene Aufgaben abrechnen">
        <Box sx={paymentRunStyle.buttonContainer}>
          <ButtonLoading onClick={createPaidTasksInvoices} variant={"contained"} isLoading={isLoadingPaidTasks}>
            Abgenommene Ausschreibungen abrechnen
          </ButtonLoading>
        </Box>
      </ContentBox>

      <ContentBox headline="CSV Zahllauf erstellen">
        <Box sx={paymentRunStyle.buttonContainer}>
          <Grid container>
            <Grid item xs={12}>
              <DropzoneArea
                onChange={(files) => {
                  setFiles(files);
                }}
                acceptedFiles={["text/csv"]}
                filesLimit={1}
                dropzoneText={"CSV Datei auswählen"}
                showFileNames
                showAlerts={false}
                maxFileSize={300000000}
                useChipsForPreview
                fileObjects={undefined}
                onDelete={undefined}
              />
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "1rem" }}>
              <ButtonLoading onClick={saveFile} variant={"contained"} isLoading={isLoading}>
                Zahllauf erstellen
              </ButtonLoading>
            </Grid>
          </Grid>
        </Box>
      </ContentBox>
    </PageContentContainer>
  );
};

export default PaymentRunList;
