/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React, { useCallback, useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import validatorStyle from "./validatorStyle";
import { ValidatorType } from "../../types/ValidatorType";
import { Autocomplete, AutocompleteChangeReason, AutocompleteProps, TextField } from "@mui/material";
import legalbirdAutoCompleteStyle from "../AutoComplete/legalbirdAutoCompleteStyle";
import algoliasearch from "algoliasearch/lite";
import { ALGOLIA_APPLICATION_ID, ALGOLIA_ORGANIZATION_INDEX, ALGOLIA_SEARCH_API_KEY } from "../../config/_entrypoint";
import { Hit } from "@algolia/client-search";
import { Organization } from "../../types/Organization";
import _find from "lodash/find";
import { FormChangeEvent, FormChangeEventHandler } from "../../types/UseForm";
import { debounce } from "lodash";

type OrganizationHit = Hit<Organization>;

type ValidatorOrganizationAutocompleteProps = {
  label: string;
  registerValidators?: Function;
  validators?: Array<ValidatorType>;
  dependentValidationFields?: Array<string>;
  helperText?: string;
  isHighlighted?: boolean;
  error?: boolean;
  name: string;
  emptyText?: string;
  noOptionsText?: string;
  organizationType?: string | null;
  onChange: FormChangeEventHandler<HTMLInputElement>;
  value?: string;
} & Omit<AutocompleteProps<OrganizationHit, false, false, false>, "onChange" | "renderInput" | "options" | "value">;

const ValidatorOrganizationAutocomplete = ({
  label,
  registerValidators = () => {},
  validators = [],
  dependentValidationFields = [],
  helperText = "",
  isHighlighted = false,
  error = false,
  disabled,
  value,
  name,
  emptyText = "Bitte fangen Sie an zu tippen um zu suchen",
  noOptionsText = "Nicht gefunden",
  onChange,
  organizationType = null,
  ...rest
}: ValidatorOrganizationAutocompleteProps) => {
  const [options, setOptions] = useState<OrganizationHit[]>([]);
  const client = algoliasearch(ALGOLIA_APPLICATION_ID, ALGOLIA_SEARCH_API_KEY);
  const organizationIndex = client.initIndex(ALGOLIA_ORGANIZATION_INDEX);
  const organizationId = value && value.split("/").pop();
  const objectId = organizationId ? `organization::${organizationId}` : null;

  const setValueFromOption = (option: OrganizationHit) => {
    const selectedOrganizationId = option.objectID.split("::").pop();

    onChange({
      target: {
        type: "text",
        name: name,
        value: `/organizations/${selectedOrganizationId}`,
      },
    } as FormChangeEvent);
  };

  const fetchOptions = useCallback(
    debounce((searchText: string) => {
      if (!searchText.trim()) {
        setOptions([]);
        return;
      }

      const filters = organizationType ? { filters: "type:" + organizationType } : {};

      organizationIndex
        .search<OrganizationHit>(searchText, filters)
        .then(({ hits }) => {
          const sortedHits = hits.sort((a, b) => (a.name || "").localeCompare(b.name || ""));
          setOptions(sortedHits);
        })
        .catch((err) => console.error(err));
    }, 300),
    []
  );

  useEffect(() => {
    if (organizationId) {
      organizationIndex
        .search<OrganizationHit>("", {
          filters: `objectID:"${objectId}"`,
        })
        .then(({ hits }) => {
          if (hits.length > 0) {
            setOptions([hits[0]]);
            setValueFromOption(hits[0]);
          }
        })
        .catch((err) => console.error("Fehler beim Laden der initialen Option:", err));
    }
  }, []);

  useEffect(() => {
    if (registerValidators && validators && !disabled) {
      registerValidators(name, validators, dependentValidationFields);
    }
    return () => {
      registerValidators && registerValidators(name, []);
    };
  }, [registerValidators, dependentValidationFields, name, disabled]);

  const handleAutoCompleteValueChange = (
    e: React.SyntheticEvent,
    value: OrganizationHit | null,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === "clear") {
      onChange({
        target: {
          type: "text",
          name: name,
          value: null,
        },
      } as FormChangeEvent);
    }

    if (!value) {
      return;
    }

    setValueFromOption(value);
  };

  const handleInputChange = (e: React.SyntheticEvent, newInputValue: string) => {
    fetchOptions(newInputValue);
  };

  return (
    <FormControl
      fullWidth={true}
      margin={"none"}
      sx={isHighlighted && value === null ? validatorStyle.mandatory : undefined}
      error={error}
      disabled={disabled}
    >
      <Autocomplete
        {...rest}
        renderInput={(params) => (
          <TextField {...params} label={label} sx={{ ...legalbirdAutoCompleteStyle.textField }} />
        )}
        options={options}
        value={_find(options, (option) => option.objectID === objectId) || null}
        onChange={handleAutoCompleteValueChange}
        onInputChange={handleInputChange}
        getOptionLabel={(option) => option.name || ""}
        noOptionsText={objectId ? noOptionsText : emptyText}
        isOptionEqualToValue={(option, value) => option.objectID === value.objectID}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ValidatorOrganizationAutocomplete;
