import React from "react";
import MenuItem from "@mui/material/MenuItem";
import ValidatorSelect from "../../../Validator/ValidatorSelect";
import { translate } from "../../../../services/Translations/translatorService";
import { activityOverviewHeaderStyles } from "../activityOverviewHeaderStyles";

type ProductFilterProps = {
  productFilter: string;
  setProductFilter: (value: string) => void;
};

const ProductFilter = ({ productFilter, setProductFilter }: ProductFilterProps) => {
  return (
    <ValidatorSelect
      label="Rechtsprodukt"
      onChange={({ target }) => {
        setProductFilter(target.value);
      }}
      value={productFilter}
      sx={activityOverviewHeaderStyles.filterSelect}
      fullWidth={true}
    >
      <MenuItem value={"all"}>Alle Rechtsprodukte</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Divorce\\Divorce"}>{translate("divorce.label")}</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Settlement\\Settlement"}>{translate("settlement.label")}</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Traffic\\Traffic"}>{translate("traffic.label")}</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\TrafficLaw\\TrafficAccident\\TrafficAccident"}>{translate("trafficAccident.label")}</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Document\\Alimony"}>{translate("alimony.label")}</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Document\\Death"}>{translate("death.label")}</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\BasicCase\\FamilyLawCase"}>{translate("familyLawCase.label")}</MenuItem>
      <MenuItem value={"contractLaw"}>Beratung / Vertretung Vertragsrecht</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\ContractLaw\\DataLeakContract\\DataLeakContract"}>
        {translate("dataLeakContract.label")}
      </MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\TenancyLaw\\RentalContract\\RentalContract"}>
        {translate("rentalContract.label")}
      </MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Document\\PatientDecree"}>{translate("patientDecree.label")}</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Document\\PowerOfAttorney"}>
        {translate("powerOfAttorney.label")}
      </MenuItem>
    </ValidatorSelect>
  );
};

export default ProductFilter;
