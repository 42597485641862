import React, { ChangeEvent, useState } from "react";
import ValidatorSelect from "../Validator/ValidatorSelect";
import { Checkbox, Grid, ListItemText, MenuItem, SelectChangeEvent, TextField } from "@mui/material";
import { translate } from "../../services/Translations/translatorService";
import { UseForm } from "../../types/UseForm";
import _ from "lodash";
import ValidatorNumberField from "../Validator/ValidatorNumberField";

type SelectAndExtendedDataComboFieldProps = {
  path: string;
  config: {
    values: string[];
    showOther?: boolean;
    extensionFieldConfig: {
      type: string;
      labelPrefix: string;
      md: number;
    };

  };
  formValue: Record<
    string,
    {
      extensionValue: string;
      dynamicLabel?: string;
    }
  >;
  onChange: UseForm["handleChange"];
  disabled: boolean;
};

export default function SelectAndExtendedDataComboField({
  config,
  path,
  formValue,
  onChange,
  disabled,
}: SelectAndExtendedDataComboFieldProps) {
  const [correspondingValues, setCorrespondingValues] = useState(formValue);

  const constructFormValue = (
    selectValue: string[],
    correspondingValues: SelectAndExtendedDataComboFieldProps["formValue"] = {}
  ) => {
    let newValue = { ...correspondingValues };

    for (const key in newValue) {
      if (!selectValue.includes(key)) {
        delete newValue[key];
      }
    }

    selectValue.forEach((key: string) => {
      if (!(key in newValue)) {
        let newEntry: SelectAndExtendedDataComboFieldProps["formValue"][string] = { extensionValue: "" };
        if (key === "other") {
          newEntry.dynamicLabel = "";
        }
        newValue[key] = newEntry;
      }
    });

    //convert to string so that there's no object in the form values since then the difference check (Persister.js -> difference) filters out the unchanged entries
    return JSON.stringify(newValue);
  };

  const selectedValues = Object.keys(formValue);

  const handleSelectChange = (e: SelectChangeEvent<string[]>) => {
    onChange({
      target: {
        name: path,
        value: constructFormValue(e.target.value as string[], correspondingValues),
      },
    });
  };

  const handleDynamicLabelChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newCorrespondingValues = {
      ...correspondingValues,
      other: { extensionValue: correspondingValues.other.extensionValue, dynamicLabel: e.target.value },
    };
    setCorrespondingValues(newCorrespondingValues);

    onChange({ target: { name: path, value: constructFormValue(selectedValues, newCorrespondingValues) } });
  };

  const handleExtensionFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const previousDynamicLabel = correspondingValues[e.target.name]?.dynamicLabel;
    let newValue;
    if (previousDynamicLabel) {
      newValue = { extensionValue: e.target.value, dynamicLabel: previousDynamicLabel || "" };
    } else {
      newValue = { extensionValue: e.target.value };
    }
    const newCorrespondingValues = {
      ...correspondingValues,
      [e.target.name]: newValue,
    };
    setCorrespondingValues(newCorrespondingValues);
    onChange({ target: { name: path, value: constructFormValue(selectedValues, newCorrespondingValues) } });
  };

  return (
    <>
      <ValidatorSelect
        label={translate(path + ".label")}
        multiple
        onChange={handleSelectChange}
        value={selectedValues}
        renderValue={(selected: string[]) => {
          return selected.map((value) => translate(path + ".values." + value)).join(", ");
        }}
        disabled={disabled}
      >
        {config.values.map((value) => (
          <MenuItem value={value} key={value}>
            <Checkbox checked={selectedValues.includes(value)} />
            <ListItemText primary={translate(path + ".values." + value)} />
          </MenuItem>
        ))}
        {config.showOther && (
          <MenuItem value={"other"} key={"other"}>
            <Checkbox checked={selectedValues.includes("other")} />
            <ListItemText primary={translate(path + ".values.other")} />
          </MenuItem>
        )}
      </ValidatorSelect>
      <Grid container spacing={1}>
        {_.map(formValue, (value, key) => (
          <React.Fragment key={key}>
            <Grid item xs={12} md={config.extensionFieldConfig.md}>
              {config.extensionFieldConfig.type === "money" ? (
                <ValidatorNumberField
                  name={key}
                  value={value.extensionValue}
                  label={config.extensionFieldConfig.labelPrefix + " " + translate(path + ".values." + key)}
                  onChange={handleExtensionFieldChange}
                  fieldType={"money"}
                  numberType={"float"}
                  disabled={disabled}
                />
              ) : (
                <TextField
                  name={key}
                  label={translate(path + ".values." + key)}
                  value={value}
                  onChange={handleExtensionFieldChange}
                  disabled={disabled}
                />
              )}
            </Grid>
            {key === "other" && (
              <Grid item xs={12} md={config.extensionFieldConfig.md}>
                <TextField
                  name={"labelOther"}
                  label={"Bezeichnung Sonstiges"}
                  value={value.dynamicLabel}
                  onChange={handleDynamicLabelChange}
                  disabled={disabled}
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </>
  );
}
