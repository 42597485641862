import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import litigationCaseTranslations from "./litigationCaseTranslations";
import abstractCaseTranslations from "./abstractCaseTranslations";
import personTranslations from "./personTranslations";
import { prosecutionTranslations } from "./prosecutionTranslations";
import opposingPartyLegalEntityTranslations from "./opposingPartyLegalEntityTranslations";
import debtCollectionAgencyTranslations from "./debtCollectionAgencyTranslations";
import { objectivesLawFirmValues } from "./objectivesLawFirmTranslations";
import contactTranslations from "./contactTranslations";
import addressTranslations from "./addressTranslations";

const rentalContractTranslations = {
  ...basicCaseTranslations,
  ...litigationCaseTranslations,
  ...abstractCaseTranslations,
  label: "Mietvertrag",
  endOfContractDate: {
    label: "Datum Vertragsende",
  },
  reasonForDispute: {
    label: "Problem der Mandantschaft",
    values: {
      other: "Anderes Anliegen",
      deposit: "Kaution",
      rentIncrease: "Mieterhöhung",
      utilitiesStatement: "Nebenkostenabrechnung",
      illegalContractClause: "Widerrechtliche Vertragsklausel",
      landlordHasCancelled: "Kündigung",
      housingDeficiencies: "Mietmängel",
    },
  },
  housingDeficienciesDescriptionClient: {
    label: "Mängelbeschreibung Mandantschaft",
  },
  housingDeficienciesDescriptionLawFirm: {
    label: "Mängelbeschreibung Kanzlei",
  },
  previousBasicRent: {
    label: "Höhe vereinbarte Kaltmiete",
  },
  previousRentIncludingHeating: {
    label: "Höhe vereinbarte Warmmiete",
  },
  futureBasicRent: {
    label: "Höhe der neuen Kaltmiete",
  },
  futureRentIncludingHeating: {
    label: "Höhe der neuen Warmmiete",
  },
  cancellationReceivedDate: {
    label: "Eingang der Kündigung",
  },
  rentalContractStartDate: {
    label: "Start Mietverhältnis",
  },
  rentalContractEndDate: {
    label: "Ende Mietverhältnis",
  },
  depositAmount: {
    label: "Höhe der Kaution",
  },
  customer: customerTranslations,
  additionalClaimText: {
    label: "Zusatztext Forderung",
  },
  additionalIssueDescriptionText: {
    label: "Zusatztext Schilderung Sachverhalt",
  },
  buyerPaidDate: {
    label: "Zahlungsdatum Käufer",
  },
  buyerPaidStatus: {
    label: "Bezahlstatus Käufer",
    values: {
      fully: "vollständig",
      partially: "teilweise",
      none: "noch gar nicht",
    },
  },
  clientLegalRepresentative: personTranslations,
  responsibleProsecution: prosecutionTranslations,
  prosecutionReference: {
    label: "Aktenzeichen Staatsanwaltschaft",
  },
  contractNumber: {
    label: "Vertragsnummer",
  },
  contractDate: {
    label: "Vertragsabschluss am",
  },
  opposingPartyType: {
    label: "Vermieter ist",
    values: {
      opposingPartyLegalEntity: "Unternehmen",
      opposingPartyPerson: "Privatperson",
    },
  },
  contractualPartnerPerson: personTranslations,
  contractualPartnerLegalEntity: {
    label: "Gegenseite",
    ...opposingPartyLegalEntityTranslations,
  },
  debtCollectionAgencyReference: {
    label: "Aktenzeichen",
  },
  debtCollectionAgency: {
    label: "Inkasso",
    ...debtCollectionAgencyTranslations,
  },
  propertyManager: {
    label: "Hausverwaltung",
    legalName: {
      label: "Name",
    },
    ...contactTranslations,
    address: addressTranslations,
  },
  propertyManagerReference: {
    label: "Aktenzeichen",
  },
  goodsOrServiceProvidedStatus: {
    label: "Status Leistungserbringung/Lieferung",
    values: {
      fully: "vollständig",
      partially: "teilweise",
      none: "noch gar nicht",
    },
  },
  claimedDateClient: {
    label: "Datum Forderungsschreiben Mandant",
  },
  claimedDeadlineClient: {
    label: "Forderungsfrist Mandant",
  },
  clientHasAlreadyClaimed: {
    label: "Mandant hat bereits eingefordert",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  objectiveLawFirm: {
    label: "Zielsetzung Kanzlei",
    values: {
      ...objectivesLawFirmValues,
    },
  },
  objectiveLawFirmSecondary: {
    label: "Sekundäre Zielsetzung Kanzlei",
    values: {
      ...objectivesLawFirmValues,
    },
  },
  objectiveLawFirmTertiary: {
    label: "Tertiäre Zielsetzung Kanzlei",
    values: {
      ...objectivesLawFirmValues,
    },
  },
  paymentOrderDate: {
    label: "Datum des Mahnbescheids",
  },
  enforcementOrderDate: {
    label: "Datum des Vollstreckungsbescheids",
  },
  claimedAmountClient: {
    label: "Forderungsbetrag Mandant",
  },
  overdueFees: {
    label: "Mahngebühren",
  },
  claimedAmountLawFirm: {
    label: "Forderungsbetrag Kanzlei",
  },
  subjectOfDispute: {
    label: "Forderung",
    values: {
      money: "Geld",
      goods: "Ware oder Leistung",
      moneyAndGoods: "Geld und Leistung",
      other: "Sonstiges",
    },
    enforceClaim: {
      label: "Forderung Mandant",
      values: {
        money: "Geld",
        goods: "Ware oder Leistung",
        moneyAndGoods: "Geld und Leistung",
        other: "Sonstiges",
      },
    },
    defendClaim: {
      label: "Forderung Gegenseite",
      values: {
        money: "Geld",
        goods: "Ware oder Leistung",
        moneyAndGoods: "Geld und Leistung",
        other: "Sonstiges",
      },
    },
  },
  trialWanted: {
    label: "Gerichtsverfahren gewünscht",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  advisoryAppointment: {
    label: "Beratungsgespräch",
  },
  extrajudicialReaction: {
    label: "Reaktion des Vermieters",
    values: litigationCaseTranslations.extrajudicialReaction.values,
    enforceClaim: {
      label: "Reaktion des Vermieters",
      values: litigationCaseTranslations.extrajudicialReaction.enforceClaim.values,
    },
    defendClaim: {
      label: "Reaktion des Vermieters",
      values: litigationCaseTranslations.extrajudicialReaction.defendClaim.values,
    },
  },
  hasMonitoringBilling: {
    label: "Abrechnung Monitoringgebühr",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
};

export default rentalContractTranslations;
