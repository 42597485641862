import contactTranslations from "./contactTranslations";
import addressTranslations from "./addressTranslations";

const opposingPartyLegalEntityTranslations = {
  legalName: {
    label: "Name des Unternehmens",
  },
  legalForm: {
    label: "Gesellschaftsform",
  },
  name: {
    label: "Markenname",
  },
  ...contactTranslations,
  address: addressTranslations,
};
export default opposingPartyLegalEntityTranslations;
