import { getServiceWorldCaseLink } from "./Product/ProductService";
import { AbstractCase } from "../types/AbstractCase";
import { CalendlyEvent } from "../types/Calendly/CalendlyEvent";

const EXTERNAL_CALENDLY_URL = "https://calendly.com";

const getProductCalendlyLink = (product: AbstractCase) => {
  return getServiceWorldCaseLink(product) + "/calendly";
};

export const buildCalendlyLinkLawyer = (product: AbstractCase, isExternal = false) => {
  if (process.env.REACT_APP_DEPLOYMENT !== "production") {
    return combineCalendlyLinkLawyerParts(product, "dev", isExternal);
  }

  if (!product.backofficeCase.lawyer) {
    return combineCalendlyLinkLawyerParts(product, "frank-riedel", isExternal);
  }

  const lawyerSlug =
    product.backofficeCase.lawyer.person.givenName.replace(/\s/, "").toLowerCase() +
    "-" +
    product.backofficeCase.lawyer.person.familyName.replace(/\s/, "").toLowerCase();
  return combineCalendlyLinkLawyerParts(product, lawyerSlug, isExternal);
};

const combineCalendlyLinkLawyerParts = (product: AbstractCase, lawyerSlug: string, isExternal = false) => {
  return (
    (isExternal ? EXTERNAL_CALENDLY_URL : getProductCalendlyLink(product)) +
    `/legalbird-${lawyerSlug}/beratungsgespraech?utm_source=` +
    encodeURI(btoa(product.backofficeCaseLb.id))
  );
};

export const hasUserConsent = (calendlyEvent: CalendlyEvent, type: string) => {
  if (!calendlyEvent.consentGiver) {
    return false;
  }

  if (calendlyEvent.consentDenialDate) {
    return false;
  }

  return !!(calendlyEvent.consentApprovalDate && calendlyEvent.consentType === type);
};

export const buildCalendlyLink = (product: AbstractCase, isExternal = false) => {
  let linkExtension = "/legalbird-frank-riedel/beratungsgespraech";

  if (process.env.REACT_APP_DEPLOYMENT !== "production") {
    linkExtension = "/legalbird-dev/beratungsgespraech";
    return (
      (isExternal ? EXTERNAL_CALENDLY_URL : getProductCalendlyLink(product)) +
      linkExtension +
      "?utm_source=" +
      encodeURI(btoa(product.backofficeCaseLb.id))
    );
  }

  switch (product.productClassName) {
    case "death":
      linkExtension = "/legalbird/erstberatung-todesfall-erbrecht";
      break;
    case "alimony":
      linkExtension = "/legalbird/erstberatung-unterhalt";
      break;
    case "familyLawCase":
      linkExtension = "/legalbird/erstberatung-familienrecht";
      break;
    case "divorce":
      linkExtension = "/legalbird/erstberatung-trennung-scheidung";
      break;
    case "eventContract":
    case "membershipContract":
    case "otherContract":
    case "providerContract":
    case "dataLeakContract":
    case "purchaseContract":
    case "serviceContract":
    case "subscriptionContract":
    case "travelContract":
    case "vehicleContract":
      linkExtension = "/legalbird/erstberatung-vertragsrecht";
      break;
    default:
      return buildCalendlyLinkLawyer(product, isExternal);
  }
  return (
    (isExternal ? EXTERNAL_CALENDLY_URL : getProductCalendlyLink(product)) +
    linkExtension +
    "?utm_source=" +
    encodeURI(btoa(product.backofficeCaseLb.id))
  );
};
