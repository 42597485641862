/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import customerTranslations from "./customerTranslations";
import abstractCaseTranslations from "./abstractCaseTranslations";
import personTranslations from "./personTranslations";
import { prosecutionTranslations } from "./prosecutionTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import litigationCaseTranslations from "./litigationCaseTranslations";
import authorityTranslations from "./authorityTranslations";

const trafficAccidentTranslations = {
  label: "Verkehrsunfall",
  customer: customerTranslations,
  ...basicCaseTranslations,
  ...litigationCaseTranslations,
  ...abstractCaseTranslations,
  valueInDisputePreliminary: {
    label: "Vorläufiger Streitwert (Gesamtsumme Schaden)",
  },
  additionalClaimText: {
    label: "Zusatztext Forderung",
  },
  additionalIssueDescriptionText: {
    label: "Zusatztext Schilderung Sachverhalt",
  },
  clientLegalRepresentative: personTranslations,
  responsibleProsecution: prosecutionTranslations,
  prosecutionReference: {
    label: "Aktenzeichen Staatsanwaltschaft",
  },
  contractDate: {
    label: "Vertragsabschluss am",
  },
  typeOfDamage: {
    label: "Art des Schadens",
    values: {
      vehicle: "Schaden am Fahrzeug",
      person: "Personenschaden",
      vehicleAndPerson: "Schaden am Fahrzeug und Personenschaden",
      other: "Anderer Sachschaden",
    },
  },
  typeOfAccident: {
    label: "Art des Unfalls",
    values: {
      rearEndCollision: "Auffahrunfall",
      laneChangeCollision: "Spurwechselkollision",
      parking: "Ein- / Ausparken",
      other: "Sonstiges / Unklar",
    },
  },
  courseOfEvents: {
    label: "Unfallhergang Mandantschaft",
  },
  courseOfEventsLawFirm: {
    label: "Unfallschilderung Kanzlei",
  },
  claimsForDamages: {
    label: "Schadensersatzansprüche",
    values: {
      repairCosts: "Reparaturkosten",
      replacementCosts: "Wiederbeschaffungsaufwand",
      lossOfUse: "Nutzungsausfall",
      rentalCarCosts: "Mietwagenkosten",
      depreciation: "Wertminderung",
      expertFees: "Sachverständigengebühren",
      expensesFlatFee: "Auslagenpauschale",
      deregistrationCosts: "Abmeldekosten",
      registrationCosts: "Anmeldekosten",
      towingCosts: "Abschleppkosten",
      storageCosts: "Standkosten",
      financingCosts: "Finanzierungskosten",
      other: "Sonstiges",
    },
  },
  extrajudicialCommunicationSent: {
    label: "Schreiben an KFZ-Haftpflicht am",
  },
  extrajudicialCommunicationDeadline: {
    label: "Gesetzte Frist für KFZ-Haftpflicht",
  },
  trialWanted: {
    label: "Gerichtsverfahren gewünscht",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  extrajudicialReaction: {
    label: "Reaktion KFZ-Haftpflicht",
    values: {
      noReaction: "Keine Reaktion",
      fullyAgreed: "Forderung wird vollständig erfüllt/aufgegeben",
      partiallyAgreed: "Forderung wird teilweise erfüllt/aufgegeben",
      disagreed: "Forderung wird nicht erfüllt/aufrechterhalten",
    },
    enforceClaim: {
      label: "Reaktion KFZ-Haftpflicht",
      values: {
        noReaction: "Keine Reaktion",
        fullyAgreed: "Forderung wird voll erfüllt",
        partiallyAgreed: "Forderung wird teilweise erfüllt",
        disagreed: "Forderung wird nicht erfüllt",
      },
    },
  },
  extrajudicialRecommendation: {
    label: "Empfehlung weiteres Vorgehen",
    values: {
      agreementDone: "Einigung erzielt / Forderung erledigt",
      lawsuit: "Klage einreichen / Klage abwarten",
      insufficientEvidence: "Schlechte Beweislage",
      insufficientChancesOfSuccess: "Schlechte Erfolgsaussicht",
      costBenefitConsiderations: "Schlechtes Kosten-Nutzen-Verhältnis",
      anonymousOpponent: "Gegenseite unklar",
      opponentUnableToPay: "Gegenseite nicht solvent",
      individualText: "Individuelle Empfehlung",
    },
  },
  extrajudicialRecommendationText: {
    label: "Zusatztext Empfehlung",
  },
  finalClaimStatusClient: {
    label: "Stand Forderung aus Sicht Mandantschaft",
    values: {
      fullyAgreed: "Voll erledigt",
      partiallyAgreed: "Teilweise erledigt",
      notAgreed: "Nicht erledigt",
      noReaction: "Keine erledigt",
    },
    enforceClaim: {
      label: "Stand Forderung aus Sicht Mandantschaft",
      values: {
        fullyAgreed: "Voll erfüllt",
        partiallyAgreed: "Teilweise erfüllt",
        notAgreed: "Nicht erfüllt",
        noReaction: "Keine Reaktion",
      },
    },
  },
  finalProcessFeedbackClient: {
    label: "Finale Entscheidung Mandantschaft",
    values: {
      agreement: "Fall abschließen",
      lawsuit: "Gerichtsverfahren",
    },
    enforceClaim: {
      label: "Finale Entscheidung Mandantschaft",
      values: {
        agreement: "Forderung erledigt/zurücknehmen",
        lawsuit: "Forderung einklagen",
      },
    },
  },
  extrajudicialReactionReceived: {
    label: "Reaktion der KFZ-Haftpflicht am",
  },
  responsibleAuthority: authorityTranslations,
  claimedAmountLawFirm: {
    label: "Forderungsbetrag Kanzlei",
  },
  licensePlateOpposingParty: {
    label: "KFZ-Kennzeichen Unfallgegner",
  },
  vehicleMake: {
    label: "Marke Fahrzeug",
  },
  vehicleModel: {
    label: "Modell Fahrzeug",
  },
  vehicleId: {
    label: "Fahrzeugidentifikationsnr.",
  },
  licensePlateClientVehicle: {
    label: "KFZ-Kennzeichen eigenes Fahrzeug",
  },
  accidentAddress: {
    streetAddress: {
      label: "Straße und Hausnummer - Unfallort",
    },
    postalCode: {
      label: "PLZ - Unfallort",
    },
    addressLocality: {
      label: "Ort - Unfallort",
    },
  },
  policeInvolved: {
    label: "Polizei hinzugezogen?",
  },
  accidentDate: {
    label: "Datum des Unfalls",
  },
  accidentTime: {
    label: "Unfallzeitpunkt",
  },
  opposingInsurancePolicyNumber: {
    label: "Versicherungsnummer KFZ-Haftpflicht",
  },
  opposingCaseReferenceNumber: {
    label: "Schadennummer KFZ-Haftpflicht",
  },
  descriptionInjuries: {
    label: "Beschreibung Personenschaden",
  },
  opposingCarLiabilityInsurance: {
    label: "KFZ-Haftpflichtversicherer",
  },
  descriptionDamage: {
    label: "Beschreibung Sachschaden",
  },
  driverOfClientVehicle: {
    label: "Fahrer eigenes Fahrzeug",
    values: {
      client: "Ich selbst",
      other: "Eine andere Person",
      nobody: "Niemand (Fahrzeug geparkt)",
    },
  },
  driverPerson: {
    gender: {
      label: "Anrede - Fahrer",
      values: {
        male: "Herr",
        female: "Frau",
        diverse: "Divers",
      },
    },
    givenName: {
      label: "Vorname - Fahrer",
    },
    familyName: {
      label: " Nachname - Fahrer",
    },
    residenceAddress: {
      streetAddress: {
        label: "Straße und Hausnummer - Fahrer",
      },
      postalCode: {
        label: "PLZ - Fahrer",
      },
      addressLocality: {
        label: "Ort - Fahrer",
      },
    },
  },
  authorityReference: {
    label: "Aktenzeichen Behörde",
  },
  legalExpenseInsurance: {
    label: "Rechtsschutz Verkehr",
  },
};

export default trafficAccidentTranslations;
