import React, { useEffect, useState } from "react";
import { Call, Device } from "@twilio/voice-sdk";
import { apiPost } from "../../services/Api/apiCall";
import { TwilioError } from "@twilio/voice-sdk/es5/twilio/errors";
import Grid from "@mui/material/Grid";
import { Button, Stack, Switch, TextField, Typography } from "@mui/material";
import { MarkChatReadOutlined, SpeakerNotesOffOutlined } from "@mui/icons-material";
import { translate } from "../../services/Translations/translatorService";
import { AbstractCase } from "../../types/AbstractCase";
import { CalendlyEvent } from "../../types/Calendly/CalendlyEvent";
import { hasUserConsent } from "../../services/calendlyService";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";

type SoftphoneProps = {
  product: AbstractCase;
  calendlyEvent?: CalendlyEvent;
};

const Softphone = ({ product, calendlyEvent }: SoftphoneProps) => {
  const [token, setToken] = useState(null);
  const [device, setDevice] = useState<Device | null>(null);
  const [recordingEnabled, setRecordingEnabled] = useState<boolean>(
    (calendlyEvent && hasUserConsent(calendlyEvent, "recordingAndSummary")) || false
  );
  const [status, setStatus] = useState(translate("call.callStatus.initializing"));
  const [call, setCall] = useState<Call | null>(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const queryClient = useQueryClient();

  const handleTokenFetch = async () => {
    try {
      const response = await apiPost("/twilio/get_token", {});
      setToken(response.token);
    } catch (err: unknown) {
      if (err instanceof Error) {
        setPhoneError(`Failed to fetch token: ${err.message}`);
      } else {
        setPhoneError("Failed to fetch token: Unbekannter Fehler");
      }
    }
  };

  useEffect(() => {
    handleTokenFetch().then();
  }, []);

  const formatPhoneNumber = (input: string) => {
    let num = input.trim();
    num = num.replace(/^00/, "+");

    if (num[0] === "+") {
      num = "+" + num.slice(1).replace(/\D/g, "");
    } else {
      num = num.replace(/\D/g, "");
      if (num.startsWith("0")) {
        num = num.slice(1);
      }
      num = "+49" + num;
    }

    return num;
  };

  useEffect(() => {
    if (product && product.customer && product.customer.telephone) {
      setPhoneNumber(formatPhoneNumber(product.customer.telephone));
    }
  }, [calendlyEvent]);

  useEffect(() => {
    if (token) {
      const newDevice = new Device(token, {
        logLevel: "debug", // or 'off'
      });

      newDevice.on("ready", () => {
        setStatus(translate("call.callStatus.ready"));
      });

      newDevice.on("error", (deviceError: TwilioError) => {
        setPhoneError(deviceError.message);
      });

      newDevice.on("incoming", (connection) => {
        // Optionally auto-accept or show a UI for incoming call
        connection.accept();
        setCall(connection);
        setStatus(translate("call.callStatus.inCallWithInboundCaller"));
      });

      newDevice.on("disconnect", () => {
        setCall(null);
        setStatus(translate("call.callStatus.ended"));
        queryClient.invalidateQueries(queryKeys.collections("activities"));
      });

      setDevice(newDevice);
    }
  }, [token]);

  const makeCall = () => {
    if (!device || !phoneNumber) return;
    const params: Device.ConnectOptions["params"] = {
      To: phoneNumber,
      caseId: product.id.toString(),
      lbProductClass: product.backofficeCase.lbProductClass,
      recordingEnabled: recordingEnabled.toString(),
    };

    if (typeof calendlyEvent !== "undefined") {
      params["calendlyEventId"] = calendlyEvent.id.toString();
    }

    device
      .connect({ params })
      .then((call: Call) => {
        console.log("Outgoing call:", call);
        call.on("disconnect", () => {
          setCall(null);
          setStatus(translate("call.callStatus.ended"));
        });
        setCall(call);
        setStatus(`Wähle ${phoneNumber}...`);
      })
      .catch((error) => {
        console.error("Fehler beim Verbinden:", error);
      });
  };

  const hangUp = () => {
    if (call) {
      call.disconnect();
    }
  };

  const cancelRecording = async () => {
    setRecordingEnabled(false);

    await apiPost("/twilio/cancel_recording", {
      calendlyEventId: calendlyEvent?.id,
    });
  };
  return (
    <Grid container>
      <Grid item xs={12} mt={2}>
        <Typography>
          Bitte Telefonnummer der Mandantschaft im Format +49123456789 eingeben und auf “Anrufen” klicken, um den Anruf
          zu starten.
        </Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Stack direction="row" spacing={1} alignItems="flex-start">
          {recordingEnabled && (
            <>
              <MarkChatReadOutlined />
              <Typography>
                Info: Mandantschaft hat Gesprächsaufzeichnung zugestimmt. Das Gespräch wird aufgezeichnet.
              </Typography>
            </>
          )}
          {!recordingEnabled && (
            <>
              <SpeakerNotesOffOutlined />
              <Typography>
                Info: Mandantschaft hat Gesprächsaufzeichnung nicht zugestimmt. Das Gespräch wird nicht aufgezeichnet
              </Typography>
            </>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Switch checked={recordingEnabled} onClick={() => cancelRecording()} disabled={!recordingEnabled} />
          {recordingEnabled && (
            <Typography variant="body2">
              Aufzeichnung aktiviert. Bitte nur auf Mandantenwunsch deaktivieren, wenn dieser während des Telefonats der
              Aufzeichnung widerspricht.
            </Typography>
          )}
          {!recordingEnabled && <Typography variant="body2">Aufzeichnung deaktiviert</Typography>}
        </Stack>
      </Grid>
      <Grid item xs={12} mt={2}>
        <div>Status: {status}</div>
        {phoneError && <div style={{ color: "red" }}>Error: {phoneError}</div>}
      </Grid>

      <Grid item xs={12} mt={1}>
        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
          <TextField
            label="Telefonnummer"
            placeholder="Bitte die Telefonnummer eingeben"
            value={phoneNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
            variant="outlined"
            size={"small"}
            fullWidth
          />
          {!call ? (
            <Button variant="contained" onClick={makeCall} disabled={!device || !phoneNumber}>
              Anrufen
            </Button>
          ) : (
            <>
              <Button variant="contained" color="error" onClick={hangUp}>
                Auflegen
              </Button>
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Softphone;
